import ApiUtils from "./APIUtils";

class HashTagApi {
    constructor() {
        this.URL_PREFIX = "/v1/hashtag";
    }

    create(data) {
        return ApiUtils.post(this.URL_PREFIX + "/", data);
    }
}

export default new HashTagApi();