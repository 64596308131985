export const menuItems = [
    {
        name: '홈',
        key: 'home',
        url: '/',
        is_board: false
    },
    {
        name: '자유게시판',
        key: 'free-topic',
        url: '/board/free-topic',
        is_board: true
    },
    // {
    //     name: '뉴스모음',
    //     key: 'news/baseball',
    //     url: '/news/baseball',
    //     is_board: false
    // },
    {
        name: '프로야구',
        key: 'kbo',
        url: '/board/kbo',
        is_board: true
    },
    {
        name: '메이저리그',
        key: 'mlb',
        url: '/board/mlb',
        is_board: true
    },
    // {
    //     name: '전체 게시판',
    //     key: 'all-boards',
    //     url: '/board/all-boards',
    //     is_board: false
    // },
    {
        name: '문의',
        key: 'inquiry',
        url: '/inquiry',
        is_board: false
    }
];

export const optionsForLoggedInUsers = [
    {
        name: '내 정보',
        key: 'myaccount',
        url: '/myaccount',
        is_board: false
    },
    {
        name: '로그아웃',
        key: 'logout',
        is_board: false
    }
];

export const optionsForNotLoggedInUsers = [
    {
        name: '로그인', 
        key: 'login',
        url: '/login',
        is_board: false
    },
    {
        name: '회원가입',
        key: 'signup',
        url: '/signup',
        is_board: false
    }
];

export const optPrimaryMyAccount = [
    {
        name: '홈',
        key: 'home',
        url: '/'
    },

    {
        name: '기본정보',
        key: 'basic'
    }
];

export const optSecondaryMyAccount = [
    {
        name: '로그아웃',
        key: 'logout'
    },
    {
        name: '회원탈퇴',
        key: 'withdraw',
        url: '/user/delete'
    }
]
