import APIUtils from "./APIUtils";

class Auth {
    constructor() {
        this.URL_PREFIX = "/v1/auth";
    }

    login(params) {
        return APIUtils.post(this.URL_PREFIX + "/login", params);
    }

    authenticate(params) {
        return APIUtils.post(this.URL_PREFIX + "/authenticate", params);
    }

    logout(params) {
        return APIUtils.post(this.URL_PREFIX + "/logout", params);
    }

    signup(params) {
        return APIUtils.post(this.URL_PREFIX + "/signup", params);
    }

    sendPhoneVerificationCode(data) {
        return APIUtils.post(this.URL_PREFIX + '/phone_verification', data);
    }

    verifyCode(data) {
        return APIUtils.post(this.URL_PREFIX + "/phone_verification/verify", data);
    }

    refreshToken(params) {
        return APIUtils.post(this.URL_PREFIX + "/refreshtoken", params);
    }

    verifyReCaptchaToken(data) {
        return APIUtils.post(this.URL_PREFIX + "/recaptcha", data);
    }

    createTempChangePasswordLink(data) {
        return APIUtils.post(this.URL_PREFIX + "/password/change/send_link", data);
    }

    checkTempChangePasswordLinkValidity(code) {
        return APIUtils.get(this.URL_PREFIX + `/password/change/link_validity?code=${code}`);
    }

    changePassword(data) {
        return APIUtils.put(this.URL_PREFIX + '/password', data);
    }

    changePasswordWithCode(data) {
        return APIUtils.put(this.URL_PREFIX + '/password/change', data);
    }

    sendEmailVerificationCode(data) {
        return APIUtils.post(this.URL_PREFIX + '/email_verification', data);
    }

    verifyEmailVerificationCode(data) {
        return APIUtils.post(this.URL_PREFIX + '/email_verification/verify', data);
    }
}

export default new Auth();
