import ApiUtils from "./APIUtils";

class OAuth2Api {
    constructor() {
        this.URL_PREFIX = "/v1/oauth2";
    }

    signupKakao(data) {
        return ApiUtils.post(this.URL_PREFIX + '/signup/kakao', data);
    }

    signupNaver(data) {
        return ApiUtils.post(this.URL_PREFIX + '/signup/naver', data);
    }

    signupGoogle(data) {
        return ApiUtils.post(this.URL_PREFIX + '/signup/google', data);
    }
}

export default new OAuth2Api();