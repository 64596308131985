import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import MainAction from "../../api/action/MainAction";

import RESP_CODE from "../../const/response-code";

import HostPostListTable from "../component/HotPostListTable";
import {isMobile} from "../../util/device";
import MainLayout from './MainLayout';
import {Chip} from "@mui/material";

function MainPage({titleName})  {
    const [currentTab, setCurrentTab] = useState("most_viewed");
    const [hashTags, setHashTags] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState([]);
    const [listMostViewed, setListMostViewed] = useState([]);
    const [listMostLiked, setListMostLiked] = useState([]);
    const [listMostCommented, setListMostCommented] = useState([]);
    const [keywordRanking, setKeywordRanking] = useState([]);
    const navigate = useNavigate();

    async function loadContents() {
        return await MainAction.get();
    }

    useEffect(() => {
        titleName("야구포럼 (YagooForum)");

        loadContents().then((response) => {
            const responseCode = response.data.responseCode;

            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const responseBody = response.data.responseBody;
                setListMostViewed(responseBody.most_viewed);
                setListMostLiked(responseBody.most_liked);
                setListMostCommented(responseBody.most_commented);

                showHashTags(responseBody.trendy_hashtags);
                showSearchKeywordRanking(responseBody.keyword_ranking);
            }
        });

        markCurrentTab(currentTab);
    }, []);

    const handleHashtagClick = (hashTag) => {
        navigate(`/search?type=hashtag&keyword=${hashTag}`);
    }

    const handleSearchKeywordClick = (searchKeyword) => {
        navigate(`/search?type=post&keyword=${searchKeyword}`);
    }

    const showHashTags = (hashTags) => {
        if(hashTags.length === 0) {
            return;
        }

        hashTags.forEach(function (hashTag) {
            const newHashtag = <Chip
                key={hashTag.id}
                style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    backgroundColor: '#f7f7f7'
                }}
                onClick={() => handleHashtagClick(hashTag.name)}
                label={'#' + hashTag.name}
            />;

            setHashTags((items) => [...items, newHashtag]);
        });
    }

    function getPostListByTab(currentTab) {
        switch(currentTab) {
            case "most_viewed":
                return listMostViewed;

            case "most_liked":
                return listMostLiked;

            case "most_commented":
                return listMostCommented;

            case "keyword_ranking":
                return keywordRanking;

            default:
                return [];

        }
    }

    const showSearchKeywordRanking = (searchKeywordRanking) => {
        if(searchKeywordRanking.length === 0) {
            return;
        }

        searchKeywordRanking.map((searchKeyword, index) => {
            const searchKeywordName = searchKeyword.keyword;
            const searchKeywordItem = <Chip
                key={index}
                style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    backgroundColor: '#dfe8f1'
                }}
                onClick={() => handleSearchKeywordClick(searchKeywordName)}
                label={searchKeywordName}
            />;

            setSearchKeywords((items) => [...items, searchKeywordItem]);
        });
    }

    function markCurrentTab(tab) {
        setCurrentTab(tab);

        const tabs = document.getElementsByClassName("tab1");
        for(let i=0; i<tabs.length; i++) {
            let currentTab = tabs[i];
            if(currentTab.id === tab) {
                currentTab.style.backgroundColor = "#1d2c6b";
                currentTab.style.color = "white";
                currentTab.style.fontWeight = "bold";
            } else {
                currentTab.style.backgroundColor = "#ffffff";
                currentTab.style.color = "#1d2c6b";
                currentTab.style.fontWeight = "lighter";
            }
        }
    }

    const renderByDeviceType = (rows) => {
        if(isMobile()) {
            return renderMobile(rows);
        }
        return renderTabletOrDesktop(rows);
    }

    const getOptionTabs = () => {
        return (
            <div className={"horizontal-container"}>
                <div id={"most_viewed"} className={"tab1 vt-padding-sm"}
                     onMouseOver={() => markCurrentTab("most_viewed")}>
                    조회 순
                </div>

                <div id={"most_liked"} className={"tab1 vt-padding-sm"}
                     onMouseOver={() => markCurrentTab("most_liked")}>
                    좋아요 순
                </div>

                <div id={"most_commented"} className={"tab1 vt-padding-sm"}
                     onMouseOver={() => markCurrentTab("most_commented")}>
                    댓글 순
                </div>
            </div>
        );
    }

    const getTabletDesktopMainContents = (rows) => {
        return (
            <div className="main-contents pd-mid">
                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    트렌드 해시태그
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"chip-array"}>
                    {hashTags}
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    인기 검색어
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"chip-array"}>
                    {searchKeywords}
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    최근 일주일 간 인기 게시물
                </div>

                <div className={"vertical-gap-mid"}/>

                {getOptionTabs()}

                <HostPostListTable currentTab={currentTab} posts={rows}/>
            </div>
        );
    }

    const getMobileMainContents = (rows) => {
        return (
            <div className="main-contents pd-mid">
                <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-yagooforum-kr-white.svg`} alt={"야구포럼 로고"}/>

                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    트렌드 해시태그
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"chip-array"}>
                    {hashTags}
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    인기 검색어
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"chip-array"}>
                    {searchKeywords}
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"fontweight-mid"}>
                    최근 일주일 간 인기 게시물
                </div>

                <div className={"vertical-gap-mid"}/>

                {getOptionTabs()}

                <HostPostListTable currentTab={currentTab} posts={rows}/>
            </div>
        );
    }

    function renderMobile(rows) {
        return (
            <div className={"vertical-container padding2"}>
                {getMobileMainContents(rows)}
            </div>
        )
    }

    function renderTabletOrDesktop(rows) {
        return getTabletDesktopMainContents(rows);
    }

    return (
        <MainLayout children={renderByDeviceType(getPostListByTab(currentTab))}/>
    );
}

export default MainPage;