import { initializeApp } from 'firebase/app';
import {getMessaging, getToken} from "firebase/messaging";
import { isSupported } from "firebase/messaging";

export const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
});

export const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            console.log("Firebase is supported in this browser.");
            return getMessaging(firebaseApp);
        }
        console.log("Firebase is not supported in this browser");
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
    if (
        "serviceWorker" in navigator &&
        typeof window.navigator.serviceWorker !== "undefined"
    ) {
        return window.navigator.serviceWorker
            .getRegistration("/firebase-push-notification-scope")
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register(
                    "/firebase-messaging-sw.js",
                    {
                        scope: "/firebase-push-notification-scope",
                    }
                );
            });
    }
    throw new Error("The browser doesn`t support service worker.");
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
    try {
        const messagingResolve = await messaging;
        if (messagingResolve) {
            return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
                return Promise.resolve(
                    getToken(messagingResolve, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                        serviceWorkerRegistration,
                    })
                );
            });
        }
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }
};