import Report from "../Report";

export default {
    reportPost: async (data, dispatch = null) => {
        return await Report.reportPost(data, dispatch).then((resp) => {
            return resp;
        });
    },

    reportComment: async (postId, dispatch = null) => {
        return await Report.reportComment(postId, dispatch);
    }
};
