import ApiUtils from "./APIUtils";

class InquiryApi {
    constructor() {
        this.URL_PREFIX = "/v1/inquiry";
    }

    create(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + "/", data, dispatch);
    }
}

export default new InquiryApi();