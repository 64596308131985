import MainLayoutWithoutSecondaryHeader from "./MainLayoutWithoutSecondaryHeader";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import OAuth2Action from "../../api/action/OAuth2Action";
import allActions from "../../redux/action";
import {useDispatch} from "react-redux";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import TermsAction from "../../api/action/TermsAction";
import useModal from "../../hooks/useModal";
import useSnackbar from "../../hooks/useSnackbar";
import ReCAPTCHA from "react-google-recaptcha";
import AuthAction from "../../api/action/AuthAction";
import {TeamSelector} from "../component/TeamSelector";
import BaseballTeamAction from "../../api/action/BaseballTeamAction";

const RESP_CODE = require('../../const/response-code');

export const OAuth2SignupPage = ({titleName}) => {
    const generatedCode = new URL(window.location.href).searchParams.get('code');
    const authProvider = new URL(window.location.href).searchParams.get('authProvider');
    const email = new URL(window.location.href).searchParams.get('email');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {openModal} = useModal();
    const [agreedWithTerms, setAgreedWithTerms] = useState(false);
    const [agreedWithPrivateInfoPolicy, setAgreedWithPrivateInfoPolicy] = useState(false);
    const [terms, setTerms] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const refUsername = useRef();
    const {openSnackbar} = useSnackbar();
    const recaptchaRef = React.createRef();
    const [validToken, setValidToken] = useState(null);
    const [leagues, setLeagues] = useState([]);
    const [favouriteTeamIds, setFavouriteTeamIds] = useState([]);

    useEffect(() => {
        titleName("회원가입 - 야구포럼 (YagooForum)");

        console.log(`Generated Code: ${generatedCode}`);
        console.log(`Auth Provider: ${authProvider}`);
        console.log(`Email: ${email}`);

        TermsAction.get().then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                alert(`약관 및 개인정보보호정책을 불러오는 중에 에러가 발생했습니다.`);
                return;
            }

            const responseData = response.data.responseBody;
            setTerms(responseData.terms);
            setPrivacyPolicy(responseData.privacyPolicy);
        });

        BaseballTeamAction.getAll().then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                alert(`팀 목록을 불러오는 중에 에러가 발생했습니다.`);
                return;
            }

            const responseBody = response.data.responseBody;
            setLeagues(responseBody.leagues);
        });

    }, []);

    const onClickAgreedWithTerms = () => {
        setAgreedWithTerms(agreedWithTerms => !agreedWithTerms);
    };

    const onClickAgreedWithPrivateInfoPolicy = () => {
        setAgreedWithPrivateInfoPolicy(agreedWithPrivateInfoPolicy => !agreedWithPrivateInfoPolicy);
    }

    const checkIfAllAgreed = () => {
        if(agreedWithTerms === false) {
            openSnackbar({isOpen: true, message: '이용약관에 동의해 주시기 바랍니다.', severity: 'error'});
            return false;
        }

        if(agreedWithPrivateInfoPolicy === false) {
            openSnackbar({isOpen: true, message: '개인정보처리방침에 동의해 주시기 바랍니다.', severity: 'error'});
            return false;
        }

        return true;
    }

    const processSignup = (e) => {
        e.preventDefault();

        if(!checkIfAllAgreed()) {
            return;
        }

        const username = refUsername.current.value;
        if(username.trim() === '') {
            openSnackbar({isOpen: true, message: "사용자 이름을 입력하여 주세요.", severity: 'error'});
            return;
        }

        if(username.trim().length < 3) {
            openSnackbar({isOpen: true, message: "사용자 이름은 최소 3글자 이상이어야 합니다.", severity: 'error'});
            return;
        }

        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();

        if(token) {
            AuthAction.verifyReCaptchaToken(token, process.env.REACT_APP_RECAPTCHA_SECRET_KEY).then(response => {
                const verificationResult = response.data.responseBody.success;
                if(verificationResult) {
                    setValidToken(token);
                }
            });
        } else {
            alert('자동가입 방지를 위한 인증을 해 주시기 바랍니다.');
            return;
        }

        if(authProvider === 'KAKAO') {
            OAuth2Action.signupKakao(generatedCode, username, favouriteTeamIds).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const userDetails = response.data;
                    const loggedInUser = allActions.userActions.login(userDetails);
                    dispatch(loggedInUser);
                    navigate('/', {replace: true});
                    openSnackbar({isOpen: true, message: `안녕하세요, ${userDetails.name} 님!`, severity: 'info'});
                } else if(responseCode === RESP_CODE.REDIS_KEY_EXPIRED) {
                    openSnackbar({isOpen: true, message: "인증 시간이 지났습니다.", severity: 'error'});
                    navigate('/', {replace: true});
                } else {
                    openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${responseCode}]`, severity: 'error'});
                }
            }).catch((err) => {
                openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${err}]`, severity: 'error'});
            });
        } else if(authProvider === 'NAVER') {
            OAuth2Action.signupNaver(generatedCode, username, favouriteTeamIds).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const userDetails = response.data;
                    const loggedInUser = allActions.userActions.login(userDetails);
                    dispatch(loggedInUser);
                    navigate('/', {replace: true});
                    openSnackbar({isOpen: true, message: `안녕하세요, ${userDetails.name} 님!`, severity: 'info'});
                } else if(responseCode === RESP_CODE.REDIS_KEY_EXPIRED) {
                    openSnackbar({isOpen: true, message: "인증 시간이 지났습니다.", severity: 'error'});
                    navigate('/', {replace: true});
                } else {
                    openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${responseCode}]`, severity: 'error'});
                }
            }).catch((err) => {
                openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${err}]`, severity: 'error'});
            }); 
        } else if(authProvider === 'GOOGLE') {
            OAuth2Action.signupGoogle(generatedCode, username, favouriteTeamIds).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const userDetails = response.data;
                    const loggedInUser = allActions.userActions.login(userDetails);
                    dispatch(loggedInUser);
                    navigate('/', {replace: true});
                    openSnackbar({isOpen: true, message: `안녕하세요, ${userDetails.name} 님!`, severity: 'info'});
                } else if(responseCode === RESP_CODE.REDIS_KEY_EXPIRED) {
                    openSnackbar({isOpen: true, message: "인증 시간이 지났습니다.", severity: 'error'});
                    navigate('/', {replace: true});
                } else {
                    openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${responseCode}]`, severity: 'error'});
                }
            }).catch((err) => {
                openSnackbar({isOpen: true, message: `알 수 없는 에러가 발생했습니다. [${err}]`, severity: 'error'});
            }); 
        } else {
            console.error(`authProvider 정보가 올바르지 않습니다. ${authProvider}`);
            return;
        }
    }

    const openTermsModal = () => {
        const propsToPass = {
            open: true,
            title: '이용 약관',
            contents: terms
        };
        openModal({type: 'terms', props: propsToPass});
    }

    const openPrivacyPolicyModal = () => {
        const propsToPass = {
            open: true,
            title: '개인정보 처리방침',
            contents: privacyPolicy
        };
        openModal({type: 'terms', props: propsToPass});
    }

    const signupForm = () => {
        if(localStorage.getItem("loggedInUser") !== null) {
            return <Navigate to='/' replace={true}/>
        } else {
            return (
                <div className={"page"}>
                    <form className={"form"}>
                        <input ref={refUsername} className={"text-field padding-input signup"} placeholder={"(필수) 닉네임"}
                               autoComplete={"off"}/>
                        <input className={"text-field padding-input signup"} placeholder={"(필수) 이메일"}
                               defaultValue={email}/>

                        <div className={"vertical-gap-sm"}/>

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        />

                        <div className={"vertical-gap-sm"}/>

                        <div className="checkbox-wrapper">
                            <FormControlLabel
                                className={"control-label"}
                                control={
                                    <Checkbox
                                        className={"checkbox"}
                                        value={agreedWithTerms}
                                        onChange={onClickAgreedWithTerms}
                                    />
                                }
                                label={<Typography component={"div"} color={'#777777'} fontSize={13}>
                                    [필수] 이용약관에 동의 합니다.
                                </Typography>
                                }
                            />

                            <div style={{minWidth: '24px'}}/>

                            <div className={"terms"} onClick={() => openTermsModal()}>보기</div>
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <div className="checkbox-wrapper">
                            <FormControlLabel
                                style={{
                                    marginRight: 0
                                }}
                                control={
                                    <Checkbox
                                        className={"checkbox"}
                                        value={agreedWithPrivateInfoPolicy}
                                        onChange={onClickAgreedWithPrivateInfoPolicy}
                                    />
                                }
                                label={
                                    <Typography
                                        fontSize={13}
                                        color={'#777777'}
                                        paddingRight={0}
                                        component={"div"}
                                    >
                                        [필수] 개인정보처리방침에 동의 합니다. 14세 이상 본인입니다. 만 14세 미만은 가입하실 수 없습니다.
                                    </Typography>
                                }
                            />

                            <div className={"terms"} onClick={() => openPrivacyPolicyModal()}>보기</div>
                        </div>

                        <div className={"vertical-gap-mid"}/>

                        <div className={"font-xl"}>
                            (옵션) 회원님은 어디 팬이신가요?
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <TeamSelector leagues={leagues} favouriteTeamIds={favouriteTeamIds}
                                      setFavouriteTeamIds={setFavouriteTeamIds} isAtSignupPage={true}/>

                        <div className={"vertical-gap-sm"}/>

                        <a href={"/login"} className="link">
                            이미 회원이신가요? 여기서 <u>로그인</u> 하세요
                        </a>

                        <button type="submit" className={"button font-mid"} onClick={e => processSignup(e)}>
                            회원가입
                        </button>
                    </form>

                    <div className={"vertical-gap-mid"}/>
                </div>
            )
        }
    }

    const render = () => {
        return (
            <MainLayoutWithoutSecondaryHeader className={"horizontal-container"} children={signupForm()}/>
        );
    }

    return (
        render()
    )
}