import CommentModal from "./CommentModal";
import {useSelector} from "react-redux";
import {modalSelector} from "../../../store/modalSlice";
import {createPortal} from "react-dom";
import useModal from "../../../hooks/useModal";
import ProfileModal from "./ProfileModal";
import ProfileImageModal from "./ProfileImageModal";
import ChangeStatusMessageModal from "./ChangeStatusMessageModal";
import AlertModal from "./AlertModal";
import DeletePostModal from "./DeletePostModal";
import DeleteCommentModal from "./DeleteCommentModal";
import TermsModal from "./TermsModal";
import ReportModal from "./ReportModal";
import ChangeNameModal from "./ChangeNameModal";
import CancelEditPostModal from "./CancelEditPostModal";
import PromotionModal from "./PromotionModal";

const MODAL_COMPONENTS = {
    promotion: PromotionModal,
    comment: CommentModal,
    profile: ProfileModal,
    profileImage: ProfileImageModal,
    statusMessage: ChangeStatusMessageModal,
    name: ChangeNameModal,
    alert: AlertModal,
    deletePost: DeletePostModal,
    deleteComment: DeleteCommentModal,
    terms: TermsModal,
    report: ReportModal,
    cancelEditPost: CancelEditPostModal
}

function ModalContainer() {
    const modalList = useSelector(modalSelector);
    const { closeModal } = useModal();

    const renderModal = () => {
        const type = modalList.type;
        const props = modalList.props;

        if(type === null && props === null) {
            return null;
        }

        if(!type) {
            return null;
        }

        const ModalComponent = MODAL_COMPONENTS[type];
        if(ModalComponent !== undefined) {
            return (
                <ModalComponent key={type} {...props} onClose={closeModal} />
            );
        }
        return null;
    }

    return createPortal(<>{renderModal()}</>, document.getElementById("modal"));
}

export default ModalContainer;