import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import useModal from "../../../hooks/useModal";
import CommentAction from "../../../api/action/CommentAction";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const RESP_CODE = require('../../../const/response-code');

const CommentModal = (props) => {
    const [contentsToEdit, setContentsToEdit] = useState('');
    const {closeModal} = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function addComment(event) {
        event.preventDefault();

        if (contentsToEdit === '') {
            alert('내용이 비었습니다.');
        } else {
            CommentAction.create({
                post_id: props.postId,
                parent_comment_id: props.parentCommentId,
                contents: contentsToEdit
            }, dispatch).then(resp => {
                if(resp !== null) {
                    const respCode = resp.data.responseCode;
                    if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                        const newComment = resp.data.responseBody;
                        setContentsToEdit('');

                        if(newComment.parent_comment_id === null) {
                            props.commentAddHandler(newComment);
                        } else {
                            props.subCommentAddHandler(newComment);
                        }
                    }
                }

                closeModal();
            }).catch(error => {
                console.error(`에러 발생: ${error}`);
            });
        }
    }

    function onChangeContents(e) {
        e.preventDefault();
        setContentsToEdit(e.target.value);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setContentsToEdit('');
        closeModal();
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <div className={"pd-mid textarea-wrapper"}>
                <textarea className={"textarea-comment light-grey fullwidth"} defaultValue={contentsToEdit} onChange={e => onChangeContents(e)} />
            </div>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>취소</Button>
                <Button onClick={e => addComment(e)}>완료</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CommentModal;