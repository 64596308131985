import Post from "../Post";

export default {
    create: (data, dispatch = null) => {
        return Post.create(data, dispatch).then((resp) => {
            return resp;
        });
    },

    edit: (data, dispatch = null) => {
        return Post.edit(data, dispatch).then((resp) => {
            return resp;
        })
    },

    get: (pid) => {
        return Post.get(pid).then((resp) => {
            return resp;
        })
    },

    getPosts: (boardUrlName, page) => {
        return Post.getPosts(boardUrlName, page).then((resp) => {
            return resp;
        });
    },

    getBySearchKeyword: (criteria, keyword, boardUrlName, page) => {
        return Post.getPostsBySearchKeyword(criteria, keyword, boardUrlName, page).then((resp) => {
            return resp;
        })
    },

    getComments: (postId, page) => {
        return Post.getComments(postId, page).then((resp) => {
            return resp;
        });
    },

    searchByKeyword: (type, keyword, page) => {
        return Post.getSearchByKeywordResult(type, keyword, page).then((resp) => {
            return resp;
        })
    },

    delete: (data, dispatch = null) => {
        return Post.delete(data, dispatch).then((resp) => {
            return resp;
        });
    }
};