import { useDispatch } from "react-redux";
import { openSnackbar, closeSnackbar } from "../store/snackbarSlice";

function useSnackbar() {
    const dispatch = useDispatch();

    const handleOpenSnackbar = ({ isOpen, message, severity }) => {
        dispatch(openSnackbar({ isOpen, message, severity }));
    };

    const handleCloseSnackbar = () => {
        dispatch(closeSnackbar());
    };

    return { openSnackbar: handleOpenSnackbar, closeSnackbar: handleCloseSnackbar };
}

export default useSnackbar;