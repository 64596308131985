import ApiUtils from "./APIUtils";

class NotificationApi {
    constructor() {
        this.URL_PREFIX = "/v1/notification";
    }

    history(dispatch = null) {
        return ApiUtils.get(this.URL_PREFIX + "/history", dispatch);
    }

    markAllAsRead(dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/read/all", dispatch);
    }

    markAsRead(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/read", data, dispatch);
    }
}

export default new NotificationApi();