import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import BaseballTeamAction from "../../api/action/BaseballTeamAction";

const RESP_CODE = require('../../const/response-code');

export const TeamSelector = ({leagues, favouriteTeamIds, setFavouriteTeamIds, isAtSignupPage}) => {

    const toggleTeamSelected = (currentSelectedTeamId) => {

        // 회원가입 페이지에서 사용하는게 아닌 경우에는 각 아이템 클릭시마다 좋아하는 팀 수정 API가 호출되어야 한다.
        if(isAtSignupPage) {
            changeTeamListComponent(favouriteTeamIds, setFavouriteTeamIds, currentSelectedTeamId);
        } else {
            BaseballTeamAction.edit({team_id: currentSelectedTeamId}).then(response => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    changeTeamListComponent(favouriteTeamIds, setFavouriteTeamIds, currentSelectedTeamId);
                }
            });
        }
    }

    // 좋아하는 팀 목록 변경하는 함수
    const changeTeamListComponent = (favouriteTeamIds, setFavouriteTeamIds, currentSelectedTeamId) => {
        if(favouriteTeamIds.indexOf(currentSelectedTeamId) < 0) {
            setFavouriteTeamIds([...favouriteTeamIds, currentSelectedTeamId]);
        } else {
            setFavouriteTeamIds(favouriteTeamIds.filter((teamId) => teamId !== currentSelectedTeamId));
        }
    }

    const selected = (teamId) => {
        return favouriteTeamIds.includes(teamId);
    }

    const showTeams = (teams) => {
        if(teams.length > 0) {
            return teams.map((team, index) => {
                return (
                    <Chip
                        key={index}
                        style={{
                            marginLeft: '1.5px',
                            marginRight: '1.5px',
                        }}
                        avatar={<Avatar alt="logo path" src={team.logoPath} />}
                        label={team.name}
                        color={selected(team.id) ? "primary" : "default"}
                        variant={selected(team.id) ? "default" : "outlined"}
                        clickable={true}
                        deleteIcon={<CheckIcon/>}
                        onClick={() => toggleTeamSelected(team.id)}
                        onDelete={selected(team.id) && (() => {})}
                    />
                )
            });
        }
    }

    const showLeagues =(leagues) => {
        return (
            <div>
                {
                    leagues.map((league, index) => {
                        return (
                            <div key={index} >
                                <div className={"horizontal-container"}>
                                    <div className={"font-lg"}>
                                        {league.name}
                                    </div>
                                </div>

                                <div className={"vertical-gap-sm"}/>

                                {showLeagues(league.childrenLeagues)}

                                <div className={"chip-array"} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    {showTeams(league.teams)}
                                </div>

                                <div className={"vertical-gap-sm"}/>
                            </div>
                        );
                    })
                }
            </div>
        );
    }


    return (
        <div>
            {showLeagues(leagues)}
        </div>
    )
}