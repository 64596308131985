import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import useModal from "../../../hooks/useModal";
import { useEffect } from "react";

const AlertModal = (props) => {
    const {closeModal} = useModal();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    const handleClose = (e) => {
        e.preventDefault();
        const navigate = props.navigate;
        const nextAction = props.nextAction;
        if(navigate && nextAction === 'login') {
            navigate('/login');
        }
        closeModal();
    }

    const displayTitle = (title) => {
        if(title !== undefined && title !== null && title !== '') {
            return (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            );
        }
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            {displayTitle(props.title)}
            <input className={"input"} defaultValue={props.message} style={{ outline: "none", width: '90%' }}/>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertModal;