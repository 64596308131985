const login = (user) => {
    return {
        type: 'LOGIN',
        user
    };
};

const logout = () => {
    return {
        type: 'LOGOUT'
    };
};

const updateFcmToken = (token) => {
    return {
        type: 'UPDATE_FCM_TOKEN',
        token
    };
}

export const userActions =  {
    login,
    logout,
    updateFcmToken
};