import { useNavigate } from 'react-router-dom';
import {useEffect} from "react";
import axios from "axios";
import RESP_CODE from "../../const/response-code";
import allActions from "../../redux/action";
import {useDispatch} from "react-redux";

export default function OAuth2Google() {
    const params = new URL(window.location.href).searchParams;
    const code = params.get('code');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/oauth2/findUser/google?code=${code}`, {
                withCredentials: true,
                credentials: 'include'
            });

            const responseCode = res.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const userDetails = res.data.responseBody;
                const loggedInUser = allActions.userActions.login(userDetails);
                dispatch(loggedInUser);
                navigate('/', {replace: true});
            } else if(responseCode === RESP_CODE.EMAIL_ALREADY_TAKEN_WITH_OTHER_AUTH_PROVIDER) {
                alert("다른 이메일로 이미 가입하신 내역이 있습니다.");
                navigate('/', {replace: true});
            } else if(responseCode === RESP_CODE.USER_NOT_FOUND) {
                if(window.confirm("아직 가입된 계정이 없습니다. 회원 가입을 이어서 하시겠어요?")) {
                    const data = res.data.responseBody;
                    const generatedCode = data.generatedCode;
                    const email = data.email;
                    
                    navigate(`/oauth2/signup?code=${generatedCode}&authProvider=GOOGLE&email=${email}`, {replace: true});
                } else {
                    navigate(-2);
                }
            } else {
                alert(`알 수 없는 에러가 발생했습니다. 잠시 후 다시 실행해 주세요. [${responseCode}]`);
                navigate('/', {replace: true});
            }
        })();
    }, []);
};