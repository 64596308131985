import Image from "../Image";

export default {
    uploadPostImage: (data, dispatch = null) => {
        return Image.uploadPostImage(data, dispatch).then((resp) => {
            return resp;
        });
    },

    uploadProfileImage: (data, dispatch = null) => {
        return Image.uploadProfileImage(data, dispatch).then((resp) => {
            return resp;
        });
    },

    deleteProfileImage: (data, dispatch = null) => {
        return Image.deleteProfileImage(data, dispatch).then((resp) => {
            return resp;
        });
    }
};