import ApiUtils from "./APIUtils";

class VisitorApi {
    constructor() {
        this.URL_PREFIX = "/v1/visitor";
    }

    get() {
        return ApiUtils.get(`${this.URL_PREFIX}`);
    }
}

export default new VisitorApi();