import BaseballTeam from "../BaseballTeam";

export default {
    getAll: () => {
        return BaseballTeam.getAll().then(response => {
            return response;
        })
    },

    getAllFavouriteTeams: () => {
        return BaseballTeam.getAllFavouriteTeams().then(response => {
            return response;
        })
    },

    edit: (data) => {
        return BaseballTeam.edit(data).then(response => {
            return response;
        })
    }
}