import {useEffect, useRef, useState} from "react"
import AuthAction from "../../api/action/AuthAction";
import RESP_CODE from "../../const/response-code";
import useSnackbar from "../../hooks/useSnackbar";

export const PasswordAuthenticationPage = ({authInfo, navigate, authHandler}) => {
    const refPassword = useRef(null);
    const email = authInfo.details.email;
    const authProvider = authInfo.details.authProvider;
    const {openSnackbar} = useSnackbar();

    useEffect(() => {
        if(!authInfo.loggedIn) {
            navigate('/login');
        }
    }, [authInfo]);

    const authenticate = (event) => {
        if(event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            const password = refPassword.current.value;
            if(password === '') {
                openSnackbar({isOpen: true, message: "패스워드를 입력해 주세요.", severity: 'error'});
                return;
            }
            AuthAction.authenticate(email, password, authProvider).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    authHandler(true);
                } else {
                    openSnackbar({isOpen: true, message: "회원 인증에 실패했습니다. 패스워드가 올바른지 확인 해주세요.", severity: 'error'});
                    return;
                }
            })   
        }
    }

    return (
        <div className="vertical-container vertical-center fullwidth">

            <div className={"vertical-gap-sm"}/>

            <div>회원 정보의 조회 및 변경을 위해 인증을 진행합니다.</div>

            <div className={"vertical-gap-mid"}/>

            <input 
                ref={refPassword}
                className={"input light-grey font-mid"}
                type="password" 
                placeholder="패스워드를 입력해 주세요"
                onKeyDown={authenticate}
            />

            <div className={"vertical-gap-sm"}/>
        </div>
    )
}