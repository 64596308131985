import { isMobile, isTablet } from "../../util/device";
import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function UserDeleteCompletePage() {
    const location = useLocation();
    const redirected = location.state.redirected;
    const name = location.state.name;

    const navigate = useNavigate();

    useEffect(() => {
        if(redirected !== true) {
            navigate("/", {replace: true});
        }
    }, []);

    const moveToMain = () => {
        navigate("/", {replace: true});
    }

    function renderMobileTablet() {
        return (
            <div>
                <div className={"marginUpDown"}/>

                <div className={"center-wrapper title"}>회원 탈퇴가 성공적으로 접수되었습니다.</div>

                <div className={"marginUpDown"}/>
                
                <div className={"center-wrapper"}>
                    <div className={"text"}>
                        안녕하세요, <b>{name}</b> 님,<br/>
                        탈퇴하시면 이용 중인 야구포럼 계정이 삭제되며, 모든 데이터는 복구가 불가능합니다.<br/>
                        그 동안 작성하신 게시물, 타인 게시물에 대한 댓글 및 리액션은 삭제되지 않습니다.
                    </div>
                </div>

                <div className={"marginUpDown"}/>

                <div className={"center-wrapper"}>
                    <button type={"submit"} className={"button font-mid"} onClick={e => moveToMain(e)}>메인 페이지로 이동</button>
                </div>
            </div>
        )
    }

    function renderDesktop() {
        return (
            <div>
                <div className={"marginUpDown"}/>

                <div className={"center-wrapper title"}>회원 탈퇴가 성공적으로 접수되었습니다.</div>

                <div className={"marginUpDown"}/>
                
                <div className={"center-wrapper"}>
                    <div className={"text"}>
                        안녕하세요 <b>{name}</b> 님,<br/>

                        탈퇴하시면 이용 중인 야구포럼 계정이 삭제되며, 모든 데이터는 복구가 불가능합니다.<br/>
                        그 동안 작성하신 게시물, 타인 게시물에 대한 댓글 및 리액션은 삭제되지 않습니다.
                    </div>
                </div>

                <div className={"marginUpDown"}/>

                <div className={"center-wrapper"}>
                    <button type={"submit"} className={"button font-mid"} onClick={e => moveToMain(e)}>메인 페이지로 이동</button>
                </div>
            </div>
        )
    }
    
    function renderByDeviceType() {
        if(isMobile() || isTablet()) {
            return renderMobileTablet();
        }
        return renderDesktop();
    }

    return renderByDeviceType();
}

export default UserDeleteCompletePage;