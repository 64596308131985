import Follow from "../Follow";

export default {
    followOrUnfollow: (data, dispatch = null) => {
        return Follow.followOrUnfollow(data, dispatch).then((resp) => {
            return resp;
        })
    },

    getFollowInfo: (toId, dispatch = null) => {
        return Follow.getFollowInfo(toId, dispatch).then((resp) => {
            return resp;
        })
    }
};