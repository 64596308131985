import { isMobile, isTablet } from "../../util/device";
import {useDispatch, useSelector} from "react-redux";
import UserAction from "../../api/action/UserAction";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import RESP_CODE from "../../const/response-code";
import { clearAll } from "../../util/data";

const DeregisterAccountPage = () => {
    const [agreedToDelete, setAgreedToDelete] = useState(false);
    const [name, setName] = useState('');
    const authInfo = useSelector(state => state.authInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!authInfo.loggedIn) {
            navigate("/", {replace: true});
            return;
        }

        setName(authInfo.details.name);
    }, []);

    const onClickAgreedToDelete = () => {
        setAgreedToDelete(agreedToDelete => !agreedToDelete);
    };
    
    function deleteAccount(e) {
        e.preventDefault();

        if(!agreedToDelete) {
            alert("동의를 하셔야 탈퇴하실 수 있습니다.");
            return;
        }

        if(!authInfo.loggedIn) {
            alert("사용자 정보가 없습니다.");
            navigate("/", {replace: true});
            return;
        }

        UserAction.delete(dispatch).then((resp) => {
            if(resp !== null) {
                const responseCode = resp.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    clearAll();
                    navigate("/user/delete/complete", {state: { redirected: true, name: name }, replace: true });
                }
            }
        });
    }
    
    function renderMobileTablet() {
        return (
            <div className={"page"}>
                <div className={"vertical-gap-mid"}/>

                <div className={"center-wrapper title"}>야구포럼 탈퇴 전 확인하세요!</div>

                <div className={"vertical-gap-mid"}/>
                
                <div className={"center-wrapper"}>
                    <div className={"text"}>
                        안녕하세요, <b>{name}</b> 님,<br/>
                        탈퇴하시면 이용 중인 야구포럼 계정이 삭제되며, 모든 데이터는 복구가 불가능합니다.<br/>
                        그 동안 작성하신 게시물, 타인 게시물에 대한 댓글 및 리액션은 삭제되지 않습니다.
                    </div>
                </div>

                <div className={"marginUpDown"}/>

                <div className="center-wrapper">
                    <FormControlLabel
                        style={{
                            width: '85%'
                        }}
                        control={
                            <Checkbox
                                className={"checkbox"}
                                value={agreedToDelete}
                                onChange={onClickAgreedToDelete}
                            />
                        }
                        label={
                            <Typography
                                fontSize={10}
                                color={'#777777'}
                                paddingRight={0}
                            >
                                [필수] 안내사항을 모두 확인하였으며, 이에 동의합니다.
                            </Typography>
                        }
                    />
                </div>
                <div className={"center-wrapper"}>
                    <button type={"submit"} className={"button font-mid"} onClick={e => deleteAccount(e)}>탈퇴하기</button>
                </div>
            </div>
        )
    }

    function renderDesktop() {
        return (
            <div className={"page"}>
                <div className={"vertical-gap-mid"}/>

                <div className={"center-wrapper title"}>야구포럼 탈퇴 전 확인하세요!</div>

                <div className={"vertical-gap-mid"}/>
                
                <div className={"center-wrapper"}>
                    <div className={"text"}>
                        안녕하세요 <b>{name}</b> 님,<br/>

                        탈퇴하시면 이용 중인 야구포럼 계정이 삭제되며, 모든 데이터는 복구가 불가능합니다.<br/>
                        그 동안 작성하신 게시물, 타인 게시물에 대한 댓글 및 리액션은 삭제되지 않습니다.
                    </div>
                </div>

                <div className={"marginUpDown"}/>

                <div className="center-wrapper">
                    <FormControlLabel
                        style={{
                            marginRight: 0
                        }}
                        control={
                            <Checkbox
                                className={"checkbox"}
                                value={agreedToDelete}
                                onChange={onClickAgreedToDelete}
                            />
                        }
                        label={
                            <Typography
                                fontSize={13}
                                color={'#777777'}
                                paddingRight={0}
                            >
                                [필수] 안내사항을 모두 확인하였으며, 이에 동의합니다.
                            </Typography>
                        }
                    />
                </div>
                <div className={"center-wrapper"}>
                    <button type={"submit"} className={"button font-mid"} onClick={e => deleteAccount(e)}>탈퇴하기</button>
                </div>
            </div>
        )
    }

    function renderByDeviceType() {
        if(isMobile() || isTablet()) {
            return renderMobileTablet();
        }
        return renderDesktop();
    }

    return (
        renderByDeviceType()
    )
}

export default DeregisterAccountPage;