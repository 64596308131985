import {useSelector} from "react-redux";
import { snackbarSelector } from "../../../store/snackbarSlice";
import {createPortal} from "react-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import { Snackbar, Alert } from "@mui/material";


function SnackbarContainer() {
    const snackbar = useSelector(snackbarSelector);
    const { closeSnackbar } = useSnackbar();

    const renderSnackbar = () => {
        const isOpen = snackbar.isOpen;
        const message = snackbar.message;
        const severity = snackbar.severity;

        return (
            <Snackbar open={isOpen} autoHideDuration={2000} onClose={closeSnackbar}>
                <Alert severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }

    return createPortal(<>{renderSnackbar()}</>, document.getElementById("snackbar"));
}

export default SnackbarContainer;