import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import useModal from "../../../hooks/useModal";
import ProfileAction from "../../../api/action/ProfileAction";
import RESP_CODE from "../../../const/response-code";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const CommentModal = (props) => {
    const [contentsToEdit, setContentsToEdit] = useState(props.current);
    const {closeModal} = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function onChangeContents(e) {
        e.preventDefault();
        setContentsToEdit(e.target.value);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setContentsToEdit('');
        closeModal();
    }

    const editProfile = (e) => {
        e.preventDefault();

        ProfileAction.edit(
            {
                key: props.field,
                value: contentsToEdit
            }, navigate, dispatch
        ).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                props.statusMessageChangeHandler(contentsToEdit);
                setContentsToEdit('');
                closeModal();
            }
            else {
                alert(`에러가 발생했습니다. ${responseCode}`);
            }
        });
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <input className={"input light-grey"} defaultValue={contentsToEdit} style={{ outline: "none", width: '90%' }} onChange={e => onChangeContents(e)} />

            <DialogActions>
                <Button onClick={e => handleClose(e)}>취소</Button>
                <Button onClick={e => editProfile(e)}>완료</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CommentModal;