import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    message: null,
    severity: 'error',
};

export const snackbarSelector = (state) => state.snackbar;

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        openSnackbar: (state, action) => {
            const {isOpen, message, severity} = action.payload;
            state.isOpen = isOpen;
            state.message = message;
            state.severity = severity;
        },
        closeSnackbar: (state, action) => {
            return initialState;
        }
    }
});

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;