import ApiUtils from "./APIUtils";

class PostApi {
    constructor() {
        this.URL_PREFIX = "/v1/post";
    }

    get(pid) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/get/${pid}`);
    }

    getPosts(boardUrlName, page = 1) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/list/board/${boardUrlName}?page=${page}`);
    }

    getPostsBySearchKeyword(criteria, keyword, boardUrlName, page = 1) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/list/board/${boardUrlName}?criteria=${criteria}&keyword=${keyword}&page=${page}`);
    }

    getComments(postId, page) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/${postId}/comment?page=${page}`);
    }

    create(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + "/", data, dispatch);
    }

    edit(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/", data, dispatch);
    }

    // soft delete
    delete(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/delete", data, dispatch);
    }

    getSearchByKeywordResult(type, keyword, page) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/search?type=${type}&keyword=${keyword}&page=${page}`);
    }
}

export default new PostApi();