import {isMobile, isTablet} from "../../util/device";
import {useEffect} from "react";

export default function Page404(props) {
    useEffect(() => {

    }, []);

    const imageToDisplay = () => {
        if(isMobile()) {
            return <img src={ require('../../asset/404_mobile.png')}/>;
        } else if(isTablet()) {
            return <img src={ require('../../asset/404_tablet.jpeg')}/>;
        } else {
            return <img src={ require('../../asset/404_desktop.jpg')}/>;
        }
    }

    return (
        <div>
            {imageToDisplay()}
        </div>
    )
};