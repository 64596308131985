import ApiUtils from "./APIUtils";

class UserApi {
    constructor() {
        this.URL_PREFIX = "/v1/user";
    }

    edit(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + '/', data, dispatch);

    }

    details(dispatch = null) {
        return ApiUtils.get(this.URL_PREFIX + "/details", dispatch);
    }

    detailsWithUserId(userId, dispatch = null) {
        return ApiUtils.get(this.URL_PREFIX + `/details/${userId}`, dispatch);
    }

    delete(dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + '/delete', dispatch);
    }
}

export default new UserApi();