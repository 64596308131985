export function isEmailValid(email) {
    return String(email)
    .toLowerCase()
    .match(
        "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@"
        + "[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$"
    );
}

export function isPostTitleValid(title) {
    return title.trim().length > 0 && title.trim().length <= 40;
}

export function isPostContentsValid(contents) {
    return contents.trim().length > 0;
}

export function isPasswordValid(password) {
    const MIN_SIZE = 8;
    const MAX_SIZE = 50;
    
    return String(password).match("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])" +
    "(?=.*[`~!@#$%^&*()\\-_=+\\[\\]{}|:;\"',./<>?]).{" + MIN_SIZE + "," + MAX_SIZE + "}$");
}

export function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}