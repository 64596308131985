import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import AuthAction from "../../api/action/AuthAction";
import {generateRandomString} from "../../util/security";
import RESP_CODE from "../../const/response-code";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/action";
import MainLayoutWithoutSecondaryHeader from './MainLayoutWithoutSecondaryHeader';

export default function LoginPage({titleName}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authInfo = useSelector(state => state.authInfo);

    let state = generateRandomString(32);
    const KAKAO_OAUTH2_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code&scope=account_email`;
    const NAVER_OAUTH2_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}&state=${state}`;
    const GOOGLE_OAUTH2_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&response_type=code&include_granted_scopes=true&state=${state}&scope=https://www.googleapis.com/auth/userinfo.email`;

    useEffect(() => {
        titleName("로그인 - 야구포럼 (YagooForum)");

        if(authInfo.loggedIn) {
            navigate('/', {replace: true});
        }
    }, []);

    function toSignupWithKakao(e) {
        e.preventDefault();
        window.location.replace(KAKAO_OAUTH2_URL);
    }

    function toSignupWithGoogle(e) {
        e.preventDefault();
        window.location.replace(GOOGLE_OAUTH2_URL);
    }

    function toSignupWithNaver(e) {
        e.preventDefault();
        window.location.replace(NAVER_OAUTH2_URL);
    }

    const login = (e) => {
        e.preventDefault();
        if(email === '') {
            alert('이메일을 입력해 주시기 바랍니다.');
            return;
        }

        if(password === '') {
            alert('비밀번호를 입력해 주시기 바랍니다.');
            return;
        }

        AuthAction.login(
            email,
            password,
            'DEFAULT'
        ).then((resp) => {
            const responseCode = resp.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const userDetails = resp.data;
                const loggedInUser = allActions.userActions.login(userDetails);
                dispatch(loggedInUser);
                navigate('/', { replace: true });
            } else if(responseCode === RESP_CODE.USER_NOT_FOUND) {
                alert("해당 사용자가 존재하지 않습니다.");
            } else if(responseCode === RESP_CODE.WRONG_PASSWORD) {
                alert("비밀번호를 잘못 입력하셨습니다.");
            } else {
                alert("로그인에 실패했습니다.");
            }
        });
    }

    const loginForm = () => {
        return (
            <form onSubmit={e => login(e)} className={"form"}>
                <div className={"vertical-gap-mid"}/>
                <input
                    className={"login"}
                    placeholder={"이메일"}
                    defaultValue={email}
                    onChange={e => {
                        setEmail(e.target.value)
                    }}
                    type={"email"}
                />

                <input
                    className={"login"}
                    placeholder={"패스워드"}
                    defaultValue={password}
                    onChange={e => {
                        setPassword(e.target.value)
                    }}
                    type={"password"}
                    autoComplete={"off"}
                />

                <button className={"button font-mid"} type={"submit"}>로그인</button>

                <div className={"vertical-gap-sm"}/>
                <button type={"submit"} className={"button3 kakao"} onClick={e => toSignupWithKakao(e)}>
                    카카오로 시작하기
                </button>

                <div className={"vertical-gap-sm"}/>
                <button type={"submit"} className={"button3 google"} onClick={e => toSignupWithGoogle(e)}>
                    구글로 시작하기
                </button>

                <div className={"vertical-gap-sm"}/>
                <button type={"submit"} className={"button3 naver"} onClick={e => toSignupWithNaver(e)}>
                    네이버로 시작하기
                </button>

                <a className={"a"} href={'/forgot_password'}>비밀번호를 잊으셨나요?</a>

                <a className={"a"} href={'/signup'}>아직 회원이 아니신가요?</a>
            </form>
        );
    }

    const render = () => {
        return (
            <MainLayoutWithoutSecondaryHeader className={"horizontal-container"} children={loginForm()}/>
        );
    }

    return (
        render()
    )
}