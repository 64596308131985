import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ImageResize from 'quill-image-resize';
import React, {useMemo, memo, useCallback} from "react";
import ImageAction from "../../api/action/ImageAction";
import RESP_CODE from "../../const/response-code";
import {useDispatch} from "react-redux";

Quill.register('modules/ImageResize', ImageResize);

const Editor = memo(({quillRef, contents}) => {

    const dispatch = useDispatch();

    const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('name', 'image');
        input.click();

        input.onchange = async() => {
            const file = input.files[0];

            const formData = new FormData();
            formData.append('img', file);

            const result = await ImageAction.uploadPostImage(formData, dispatch);
            if(result !== null) {
                const respCode = result.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                    const IMG_URL = result.data.responseBody;

                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection()?.index;

                    if (typeof (range) !== "number") return;
                    quill.setSelection(range, 1);
                    quill.clipboard.dangerouslyPasteHTML(
                        range,
                        `<a href=${IMG_URL}><img src=${IMG_URL} alt="image"/></a>`);
                } else {
                    console.log(`Error while uploading an image. ${result}`);
                }
            }
        }
    }, [quillRef]);

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline','strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
            ],
            clipboard: {
                matchVisual: false
            },
            handlers: {
                image: imageHandler
            },
        },

        ImageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), []);

    return (
        <ReactQuill
            ref={quillRef}
            value={contents}
            modules={modules}
            theme="snow"
            className={"editor-textarea"}
            />
    )
})

export default Editor;