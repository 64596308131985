import React, {useEffect, useState} from "react";
import {AvatarGroup, Dialog} from "@mui/material";
import useModal from "../../../hooks/useModal";
import FollowAction from "../../../api/action/FollowAction";
import {useDispatch, useSelector} from "react-redux";
import {getUserId} from "../../../util/data";
import {Menu, MenuItem}  from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import {mapRole} from "../../../const/role-names";

const RESP_CODE = require('../../../const/response-code');

const ProfileModal = (props) => {
    const {closeModal} = useModal();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [headProfileImage, setHeadProfileImage] = useState('');
    const [numFollowers, setNumFollowers] = useState(0);
    const [numFollowing, setNumFollowing] = useState(0);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followButtonText, setFollowButtonText] = useState("");
    const [favouriteTeamLogoPaths, setFavouriteTeamLogoPaths] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [role, setRole] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authInfo = useSelector(state => state.authInfo);

    const isUnfollowMenuOpen = Boolean(anchorEl);
    const DefaultUserIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/default_user.svg`;

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    useEffect(() => {
        const pName = props.name;
        const pEmail = props.email;
        const pStatusMessage = props.statusMessage;
        const pHeadProfileFromResponse = props.headProfileFromResponse;
        const pIsFollowing = props.isFollowing;
        const pNumFollowers = props.numFollowers;
        const pNumFollowing = props.numFollowing;
        const pFavouriteTeamLogoPaths = props.favouriteTeamLogoPaths;
        const pRole = props.role;
        
        setName(pName);
        setEmail(pEmail);
        setStatusMessage(pStatusMessage);

        if(pHeadProfileFromResponse === null) {
            setHeadProfileImage(DefaultUserIcon);
        } else {
            setHeadProfileImage(pHeadProfileFromResponse);
        }

        handleFollowButtonText(pIsFollowing);
        setIsFollowing(pIsFollowing);
        setNumFollowers(pNumFollowers);
        setNumFollowing(pNumFollowing);
        setFavouriteTeamLogoPaths(pFavouriteTeamLogoPaths);
        setRole(pRole);
    }, []); // dependency를 걸지 않음으로써 처음 한번 컴포넌트 호출시에만 useEffect가 호출된다.

    const handleFollowButtonText = (following) => {
        if(following) {
            setFollowButtonText("Following");
        } else {
            setFollowButtonText("Follow");
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        setHeadProfileImage('');
        closeModal();
    }

    const showFollowText = () => {
        return followButtonText;
    }

    const handleIsFollowing = (resIsFollowing) => {
        setIsFollowing(resIsFollowing);
    }

    const handleNumFollowersChange = (newNumFollowers) => {
        setNumFollowers(newNumFollowers);
    }

    const handleNumFollowingChange = (newNumFollowing) => {
        setNumFollowing(newNumFollowing);
    }

    const followOrUnfollow = (e) => {
        e.preventDefault();

        if(followButtonText === "Follow" && !isFollowing) {
            processFollowOrUnfollow();
        } else {
            handleUnfollowMenuClick(e);
        }
    }

    const processFollowOrUnfollow = () => {
        const dataToPass = {
            followee_id: props.userId
        }
        FollowAction.followOrUnfollow(dataToPass, dispatch).then(r => {
            if(r !== null) {
                const responseCode = r.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = r.data.responseBody;
                    handleFollowButtonText(data.isFollowing);
                    handleIsFollowing(data.isFollowing);
                    handleNumFollowersChange(data.numFollowers);
                    handleNumFollowingChange(data.numFollowing);
                } else if(responseCode === RESP_CODE.CANNOT_FOLLOW_YOURSELF) {
                    alert("자기 자신을 팔로우 할 수 없습니다.");
                }
            }
        });

        handleUnfollowMenuClose();
    }

    const showUnfollowOption = () => {
        return (
            <MenuItem onClick={processFollowOrUnfollow}>Unfollow</MenuItem>
        )
    }

    const goToMyAccountPage = (e) => {
        e.preventDefault();
        navigate('/myaccount');
        closeModal();
        return;
    }

    const showFollowButton = () => {
        const loggedInUserId = getUserId();

        // 사용자가 로그인 한 상태이며, 자기 자신이 아닌 회원의 프로필을 열어봤을 때만 팔로우 버튼을 보여준다.
        if(authInfo.loggedIn && props.userId !== loggedInUserId) {
            return (
                <div className={"horizontal-container horizontal-center"}>
                    <button className={"button5 font-sm"} onClick={e => followOrUnfollow(e)}>{showFollowText()}</button>
                    <Menu open={isUnfollowMenuOpen} anchorEl={anchorEl} onClose={handleUnfollowMenuClose}>{showUnfollowOption()}</Menu>
                </div>
            )
        }

        if(authInfo.loggedIn && props.userId === loggedInUserId) {
            return (
                <div className={"horizontal-container horizontal-center"}>
                    <button className={"button5 font-mid"} onClick={e => goToMyAccountPage(e)}>내 정보</button>
                </div>
            )
        }
    }

    const showStatusMessage = () => {
        if(statusMessage !== '') {
            return <div className={"font-mid horizontal-center"}>{statusMessage}</div>;
        }
    }

    const showFollowInfo = () => {
        return (
            <div className={"horizontal-container horizontal-center"}>
                <div className={"font-mid"}>{numFollowers} Followers</div>
                <div style={{ marginRight: '12px'}}/>
                <div className={"font-mid"}>{numFollowing} Following</div>
            </div>
        )
    }

    const handleUnfollowMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleUnfollowMenuClose = () => {
        setAnchorEl(null);
    };

    const showFavouriteTeamLogos = () => {
        if(favouriteTeamLogoPaths.length > 0) {
            return (
                <div className={"page"}>
                    <div className={"vertical-gap-sm"}/>

                    <AvatarGroup max={4}>
                        {
                            favouriteTeamLogoPaths.map((logoPath, index) => {
                                return (
                                    <Avatar alt={"favourite team"} src={logoPath}/>
                                )
                            })
                        }
                    </AvatarGroup>
                </div>
            )
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
        >
            <div className={"vertical-container padding"}>
                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-center"}>
                    <img className={"profile-image"} src={headProfileImage}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"font-xl horizontal-center"}>
                    {name}
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"font-mid horizontal-center"}>
                    {mapRole[role]}
                </div>


                <div className={"vertical-gap-sm"}/>

                {showStatusMessage()}

                {showFavouriteTeamLogos()}

                <div className={"vertical-gap-sm"}/>

                {showFollowInfo()}

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-center fullwidth"}>
                    <div className={"space-between button-group1"}>
                        {showFollowButton()}
                        <div className={"horizontal-gap-mid"}/>
                        <button className={"button5 font-sm"} onClick={e => handleClose(e)}>닫기</button>
                    </div>
                </div>

                <div className={"vertical-gap-sm"}/>
            </div>
        </Dialog>
);
}
export default ProfileModal;