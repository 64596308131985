import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import PostListTable from "../component/PostListTable";
import EditNoteIcon from '@mui/icons-material/EditNote';

import {useSelector} from "react-redux";
import {isMobile, isTablet} from "../../util/device";
import MainLayout from "./MainLayout";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from '@mui/icons-material/Search';
import PostAction from "../../api/action/PostAction";
import useModal from "../../hooks/useModal";
import { CustomPaginationRounded } from "../component/CustomPaginationRounded";
import PendingIcon from '@mui/icons-material/Pending';

import RESP_CODE from "../../const/response-code";

export default function BoardPage ({titleName}) {
    const params = useParams();
    const [searchParams, setSearchParams]  = useSearchParams();
    const [page, setPage] = useState(1);
    const boardUrlName = params.boardUrlName;
    const [limit, setLimit] = useState(initialLimit());
    const [numPage, setNumPage] = useState(0);
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();
    const authInfo = useSelector(state => state.authInfo);
    const [boardName, setBoardName] = useState('');
    const [parentBoardName, setParentBoardName] = useState(null);
    const [parentBoardUrl, setParentBoardUrl] = useState(null);
    const [keyword, setKeyword] = useState(null);
    const [criteria, setCriteria] = useState('TITLE');
    const {openModal} = useModal();
    const refCriteria = useRef(null);
    const refKeyword = useRef(null);
    const [noPosts, setNoPosts] = useState(false);
    const [postsLoaded, setPostsLoaded] = useState(false);

    async function getPosts(boardUrlName, page) {
        return await PostAction.getPosts(boardUrlName, page);
    }

    async function getPostSearchResult(criteria, keyword, boardUrlName, page) {
        return await PostAction.getBySearchKeyword(criteria, keyword, boardUrlName, page);
    }

    function initialLimit() {
        if (searchParams.get('limit') === null) {
            return 20;
        } else {
            return searchParams.get('limit');
        }
    }

    const processDisplayPosts = (data) => {
        const parentBoardName = data.parent_board_name;
        setParentBoardName(parentBoardName);

        const parentBoardUrl = data.parent_board_url;
        setParentBoardUrl(parentBoardUrl);

        const boardName = data.board_name;
        setBoardName(boardName);

        const totalCount = data.total_count;
        const numPagesFromTotalCount = Math.ceil(totalCount / limit);
        setNumPage(numPagesFromTotalCount);

        const posts = data.posts;
        setPosts(posts);

        if(data.posts.length === 0) {
            setNoPosts(true);
        }

        titleName(boardName);
    }

    useEffect(() => {
        let query = `?page=${page}&limit=${limit}`;

        if(keyword !== null && criteria !== null) {
            query += `&keyword=${keyword}&criteria=${criteria}`;

            getPostSearchResult(criteria, keyword, boardUrlName, page).then((resp) => {
                const respCode = resp.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                    const responseData = resp.data.responseBody;
                    processDisplayPosts(responseData);
                }
            });
        } else {
            getPosts(boardUrlName, page).then((resp) => {
                const responseData = resp.data.responseBody;
                if (responseData !== null && responseData !== undefined) {
                    processDisplayPosts(responseData);
                }
            });
        }

        const queryURL = `/board/${boardUrlName}${query}`;
        navigate(queryURL, { replace: true });
        setPostsLoaded(true);
    }, [boardUrlName, page, keyword, criteria]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const search = async (event) => {
        if(event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            searchPosts();
        }
    }

    const insertLinkParentBoard = () => {
        if(parentBoardName !== null && parentBoardUrl !== null) {
            return (
                <div className={"horizontal-container"}>
                    <div className={"horizontal-gap-sm"}/>
                    <div className={"font-lg"}> / </div>
                    <div className={"horizontal-gap-sm"}/>
                    <a className={"link font-lg"} href={`/board/${parentBoardUrl}`}>{parentBoardName}</a>
                </div>
                
            )
        }
    }

    const displayCurrentBoard = () => {
        return (
            <div className="horizontal-container pd-mid vertical-center">
                <a className={"link font-lg"} href={`/`}>홈</a>
                {insertLinkParentBoard()}
                <div className={"horizontal-gap-sm"}/>
                <div className={"font-lg"}> / </div>
                <div className={"horizontal-gap-sm"}/>
                <a className={"link font-xl"} href={`/board/${boardUrlName}`}>{boardName}</a>
            </div>
        );
    }

    const btnWritePost = () => {
        if(authInfo.loggedIn) {
            return (
                <div className={"fab"} onClick={() => {
                    navigate(`/board/${boardUrlName}/post/create`);
                }}>
                    <CreateIcon style={{ 
                        color: '#343434',
                        margin: '0',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}/>
                </div>
            )
        }
    }

    const searchPosts = () => {
        const currentCriteria = refCriteria.current.value;
        const currentKeyword = refKeyword.current.value;

        if(currentKeyword === '' || currentKeyword === null) {
            const propsToPass = {
                open: true,
                message: '검색어가 없습니다.',
            }
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(currentCriteria === '' || currentCriteria === null) {
            const propsToPass = {
                open: true,
                message: '검색 조건이 없습니다.',
            }
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        setKeyword(currentKeyword);
        setCriteria(currentCriteria);
        setPage(1);
    }

    const postSearchTypeMenu = () => {
        const options = [
            { key: 'TITLE', label: '제목' },
            { key: 'CONTENTS', label: '내용' },
            { key: 'AUTHOR', label: '글쓴이' }
        ];
        return (
            options.map(value => {
                return <option key={value.key} value={value.key} className={"font-sm"}>{value.label}</option>
            })
        );
    }

    const displayPostSearchBar = () => {
        if(numPage <= 0) {
            return;
        }

        let containerClass = "horizontal-container fullwidth pd-mid";
        if(isMobile()) {
            containerClass += " pd-sm";
        }

        return (
            <div className={containerClass}>
                <select
                    ref={refCriteria}
                    className={"dropdown-select font-sm horizontal-item-first"}
                >
                    {postSearchTypeMenu()}
                </select>

                <input
                    ref={refKeyword}
                    className={"horizontal-item-mid post-search font-sm"}
                    type="text"
                    placeholder="검색어를 입력해 보세요"
                    onKeyDown={search}
                />

                <div className={"icon-wrapper horizontal-item-end"} onClick={() => searchPosts()}>
                    <SearchIcon
                    style={{
                        verticalAlign: 'middle'
                    }}
                    />
                </div>
            </div>
        );
    }

    const displayPagination = () => {
        if(numPage > 1) {
            return (
                <CustomPaginationRounded numPage={numPage} change={handleChange}/>
            );
        }
    }

    const fontSize = function() {
        if(isMobile()) {
            return 128;
        } else if(isTablet()) {
            return 144;
        }
        return 192;
    }

    const displayContents = () => {
        if(postsLoaded) {
            if(posts.length === 0) {
                return (
                    <div className="fullwidth bg-light-grey vertical-padding-sm">
    
                        <div className="horizontal-center">
                            <EditNoteIcon 
                                sx={{ 
                                    fontSize: fontSize,
                                    color: '#aeaeae'
                                }}
                            />
                        </div>
                        
    
                        <div className={"vertical-gap-sm"}/>
    
                        <div className={"horizontal-center font-light-grey font-mid"}>아직 게시물이 없습니다. 첫 글을 남겨 보시겠어요?</div>
                    </div>
                )
            } else {
                return (
                    <div className="fullwidth">
                        {displayPostSearchBar()}
    
                        <div className={"vertical-gap-sm"}/>
    
                        <PostListTable boardUrlName={boardUrlName} posts={posts}/>
    
                        <div className={"vertical-gap-sm"}/>
    
                        {displayPagination()}
                    </div>
                )
            }
        } else {
            return (
                <div className="fullwidth bg-light-grey vertical-padding-sm">

                    <div className="horizontal-center">
                        <PendingIcon 
                            sx={{ 
                                fontSize: fontSize,
                                color: '#aeaeae'
                            }}
                        />
                    </div>
                    

                    <div className={"vertical-gap-sm"}/>

                    <div className={"horizontal-center font-light-grey font-mid"}>게시물을 불러오는 중입니다.</div>
                </div>
            )
        }
    }

    const renderTabletOrDesktop = (posts) => {
        return (
            <MainLayout>
                <div className={"vertical-container main-contents"}>

                    <div className={"vertical-gap-mid"}/>

                    <div className="title top font-xl fullwidth">
                        {displayCurrentBoard()}
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    {displayContents()}
                </div>

                {btnWritePost()}
            </MainLayout>
        );
    }

    const renderMobile = (posts) => {
        return (
            <MainLayout className={"main-contents"}>
                <div className="top pd-sm">
                    {displayCurrentBoard()}
                </div>

                <div className={"vertical-gap-sm"}/>

                {displayContents()}

                <div className={"vertical-gap-sm"}/>

                {btnWritePost()}
            </MainLayout>
        );
    }

    const renderByDeviceType = (rows) => {
        if(isMobile()) {
            return renderMobile(rows);
        }
        return renderTabletOrDesktop(rows);
    }

    return (
        renderByDeviceType(posts)
    )
};