import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import React, {useEffect, useState} from "react";
import useModal from "../../../hooks/useModal";
import ReportAction from "../../../api/action/ReportAction";
import RESP_CODE from "../../../const/response-code";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const ReportModal = (props) => {
    const [title, setTitle] = useState('');
    const [reportReason, setReportReason] = useState('VIOLENT');
    const [contentsToEdit, setContentsToEdit] = useState('');
    const {openModal, closeModal} = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle(props.title);
    }, []);

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function report(event) {
        event.preventDefault();

        if(props.reportedContentsType === 'post') {
            reportPost();
        } else {
            reportComment();
        }
    }

    function reportPost() {
        ReportAction.reportPost({
            reporting_user_id: props.reportingUserId,
            reported_post_id: props.postId,
            details: contentsToEdit,
            report_reason: reportReason
        }, dispatch).then(resp => {
            if(resp !== null) {
                const responseCode = resp.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    alert("게시글 신고가 정상적으로 접수되었습니다.");
                }
            }
            closeModal();
        }).catch(error => {
            console.error(`에러 발생: ${error}`);
        });
    }

    function reportComment() {
        ReportAction.reportComment({
            reporting_user_id: props.reportingUserId,
            reported_comment_id: props.commentId,
            details: contentsToEdit,
            report_reason: reportReason
        }, dispatch).then(resp => {
            if(resp !== null) {
                const responseCode = resp.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    alert("댓글 신고가 정상적으로 접수되었습니다.");
                }
            }
            closeModal();
        }).catch(error => {
            console.error(`에러 발생: ${error}`);
        });
    }

    function onChangeContents(e) {
        e.preventDefault();
        setContentsToEdit(e.target.value);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setContentsToEdit('');
        closeModal();
    }

    const inquiryTypeMenu = () => {
        const options = [
            { key: 'VIOLENT', label: '욕설, 비방, 혐오' },
            { key: 'VIOLATION_ILLEGAL_INFORMATION', label: '불법 정보' },
            { key: 'VIOLATION_PRIVACY_POLICY', label: '개인 정보 노출 및 유포' },
            { key: 'VIOLATION_RIGHTS', label: '권리 침해' },
            { key: 'SPAM', label: '스팸' },
            { key: 'OTHERS', label: '기타' }
        ];
        return (
            options.map(value => {
                return <option key={value.key} value={value.key} className={"option"}>{ value.label }</option>
            })
        );
    }

    function handleReportReasonChange(event) {
        event.preventDefault();
        setReportReason(event.target.value);
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>

            <div style={{paddingLeft: '8px', paddingRight: '8px'}}>
                <div className={"dropdown-wrapper"}>
                    <select className={"dropdown-select"} onChange={e => handleReportReasonChange(e)}>
                        {inquiryTypeMenu()}
                    </select>
                </div>

                <div className={"vertical-gap-sm"}/>

                <input className={"input light-grey"} defaultValue={contentsToEdit} style={{outline: "none", width: '90%'}}
                       onChange={e => onChangeContents(e)}/>
            </div>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>취소</Button>
                <Button onClick={e => report(e)}>신고하기</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReportModal;