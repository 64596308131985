import React, {useEffect, useRef, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import {AppBar, Box, ClickAwayListener, Container, Toolbar} from "@mui/material";
import {createSearchParams, useNavigate} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { Notifications } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import { Menu, MenuItem} from '@mui/material';
import NotificationAction from '../../api/action/NotificationAction';
import { isMobile } from "../../util/device";
import { useSelector, useDispatch } from 'react-redux';
import AuthAction from '../../api/action/AuthAction';
import allActions from "../../redux/action";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { menuItems, optionsForLoggedInUsers, optionsForNotLoggedInUsers } from '../../const/menu-items';
import { Main } from './Main';
import { MobileAppBar } from './MobileAppBar';
import { DrawerHeader } from './DrawerHeader';
import { SearchIconWrapper } from './SearchIconWrapper';
import { StyledInputBase } from './StyledInputBase';
import { Search } from './Search';
import { drawerWidth } from './values';

import RESP_CODE from "../../const/response-code";
import useModal from "../../hooks/useModal";

function PrimaryHeader() {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const [invisible, setInvisible] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const refDrawer = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();
    const theme = useTheme();
    const {openModal} = useModal();

    const isNotiPopupOpen = Boolean(anchorEl);
    const placeholder = "전체 게시물 검색";

    useEffect(() => {
        NotificationAction.history().then((resp) => {
            if(resp === null) {
                return;
            }

            const responseCode = resp.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const unreadCount = resp.data.responseBody[0];
                console.log(unreadCount);
                setUnreadNotificationsCount(unreadCount);
                if(unreadCount > 0) {
                    setInvisible(false);
                }

                const notificationData = resp.data.responseBody[1];
                if(notificationData) {
                    notificationData.forEach(function(notification) {
                        if(notification.type === 'ROLE_PROMOTION' && notification.isRead === false) {
                            const propsToPass = {
                                open: true,
                                notificationId: `${notification.notification_id}`,
                                message: `${notification.message}`
                            };
                            openModal({type: 'promotion', props: propsToPass});
                        }
                    })
                    setNotificationMessages(notificationData);
                }
            }
        });
    }, []);

    const handleClickAway = () => {
        setIsDrawerOpen(false);
    };

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleNotiPopupClose = () => {
        setAnchorEl(null);
        if(unreadNotificationsCount > 0) {
            setUnreadNotificationsCount(unreadNotificationsCount - 1);
        }
    };

    const handleInput = event => {
        setSearchKeyword(event.target.value);
    }

    const search = async (event) => {
        if(event.keyCode === 13) {
            event.preventDefault();
            await proceedSearch(searchKeyword);
        }
    }

    async function proceedSearch(searchKeyword) {
        if(searchKeyword !== '') {
            navigate(`/search?type=post&keyword=${searchKeyword}`);
            setSearchKeyword('');
        }
    }

    function navigateToPost(notificationId, urlName, postId) {
        NotificationAction.markAllAsRead(dispatch).then(response => {
            if(response !== null) {
                const respCode = response.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                    navigate(`/board/${urlName}/post/${postId}`);
                    handleNotiPopupClose();
                }
            }
        });
    }

    function notificationHistory() {
        return notificationMessages.map((item, index) => {
            return (
                <MenuItem key={index} onClick={e => navigateToPost(item.notification_id, item.url_name, item.post_id)}>{item.message}</MenuItem>
            )
        })
    }

    function goToPage(e, item) {
        e.preventDefault();

        const key = item.key;

        if(key === 'logout') {
            setIsDrawerOpen(false);
            logout();
            return;
        }

        if(item.is_board === true) {
            navigate({
                pathname: item.url, 
                search: createSearchParams({
                    page: 1,
                    limit: 20
                }).toString()
            });
        } else {
            navigate(item.url);
        }
        window.location.reload();
        setIsDrawerOpen(false);
    }

    async function logout() {
        if (!authInfo.loggedIn) {
            return;
        }

        await AuthAction.logout(
            authInfo.details.email
        ).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                dispatch(allActions.userActions.logout());
                navigate('/', {replace: true});
            }
        });
    }

    function secondaryOption() {
        let secondaryMenu;
        if(authInfo.loggedIn) {
            secondaryMenu = optionsForLoggedInUsers;

        } else {
            secondaryMenu = optionsForNotLoggedInUsers;
        }

        return (
            secondaryMenu.map((item, index) => {
                return (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={e => goToPage(e, item)}>
                            <ListItemText primary={item.name}/>
                        </ListItemButton>
                    </ListItem>
                )
            })
        )
    }

    const closeDrawer = (event) => {
        event.preventDefault();

        if(isDrawerOpen) {
            handleDrawerClose();
        }
    }

    function renderByDeviceType() {
        if(isMobile()) {
            return renderMobile();
        }
        return renderTabletDesktop();
    }

    const listMenuItem = (item) => {
        return (
            <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={e => goToPage(e, item)}>
                    <ListItemText primary={item.name}/>
                </ListItemButton>
            </ListItem>
        )
    }

    const userInfoSummary = () => {
        if(authInfo.loggedIn) {
            return (
                <div>
                    <List>
                        <div className={"vertical-container"} style={{ padding: '8px 16px' }}>
                            {authInfo.details.name} 회원님, 안녕하세요!
                        </div>
                    </List>
                    <Divider />
                </div>
            )
        }
    }

    function renderMobile() {
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ display: 'flex' }} style={{ width: '100%' }}>
                    <CssBaseline />
                    <MobileAppBar position="fixed" open={isDrawerOpen} style={{ boxShadow: 'none'}}>
                        <Toolbar style={{ backgroundColor: '#1d2c6b' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(isDrawerOpen && { display: 'none' }) }}
                            >

                                <MenuIcon />

                            </IconButton>

                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder={placeholder}
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={searchKeyword}
                                    onChange={handleInput}
                                    onFocus={e => closeDrawer(e)}
                                    onKeyDown={e => search(e)}
                                />
                            </Search>

                            <div className={"horizontal-gap-sm"}/>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={isNotiPopupOpen}
                                onClose={handleNotiPopupClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {notificationHistory()}
                            </Menu>

                            <Badge className={"badge-mobile"} badgeContent={unreadNotificationsCount} invisible={invisible} style={{ cursor: 'pointer' }}showZero>
                                <Notifications
                                    onClick={handleNotificationClick}
                                />
                            </Badge>
                        </Toolbar>
                    </MobileAppBar>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={isDrawerOpen}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        {userInfoSummary()}
                        <List>
                            {menuItems.map((item, index) => (
                                listMenuItem(item)
                            ))}
                        </List>
                        <Divider />
                        <List>
                            {secondaryOption()}
                        </List>
                    </Drawer>

                    <Main style={{ paddingTop: '2px', paddingBottom: '2px' }} open={isDrawerOpen}>
                        <DrawerHeader />
                    </Main>
                </Box>
            </ClickAwayListener>
        );
    }

    function renderTabletDesktop() {
        return (
            <Box sx={{ flexGrow: 1 }} style={{ width: '100%' }}>
                <AppBar position="static" elevation={0} style={{ backgroundColor: "#1d2c6b" }}>
                    <Container className={"PrimaryHeader"} style={{ paddingLeft: "30px", paddingRight: "30px" }}  maxWidth="xl">
                        <Toolbar style={{ minHeight: "30px", display: "flex", justifyContent: "space-between" }} sx={{ boxShadow: 0 }} disableGutters>
                            <a href={"/"} className={"main-logo"}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-yagooforum-en-navy.svg`} alt={"야구포럼 로고"}/>
                            </a>

                            <div className={"search"}>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={isNotiPopupOpen}
                                    onClose={handleNotiPopupClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {notificationHistory()}
                                </Menu>

                                <Badge badgeContent={unreadNotificationsCount} invisible={invisible} style={{ cursor: 'pointer' }} showZero>
                                    <Notifications
                                        onClick={handleNotificationClick}
                                    />
                                </Badge>

                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder={placeholder}
                                        inputProps={{ 'aria-label': 'search' }}
                                        value={searchKeyword}
                                        onChange={handleInput}
                                        onKeyDown={e => search(e)}
                                    />
                                </Search>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Box>
        )
    }

    return renderByDeviceType();
}

export default PrimaryHeader;