import ApiUtils from "./APIUtils";

class ProfileApi {
    constructor() {
        this.URL_PREFIX = "/v1/profile";
    }

    edit(data) {
        return ApiUtils.put(this.URL_PREFIX + '/', data);

    }
}

export default new ProfileApi();