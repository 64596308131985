import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AuthAction from "../../api/action/AuthAction";
import RESP_CODE from "../../const/response-code";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import allActions from "../../redux/action";
import { isMobile, isTablet } from '../../util/device';
import { menuItems } from '../../const/menu-items';

const SecondaryHeader = ({currentBoardName}) => {
    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    async function logout() {
        if (!authInfo.loggedIn) {
            return;
        }

        await AuthAction.logout(
            authInfo.details.email
        ).catch((resp) => {
            
        }).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                dispatch(allActions.userActions.logout());
                navigate('/', {replace: true});
            }
        });
    }

    function displayUserMenu() {
        if(authInfo.loggedIn) {
            return (
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                    <button
                        key={'myaccount'}
                        onClick={() => navigate('/myaccount')}
                        className={"mypage-auth-button upper font-mid"}
                    >
                        내 정보
                    </button>
                    <button
                        key={'logout'}
                        onClick={() => logout()}
                        className={"mypage-auth-button upper font-mid"}
                    >
                        로그아웃
                    </button>
                </Box>
            )
        } else {
            return (
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, width: 'fit-content' }}>
                    <button
                        key={'login'}
                        onClick={() => navigate('/login')}
                        className={"mypage-auth-button upper font-mid"}
                    >
                        로그인
                    </button>
                    <button
                        key={'signup'}
                        onClick={() => navigate('/signup')}
                        className={"mypage-auth-button upper font-mid"}
                    >
                        회원가입
                    </button>
                </Box>
            );
        }
    }

    const menus = menuItems.map((item) => {
        let link;

        const nonBoards = ["news/baseball", "inquiry", "report"];

        if(nonBoards.includes(item.key)) {
            link = `/${item.key}`
        } else {
            link = `/board/${item.key}`;
        }

        if(item.key !== 'home') {
            return (
                <button key={item.key} className={"menu-button category font-mid"}>
                    <a key={item.key} href={link} className={"category-link"}>{item.name}</a>
                </button>
            );
        }
    })

    function renderTabletDesktop() {
        return (
            <Toolbar sx={{ minHeight: '0' }} className={"toolbar"} variant={"dense"} disableGutters>
                {displayUserMenu()}
                <Box className={"box category-list"}>
                    {menus}
                </Box>
            </Toolbar>
        );
    }

    function renderByDeviceType() {
        if(!isMobile()) {
            return renderTabletDesktop();
        }
    }

    return (
        renderByDeviceType()
    )
};
export default SecondaryHeader;
