import ApiUtils from "./APIUtils";

class ReactionApi {
    constructor() {
        this.URL_PREFIX = "/v1/reaction";
    }

    create(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + "/", data, dispatch);
    }

    getCounts(postId) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/${postId}/counts`);
    }
}

export default new ReactionApi();