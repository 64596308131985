import ApiUtils from "./APIUtils";

class BoardApi {
    constructor() {
        this.URL_PREFIX = "/v1/board";
    }

    get(pid) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/${pid}`);
    }

    getByUrlName(urlName) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/urlName/${urlName}`);
    }

    getAll() {
        return ApiUtils.get(`${this.URL_PREFIX}/all`);
    }

    create(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + "/", data, dispatch);
    }

    edit(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/", data, dispatch);
    }

    delete(data) {
        return ApiUtils.delete(this.URL_PREFIX + "/", data);
    }
}

export default new BoardApi();