import OAuth2 from "../OAuth2";

const RESP_CODE = require('../../const/response-code');

export default {

    signupKakao: async (generatedCode, username, favouriteTeamIds) => {
        return OAuth2.signupKakao({
            generatedCode: generatedCode,
            username: username,
            favourite_team_ids: favouriteTeamIds
        }).then((resp) => {
            return resp;
        });
    },

    signupNaver: async (generatedCode, username, favouriteTeamIds) => {
        return OAuth2.signupNaver({
            generatedCode: generatedCode,
            username: username,
            favourite_team_ids: favouriteTeamIds
        }).then((resp) => {
            return resp;
        });
    },

    signupGoogle: async (generatedCode, username, favouriteTeamIds) => {
        return OAuth2.signupGoogle({
            generatedCode: generatedCode,
            username: username,
            favourite_team_ids: favouriteTeamIds
        }).then((resp) => {
            return resp;
        });
    }
};
