import ApiUtils from "./APIUtils";

class TermsAndConditionsApi {
    constructor() {
        this.URL_PREFIX = "/v1/terms";
    }

    get(pid) {
        return ApiUtils.get(`${this.URL_PREFIX}` + '/');
    }
}

export default new TermsAndConditionsApi();