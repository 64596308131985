import {useEffect, useRef} from "react";
import AuthAction from "../../api/action/AuthAction";
import RESP_CODE from "../../const/response-code";
import {useNavigate} from "react-router-dom";
import { isEmailValid } from "../../util/validator";
import useSnackbar from "../../hooks/useSnackbar";
import { useSelector } from "react-redux";

const ForgotPasswordPage = ({titleName}) => {
    const navigate = useNavigate();
    const refEmail = useRef(null);
    const {openSnackbar} = useSnackbar();
    const authInfo = useSelector(state => state.authInfo);

    useEffect(() => {
        titleName('비밀번호 찾기');

        // 이 페이지도 로그인 한 회원에게는 보일 필요가 없으므로, 로그인 상태인 경우에는 메인 화면으로 강제 리다이렉트 시킨다.
        if(authInfo.loggedIn) {
            navigate('/', {replace: true});
        }
    }, []);

    async function createTempChangePasswordLinkToEmail(e) {
        e.preventDefault();
        const email = refEmail.current.value.trim();

        if (email === '') {
            openSnackbar({isOpen: true, message: '이메일 주소를 입력해 주세요.', severity: 'error'});
            return;
        }

        if(!isEmailValid(email)) {
            console.log('not valid');
            openSnackbar({isOpen: true, message: '올바르지 않은 이메일 주를 입력하셨습니다.', severity: 'error'});
            return;
        }

        await AuthAction.createTempChangePasswordLink(email).then(response => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                openSnackbar({isOpen: true, message: '회원님의 이메일로 비밀번호 재설정을 위한 임시 링크를 보냈습니다.', severity: 'info'});
                navigate('/', {replace: true});
            } else if (responseCode === RESP_CODE.USER_NOT_FOUND) {
                openSnackbar({isOpen: true, message: "입력하신 이메일에 해당하는 사용자가 없습니다.", severity: 'error'});
                return;
            } else {
                openSnackbar({isOpen: true, message: `에러가 발생했습니다 [${responseCode}].`, severity: 'error'});
                return;
            }
        })
    }

    return (
        <div className={"page"}>
            <form className={"form"} onSubmit={e => createTempChangePasswordLinkToEmail(e)}>
                <input
                    ref={refEmail}
                    className={"input light-grey"}
                    type={"email"}
                    placeholder={"이메일 주소를 입력해 주세요."}
                />
                <button className={"button font-mid"} type={"submit"}>임시 링크 받기</button>
            </form>
        </div>
    )
}

export default ForgotPasswordPage;