import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import AuthAction from "../../api/action/AuthAction";
import RESP_CODE from "../../const/response-code";

const ChangePasswordWithCodePage = ({titleName}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const code = searchParams.get("code");
    const navigate = useNavigate();

    useEffect(() => {
        titleName("패스워드 변경 - 야구포럼 (YagooForum)");
        
        AuthAction.checkTempChangePasswordLinkValidity(code).then(response => {
            const isTempLinkValid = response.data.isTempLinkValid;

            if(!isTempLinkValid) {
                alert('유효하지 않은 링크입니다.');
                navigate('/', {replace: true});
            }
        });
    }, []);

    async function changePassword(e) {
        e.preventDefault();

        if(newPassword !== newPassword2) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        AuthAction.changePasswordWithCode({
            newPassword: newPassword,
            code: code
        }).then((response) => {
            if(response.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                alert("패스워드가 성공적으로 변경되었습니다. 다시 로그인 해보시겠어요?");
            }

            else {
                alert(response.data.responseCode);
            }
            
            navigate('/', {replace: true});
        })

    }

    return (
        <form className={"form"} onSubmit={e => changePassword(e)}>
            <input className={"input light-grey"} type={"password"} placeholder={"비밀번호를 입력해 주세요"} onChange={e => setNewPassword(e.target.value)} autoComplete={"off"}/>
            <input className={"input light-grey"} type={"password"} placeholder={"비밀번호를 한번 더 입력해 주세요"} onChange={e => setNewPassword2(e.target.value)} autoComplete={"off"}/>

            <button type={"submit"} className={"button font-mid"}>비밀번호 변경</button>
        </form>
    )
}

export default ChangePasswordWithCodePage;