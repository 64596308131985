import ApiUtils from "./APIUtils";

class FollowApi {
    constructor() {
        this.URL_PREFIX = "/v1/follow";
    }

    followOrUnfollow(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/", data, dispatch);
    }

    getFollowInfo(toId, dispatch = null) {
        return ApiUtils.get(this.URL_PREFIX + `/info/${toId}`, dispatch);
    }
}

export default new FollowApi();