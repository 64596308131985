import {useEffect, useState} from "react";
import BoardAction from "../../api/action/BoardAction";
import RESP_CODE from "../../const/response-code";
import Board from "../component/board/Board";
import MainLayout from "./MainLayout";

export const AllBoardsPage = ({titleName}) => {
    const [boardIds, setBoardIds] = useState([]);
    const [boardsList, setBoardsList] = useState([]);

    async function getAllBoards() {
        return await BoardAction.getAll();
    }

    const boardIdsHandler = (boardId) => {
        setBoardIds([...boardIds, boardId]);
    }

    useEffect(() => {
        titleName('전체 게시판');

        getAllBoards().then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const boards = response.data.responseBody;
                setBoardsList(boards);
            }
        });
    }, []);

    function renderAllBoards() {
        return boardsList.map((item, index) => {
            if(item.parent_board_id === null) {
                return (
                    <Board key={item.board_id} index={index} props={item} />
                )
            }
        });
    }

    return (
        <MainLayout>
            <div className={"grid-2"}>
                {renderAllBoards()}
            </div>
        </MainLayout>
    )
}