import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import useModal from "../../../hooks/useModal";
import PostAction from "../../../api/action/PostAction";
import RESP_CODE from "../../../const/response-code";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

const DeletePostModal = (props) => {
    const {closeModal} = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function deletePost(event) {
        event.preventDefault();
        PostAction.delete({
            post_id: props.postId,
        }, dispatch).then(resp => {
            if(resp !== null) {
                const responseCode = resp.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    navigate(`/board/${props.boardUrlName}`, {replace: true});
                    closeModal();
                }
            }
        }).catch(error => {
            console.error(`게시물 삭제 중 에러 발생: ${error}`);
        });
    }

    const handleClose = (e) => {
        e.preventDefault();
        closeModal();
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>아니오</Button>
                <Button onClick={e => deletePost(e)}>네</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeletePostModal;