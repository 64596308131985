import {useRef, useState} from "react";
import AuthAction from "../../api/action/AuthAction";
import useSnackbar from "../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { isPasswordValid } from "../../util/validator";

const RESP_CODE = require('../../const/response-code');

const ChangePasswordPage = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const refCurrentPassword = useRef(null);
    const refNewPassword = useRef(null);
    const refNewPassword2 = useRef(null);
    const {openSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const changePassword = (event) => {
        event.preventDefault();

        const currentPassword = refCurrentPassword.current.value;
        const newPassword = refNewPassword.current.value;
        const newPassword2 = refNewPassword2.current.value;

        if(newPassword !== newPassword2) {
            openSnackbar({isOpen: true, message: "새 비밀번호가 일치하지 않습니다.", severity: 'error'});
            return;
        }

        if(!isPasswordValid(newPassword)) {
            openSnackbar({isOpen: true, message: "패스워드는 8자 이상 50자 이하여야 하며, 최소 1개 이상의 특수문자와 소문자, 대문자의 조합으로 구성되어야 합니다.", severity: 'error'});
            return;
        }

        AuthAction.changePassword({
            currentPassword: currentPassword,
            newPassword: newPassword
        }).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                openSnackbar({isOpen: true, message: '패스워드가 성공적으로 변경되었습니다.', severity: 'success'});
                navigate('/', {replace: true});
            } else {
                openSnackbar({isOpen: true, message: `에러가 발생했습니다. [${responseCode}]`, severity: 'error'});
                return;
            }
        })
    }

    return (
        <div className={"page fullwidth"}>
            <form className={"form"} style={{padding: '36px 24px'}}>
                <div className={"horizontal-container"} style={{width: '100%'}}>
                    <div style={{width: '15%', color: '#6f6f6f'}}>현재 비밀번호</div>
                    <input ref={refCurrentPassword} className={"input light-grey"} style={{width: '55%'}}
                           type={"password"} autoComplete={"off"}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container"} style={{width: '100%'}}>
                    <div style={{width: '15%', color: '#6f6f6f'}}>새 비밀번호</div>
                    <input ref={refNewPassword} className={"input light-grey"} style={{width: '85%'}} type={"password"}
                           autoComplete={"off"}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container"} style={{width: '100%'}}>
                    <div style={{width: '15%', color: '#6f6f6f'}}>새 비밀번호 (재입력)</div>
                    <input ref={refNewPassword2} className={"input light-grey"} style={{width: '85%'}} type={"password"}
                           autoComplete={"off"}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <button className={"button font-mid"} onClick={e => changePassword(e)}>비밀번호 변경</button>
            </form>
        </div>
    )
};

export default ChangePasswordPage;
