import ApiUtils from "./APIUtils";

class ImageApi {
    constructor() {
        this.URL_PREFIX = "/v1/image";
    }

    uploadPostImage(data, dispatch = null) {
        return ApiUtils.postMultipart(this.URL_PREFIX + "/post", data, dispatch);
    }

    uploadProfileImage(data, dispatch = null) {
        return ApiUtils.postMultipart(this.URL_PREFIX + "/profile", data, dispatch);
    }

    deleteProfileImage(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/profile", data, dispatch);
    }
}

export default new ImageApi();