import React, {useState} from "react";
import {formatTimestamp} from "../../../util/datetime";
import {CardContent} from "@mui/material";
import useModal from "../../../hooks/useModal";
import {getUserId} from "../../../util/data";
import {useSelector} from "react-redux";
import UserAction from "../../../api/action/UserAction";
import FollowAction from "../../../api/action/FollowAction";
import RESP_CODE from "../../../const/response-code";

const Comment = ({index, postAuthorUserId, postId, item }) => {
    const [subCommentsList, setSubCommentsList] = useState(item.comments_on_comment);
    const {openModal} = useModal();
    const authInfo = useSelector(state => state.authInfo);
    const myId = getUserId();

    const ReportIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/siren.svg`;
    const DeleteIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/delete.svg`;
    const CommentOnCommentIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/reply-arrow.svg`;

    const reportComment = (event) => {
        event.preventDefault();

        const title = '댓글 신고하기';
        const propsToPass = {
            open: true,
            title: title,
            reportedContentsType: 'comment',
            reportingUserId: myId,
            commentId: item.comment_id,
        }
        openModal({type: 'report', props: propsToPass});
    }

    const openDeleteCommentModal = () => {
        const propsToPass = {
            open: true,
            item: item,
            title: '댓글을 삭제하시겠습니까?'
        }
        openModal({type: 'deleteComment', props: propsToPass});
    }

    const btnDelete = () => {
        if(myId === item.user_id) {
            return (
                <img className={"img-icon sm"} src={DeleteIcon} alt={"report"} onClick={e => openDeleteCommentModal(e)}/>
            )
        }
    }

    const btnReport = () => {
        if(myId !== postAuthorUserId) {
            return (
                <img className={"img-icon sm"} src={ReportIcon} alt={"report"} onClick={e => reportComment(e)}/>
            )
        }
    }

    const subCommentChangeHandler = (newSubComment) => {
        if(subCommentsList !== undefined) {
            setSubCommentsList([...subCommentsList, newSubComment]);
        } else {
            setSubCommentsList([newSubComment]);
        }
    }

    const openReplyModal = (event) => {
        event.preventDefault();

        const propsToPass = {
            open: true,
            title: '댓글 남기기',
            postId: postId,
            parentCommentId: item.comment_id,
            subCommentAddHandler: subCommentChangeHandler
        }
        openModal({type: "comment", props: propsToPass});
    }

    const ownerBadge = (commentUserId) => {
        if(postAuthorUserId === commentUserId) {
            return (
                <div className={"owner-badge"} style={{ fontSize: 12 }}>
                    작성자
                </div>
            )
        }
    }

    // 대댓글에는 댓글을 달지 못하게 설정.
    const displayCommentButton = () => {
        if(item.parent_comment_id === null) {
            return (
                <button className={"btn-comment font-sm"} onClick={e => openReplyModal(e)} >댓글 달기</button>
            );
        }
    }

    const loadSubComments = () => {
        if(subCommentsList !== undefined && subCommentsList.length !== undefined) {
            return subCommentsList.map((item, index) => {
                return (
                    <Comment key={item.comment_id} index={index} postAuthorUserId={item.post_author_user_id} item={item} />
                )
            })
        }
    }

    const addReplyArrow = () => {
        if(item.parent_comment_id !== null) {
            return (
                <div className={"horizontal-container"}>
                    <img className={"img-icon sm rotate-180"} src={CommentOnCommentIcon} alt={"comment"} />
                    <div style={{ width: '24px' }}/>
                </div>
            )
        }
    }

    const marginOption = () => {
        if(item.parent_comment_id !== null) {
            return "marginTop"
        }
        return "";
    }

    const openProfile = (userId) => {
        if(!authInfo.loggedIn) {
            const propsToPass = {
                open: true,
                message: '로그인 후에 정보를 보실 수 있습니다.',
                nextAction: 'login'
            };
            const modalName = 'alert';
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        UserAction.detailsWithUserId(userId).then((response) => {
            if(response === null) {
                console.log('회원님께서 로그아웃 되셨습니다. 다시 로그인 해주시겠어요?');
                return;
            }

            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.USER_NOT_FOUND) {
                const propsToPass = {
                    open: true,
                    message: '탈퇴한 회원입니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
                return;
            }

            if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                console.log(`프로필 정보를 열어볼 수 없습니다. [${responseCode}]`);
                return;
            }

            const data = response.data.responseBody;

            // 정상적으로 프로필 모달 열기.
            const name = data.name;
            const email = data.email;
            const role = data.role;
            const statusMessage = data.statusMessage;
            const profileImageUrls = data.profileImageUrls;
            const favouriteTeams = data.favourite_teams;
            let headProfileFromResponse = null;
            if (profileImageUrls.length !== 0) {
                headProfileFromResponse = profileImageUrls[0];
            }

            FollowAction.getFollowInfo(userId).then((response) => {
                if(response !== null) {
                    const isFollowing = response.isFollowing;
                    const numFollowers = response.numFollowers;
                    const numFollowing = response.numFollowing;

                    const propsToPass = {
                        open: true,
                        userId: userId,
                        name: name,
                        email: email,
                        role: role,
                        statusMessage: statusMessage,
                        headProfileFromResponse: headProfileFromResponse,
                        isFollowing: isFollowing,
                        numFollowers: numFollowers,
                        numFollowing: numFollowing,
                        favouriteTeamLogoPaths: favouriteTeams
                    }
                    openModal({type: 'profile', props: propsToPass});
                }
            })
        });
    }

    return (
        <div className={`horizontal-container fullwidth ${marginOption()}`}>
            {addReplyArrow()}
            <CardContent className={"comment-item"} sx={{ padding: 0 }} key={index}>
                <div className={"space-between"}>
                    <div className={"horizontal-container"}>
                        <div className={"fontweight-sm font-lg"} onClick={() => openProfile(item.user_id)}>{item.username}</div>
                        <div>{ownerBadge(item.user_id)}</div>
                    </div>

                    <div className={"horizontal-container vertical-center"}>
                        <div style={{ fontSize: 15 }}>{formatTimestamp(item.created_at)}</div>
                        <div>{btnReport()}</div>
                        <div>{btnDelete()}</div>
                    </div>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"space-between"}>
                    <div style={{ fontSize: 15, whiteSpace: 'pre-line', wordBreak: 'break-all', maxWidth: '90%' }}>{item.contents}</div>
                    {displayCommentButton()}
                </div>
                {loadSubComments()}
            </CardContent>
        </div>
    )
}

export default Comment;