import {isMobile} from "../../util/device";
import {formatTimestamp} from "../../util/datetime";
import React from "react";
import {useNavigate} from "react-router-dom";

import useModal from "../../hooks/useModal";
import {useDispatch, useSelector} from "react-redux";
import UserAction from "../../api/action/UserAction";
import FollowAction from "../../api/action/FollowAction";

const RESP_CODE = require('../../const/response-code');

export default function PostListTable({boardUrlName, posts}) {
    const authInfo = useSelector(state => state.authInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {openModal} = useModal();

    const PictureIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/picture.svg`;

    const displayCommentsCount = (commentsCount) => {
        if(commentsCount !== 0) {
            return `[${commentsCount}]`;
        }
    }

    const openProfile = (data) => {
        const userId = data.user_id;

        if(!authInfo.loggedIn) {
            const propsToPass = {
                open: true,
                message: '로그인 후에 정보를 보실 수 있습니다.',
                navigate: navigate,
                nextAction: 'login'
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        const userDeleted = data.userDeleted;
        if(userDeleted) {
            const propsToPass = {
                open: true,
                message: '탈퇴한 회원입니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        getUserInfo(userId).then((response) => {
            // 정상적으로 프로필 모달 열기.
            const name = response.name;
            const email = response.email;
            const statusMessage = response.statusMessage;
            const profileImageUrls = response.profileImageUrls;
            const favouriteTeams = response.favourite_teams;
            let headProfileFromResponse = null;
            if (profileImageUrls.length !== 0) {
                headProfileFromResponse = profileImageUrls[0];
            }

            getFollowInfo(userId).then((response) => {
                if(response !== null) {
                    const isFollowing = response.isFollowing;
                    const numFollowers = response.numFollowers;
                    const numFollowing = response.numFollowing;

                    const propsToPass = {
                        open: true,
                        userId: userId,
                        name: name,
                        email: email,
                        statusMessage: statusMessage,
                        headProfileFromResponse: headProfileFromResponse,
                        isFollowing: isFollowing,
                        numFollowers: numFollowers,
                        numFollowing: numFollowing,
                        favouriteTeamLogoPaths: favouriteTeams
                    }
                    openModal({type: 'profile', props: propsToPass});
                }
            })
        });
    }

    async function getFollowInfo(userId) {
        return await FollowAction.getFollowInfo(userId, dispatch).then(response => {
            if(response !== null) {
                const responseCode = response.data.responseCode;
                if (responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    return response.data.responseBody;
                }
            }

            return null;
        })
    }

    async function getUserInfo(userId) {
        return await UserAction.detailsWithUserId(userId, dispatch).then(response => {
            const responseCode = response.data.responseCode;
            if (responseCode === RESP_CODE.REQUEST_SUCCESS) {
                return response.data.responseBody;
            }
            return null;
        });
    }

    const showPictureIcon = (contents) => {
        if(contents.match(/<img/)) {
            if(isMobile()) {
                return <img className={"img-icon xs"} src={PictureIcon} alt={"pictures"} />
            }
            return <img className={"img-icon xs"} src={PictureIcon} alt={"pictures"} style={{ marginRight: '6px' }} />
        }
    }

    const rows = posts.map((row) => {
        if (isMobile()) {
            return (
                <a href={`/board/${boardUrlName}/post/${row.post_id}`} className={"post-list-body-row"} key={row.post_id}>
                    <div className={"post-title-and-comments"}>
                        <div className={"mobile-title"}>{setValue(row.title)}</div>
                        <div className={"comments-count"} style={{ marginRight: '6px' }}>{displayCommentsCount(row.commentsCount)}</div>
                        {showPictureIcon(row.contents)}
                    </div>

                    <div className={"mobile-post-info"}>
                        <div>{formatTimestamp(row.created_at)} |  {row.username}  |  조회 {row.views}</div>
                    </div>
                </a>
            );
        }
        return (
            <tr className={"post-list-body-row"} key={row.post_id}>
                <td className={"post-title"}>
                    <a href={`/board/${boardUrlName}/post/${row.post_id}`}
                       className={"post-list-body-row-item horizontal-container"}
                       style={{textDecoration: 'none', color: '#333333'}}>
                        {showPictureIcon(row.contents)}
                        {setValue(row.title)}
                        <div className={"comments-count"}>{displayCommentsCount(row.commentsCount)}</div>
                    </a>
                </td>
                <td className={"post-list-body-row-item post-username"} align={"left"}
                    onClick={() => openProfile(row, navigate)}>{setValue(row.username)}</td>
                <td className={"post-list-body-row-item post-created-at"}
                    align={"right"}>{formatTimestamp(row.created_at)}</td>
                <td className={"post-list-body-row-item post-views"} align={"left"}>{setValue(row.views)}</td>
            </tr>
        )
    });

    function setValue(value) {
        return value;
    }

    const renderTabletOrDesktop = (rows) => {
        return (
            <div className={"post-list-table-page"}>
                <table className={"post-list-table"}>
                    <thead className={"post-list-header"}>
                    <tr className={"post-list-body-row"}>
                        <th className={"post-list-header-item fontweight-mid font-mid"} key={"post_title"}>제목</th>
                        <th className={"post-list-header-item fontweight-mid font-mid"} key={"user"}>글쓴이</th>
                        <th className={"post-list-header-item fontweight-mid font-mid"} key={"created_at"}>작성일자</th>
                        <th className={"post-list-header-item fontweight-mid font-mid"} key={"views"}>조회수</th>
                    </tr>
                    </thead>
                    <tbody className={"post-list-body"}>
                    { rows }
                    </tbody>
                </table>
            </div>
        )
    }

    const renderMobile = (rows) => {
        return (
            <div className={"mobile-post-list"}>
                { rows }
            </div>
        )
    }

    const renderByDeviceType = (rows) => {
        if(isMobile()) {
            return renderMobile(rows);
        }
        return renderTabletOrDesktop(rows);
    }

    return (
        renderByDeviceType(rows)
    )
};