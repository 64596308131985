import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { createStore } from "redux";
import rootReducer from "./redux/reducer";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import ModalProvider from "./context/ModalProvider";
import {Provider} from "react-redux";
import ModalContainer from "./ui/component/modal/ModalContainer";

import ReactGA from 'react-ga4';
import SnackbarProvider from "./context/SnackbarProvider";
import SnackbarContainer from './ui/component/snackbar/SnackbarContainer';

if(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

const store = createStore(rootReducer);
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <SnackbarProvider store={store}>
            <ModalProvider store={store}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App/>
                        <ModalContainer/>
                        <SnackbarContainer/>
                    </PersistGate>
                </BrowserRouter>
            </ModalProvider>
        </SnackbarProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();