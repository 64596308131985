import React from "react";
import PrimaryHeader from "../component/PrimaryHeader";

const MainLayoutWithoutSecondaryHeader = ({children}) => {
    return (
        <div className={"page"}>
            <PrimaryHeader/>
            {children}
        </div>
    )
};

export default MainLayoutWithoutSecondaryHeader;