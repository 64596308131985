import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import ReactionAction from "../../api/action/ReactionAction";
import {getUserId} from "../../util/data";
import PostAction from "../../api/action/PostAction";
import Comment from "../component/comment/Comment";
import {useDispatch, useSelector} from "react-redux";
import {shareKakao} from "../../util/kakao";
import useModal from "../../hooks/useModal";
import {isMobile, isTablet} from "../../util/device";
import MainLayout from './MainLayout';
import {getTimeDifference} from '../../util/datetime';
import {CustomPaginationRounded} from '../component/CustomPaginationRounded';
import {Chip} from "@mui/material";
import FollowAction from "../../api/action/FollowAction";
import UserAction from "../../api/action/UserAction";
import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";

const { sanitize } = require('dompurify');
const RESP_CODE = require('../../const/response-code');

const PostView = ({titleName}) => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [authorId, setAuthorId] = useState('');
    const [isAuthorDeleted, setIsAuthorDeleted] = useState(false);
    const [contents, setContents] = useState('');
    const navigate = useNavigate();
    const params = useParams();
    const postId = params.postId;
    const boardUrlName = params.boardUrlName;
    const [reactionCounts, setReactionCounts] = useState();
    const authInfo = useSelector(state => state.authInfo);
    const [hashTags, setHashTags] = useState([]);
    const [commentList, setCommentList] = useState([]);
    const [numComments, setNumComments] = useState(0);
    const [currentCommentPage, setCurrentCommentPage] = useState(1);
    const commentLimit = 20;
    const [commentPageCount, setCommentPageCount] = useState(0);
    const [firstImageUrl, setFirstImageUrl] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const userId = getUserId();
    const {openModal} = useModal();
    const [boardName, setBoardName] = useState('');
    const [postCreatedAtDateFormat, setPostCreatedAtDateFormat] = useState('');
    const [postCreated, setPostCreated] = useState('');
    const [viewCount, setViewCount] = useState(0);
    const [boardUrl, setBoardUrl] = useState(null);
    const [parentBoardName, setParentBoardName] = useState(null);
    const [parentBoardUrl, setParentBoardUrl] = useState(null);
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    const EditIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/edit.svg`;
    const DeleteIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/delete.svg`;
    const LikeIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/like.svg`;
    const DislikeIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/dislike.svg`
    const ReportIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/siren.svg`;
    const ShareIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/share.svg`;
    const CommentIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/comment.svg`;
    const PictureIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/picture.svg`;

    const displayCurrentBoard = (boardUrlName, boardName) => {
        return (
            <div className="horizontal-container pd-mid vertical-center">
                <a className={"link font-lg"} href={`/`}>홈</a>
                {insertLinkParentBoard()}
                <div className={"horizontal-gap-sm"}/>
                <div className={"font-lg"}> / </div>
                <div className={"horizontal-gap-sm"}/>
                <a className={"link font-xl"} href={`/board/${boardUrlName}`}>{boardName}</a>
            </div>
        )
    }

    const insertLinkParentBoard = () => {
        if(parentBoardName !== null && parentBoardUrl !== null) {
            return (
                <div className={"horizontal-container"}>
                    <div className={"horizontal-gap-sm"}/>
                    <div className={"font-lg"}> / </div>
                    <div className={"horizontal-gap-sm"}/>
                    <a className={"link font-lg"} href={`/board/${parentBoardUrl}`}>{parentBoardName}</a>
                </div>
                
            )
        }
    }

    async function getPost(postId) {
        return await PostAction.get(postId);
    }

    async function getReactionCounts(postId) {
        const response = await ReactionAction.getCounts(postId);
        return response.data.responseBody.countsByType;
    }

    async function deletePost(event) {
        event.preventDefault();
        const title = '이 게시물을 삭제하시겠습니까?';
        const propsToPass = {
            open: true,
            title: title,
            postId: postId,
            boardUrlName: boardUrlName
        }
        openModal({type: 'deletePost', props: propsToPass});
    }

    const getCommentList = async () => {
        return await PostAction.getComments(postId, currentCommentPage);
    }

    useEffect(() => {
        getPost(postId).then((result) => {
            const postData = result.data.responseBody;
            const createdAt = postData.createdAt;

            setPostCreatedAtDateFormat(createdAt);
            setPostCreated(getTimeDifference(createdAt));
            setAuthor(postData.username);
            setAuthorId(postData.user_id);
            setIsAuthorDeleted(postData.userDeleted);
            setTitle(postData.title);
            setContents(postData.contents);
            console.log(postData.contents);
            // setHashTags(postData.post_hash_tags);
            setBoardName(postData.board_name);
            setBoardUrl(postData.board_url);
            setParentBoardName(postData.parent_board_name);
            setParentBoardUrl(postData.parent_board_url);
            setViewCount(postData.viewCount);

            handleTitle(`${postData.title} - 야구포럼 (YagooForum)`);

            const imageUrls = postData.image_urls;
            if(imageUrls.length !== 0) {
                setFirstImageUrl(imageUrls[0]);
                setImageUrls(imageUrls);
            }

            showHashTags(postData.post_hash_tags);
        });

        getReactionCounts(postId).then(result => {
            setReactionCounts(result);
        });

        getCommentList().then(response => {
            const result = response.data.responseBody;
            setNumComments(result.total_count);
            setCommentList(result.comments);
            setCommentPageCount(Math.ceil(result.total_count / commentLimit));
        });

        setLoaded(true);
    }, [boardUrlName, postId, currentCommentPage]);

    const handleTitle = (title) => {
        titleName(title);
    }

    const kakaoButton = () => {
        return (
            <img className={"img-icon mid horizontal-margin-xs"} src={ShareIcon} alt={"share"} onClick={event => shareKakao(event, firstImageUrl, window.location.href, title)}/>
        )
    }

    const editButton = () => {
        if(userId === authorId) {
            return (
                <img className={"img-icon mid horizontal-margin-xs"} src={EditIcon} alt={"edit"} onClick={e => gotoEditPost(e)}/>
            )
        }
    }

    const deleteButton = () => {
        if(userId === authorId) {
            return (
                <img className={"img-icon mid horizontal-margin-xs"} src={DeleteIcon} alt={"delete"} onClick={e => deletePost(e)}/>
            )
        }
    }

    const commentButton = () => {
        const meta = new Map();
        meta.set('contents', '');
        return (
            <img className={"img-icon mid horizontal-margin-xs"} src={CommentIcon} alt={"delete"} onClick={e => addCommentModal(e)}/>
        )
    }

    const reportPost = (e) => {
        e.preventDefault();
        const title = '게시글 신고하기';
        const propsToPass = {
            open: true,
            title: title,
            reportedContentsType: 'post',
            reportingUserId: userId,
            postId: postId,
        }
        openModal({type: 'report', props: propsToPass});
    }

    const reportButton = () => {
        if(userId !== authorId) {
            return (
                <img className={"img-icon mid horizontal-margin-xs"} src={ReportIcon} alt={"report"} onClick={e => reportPost(e)}/>
            )
        }
    }

    function gotoEditPost(e) {
        e.preventDefault();
        navigate(`/board/${params.boardUrlName}/post/${params.postId}/edit`, {replace: true, title: title, contents: contents});
    }

    const pagination = () => {
        if(commentPageCount > 1) {
            return (
                <CustomPaginationRounded numPage={commentPageCount} change={handleCurrentCommentPageChange}/>
            )
        }
    }

    const handleCurrentCommentPageChange = (event, value) => {
        setCurrentCommentPage(value);
    };

    const react = async (event, reactionType) => {
        event.preventDefault();

        if(authInfo.loggedIn) {
            await ReactionAction.react({
                post_id: params.postId,
                type: reactionType
            }, dispatch).then(resp => {
                if(resp !== null) {
                    const respCode = resp.data.responseCode;
                    if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                        const reactionCounts = resp.data.responseBody.countsByType;
                        setReactionCounts(reactionCounts);
                    }
                } else {

                }

            }).catch(error => {
                console.log(`좋아요/싫어요를 누르는 도중에 에러가 발생했습니다. ${error}`);
            });
        } else {
            alert('로그인 하신 후에 좋아요/싫어요를 누를 수 있습니다.');
        }
    }

    // 리액트 컴포넌트는 async로 불러올 수 없다.
    const reactionCountsByType = (type) => {
        if(reactionCounts !== null && reactionCounts !== undefined) {
            return reactionCounts[type];
        }
    }

    const commentChangeHandler = (newComment) => {
        setCommentList([newComment, ...commentList]);
    }

    async function addCommentModal(event) {
        event.preventDefault();
        if(authInfo.loggedIn) {
            const propsToPass = {
                open: true,
                title: '댓글 남기기',
                postId: postId,
                commentAddHandler: commentChangeHandler
            }
            openModal({ type: "comment", props: propsToPass });
        } else {
            alert('로그인 하신 후에 댓글을 달 수 있습니다.');
            navigate('/login');
        }
    }

    const handleHashtagClick = (hashTag) => {
        navigate(`/search?type=hashtag&keyword=${hashTag}`);
    }

    const showHashTags = (hashTags) => {
        hashTags.forEach(function (hashTag) {
            const newHashtag = <Chip
                key={hashTag.id}
                style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    backgroundColor: '#f7f7f7'
                }}
                onClick={() => handleHashtagClick(hashTag)}
                label={'#' + hashTag}
            />;

            setHashTags((items) => [...items, newHashtag]);
        });
    }

    const showPictureIcon = () => {
        if(imageUrls.length > 0) {
            if(isMobile()) {
                return <img className={"img-icon xs horizontal-item-mid"} src={PictureIcon} alt={"pictures"} />
            }
            return <img className={"img-icon sm"} src={PictureIcon} alt={"pictures"} style={{ marginLeft: '8px' }} />
        }
    }

    const showNumComments = () => {
        if(numComments > 0) {
            return (
                <div className={"firebrick horizontal-item-end"}>{numComments}</div>
            )
        }
    }

    const openProfile = () => {
        if(!authInfo.loggedIn) {
            const propsToPass = {
                open: true,
                message: '로그인 후에 정보를 보실 수 있습니다.',
                navigate: null,
                nextAction: 'login'
            };
            const modalName = 'alert';
            openModal({type: 'alert', props: propsToPass});
            return;
        }
        if(isAuthorDeleted) {
            const propsToPass = {
                open: true,
                message: '탈퇴한 회원입니다.',
                navigate: null
            };
            const modalName = 'alert';
            openModal({type: 'alert', props: propsToPass});
        }

        UserAction.detailsWithUserId(authorId, dispatch).then((response) => {
            const responseCode = response.data.responseCode;

            if(responseCode === RESP_CODE.JWT_REFRESH_TOKEN_EXPIRED ||
                responseCode === RESP_CODE.JWT_INVALID_REFRESH_TOKEN ||
                responseCode === RESP_CODE.JWT_INVALID_ACCESS_TOKEN ||
                responseCode === RESP_CODE.JWT_ACCESS_TOKEN_NOT_INCLUDED) {
                const propsToPass = {
                    open: true,
                    message: '회원님께서 로그아웃 되셨습니다. 다시 로그인 해주시겠어요?',
                    navigate: navigate,
                    nextAction: 'login'
                };
                const modalName = 'alert';
                openModal({type: 'alert', props: propsToPass});
            }

            if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                console.log(`Failed to load the author details. ${responseCode}`);
                return;
            }

            const responseBody = response.data.responseBody;
            // 정상적으로 프로필 모달 열기.
            const name = responseBody.name;
            const email = responseBody.email;
            const statusMessage = responseBody.statusMessage;
            const profileImageUrls = responseBody.profileImageUrls;
            const favouriteTeams = responseBody.favourite_teams;
            const role = responseBody.role;
            let headProfileFromResponse = null;
            if (profileImageUrls.length !== 0) {
                headProfileFromResponse = profileImageUrls[0];
            }

            FollowAction.getFollowInfo(authorId).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode !== RESP_CODE.REQUEST_SUCCESS) {
                    console.log(`Failed to get the follow info of the author. ${responseCode}`);
                    return;
                }

                const responseBody = response.data.responseBody;
                if(responseBody !== null) {
                    const isFollowing = responseBody.isFollowing;
                    const numFollowers = responseBody.numFollowers;
                    const numFollowing = responseBody.numFollowing;

                    const propsToPass = {
                        open: true,
                        userId: authorId,
                        name: name,
                        email: email,
                        statusMessage: statusMessage,
                        headProfileFromResponse: headProfileFromResponse,
                        isFollowing: isFollowing,
                        numFollowers: numFollowers,
                        numFollowing: numFollowing,
                        favouriteTeamLogoPaths: favouriteTeams,
                        role: role
                    }
                    openModal({type: 'profile', props: propsToPass});
                }
            })
        });
    }

    const getPostMetadata = () => {
        return (
            <div className={"horizontal-container"}>
                <div className={"font-sm font-dark-grey"}>작성: {postCreated}</div>

                <div className={"horizontal-gap-sm"}/>

                <div className={"font-sm font-dark-grey clickable"} onClick={() => openProfile()}>작성자: {author}</div>

                <div className={"horizontal-gap-sm"}/>

                <div className={"font-sm font-dark-grey"}>조회 수 {viewCount}</div>
            </div>
        );
    }

    const renderDesktop = () => {
        return (
            <div className={"main-contents post"}>
                <div className={"vertical-gap-sm"}/>

                <div className="top">
                    {displayCurrentBoard(boardUrlName, boardName)}
                </div>

                <div className={"vertical-gap-sm"}/>

                <form className={"post-form"}>
                    <div className={"post-title-wrapper horizontal-container vertical-center"}>
                        <div className={"title vertical-margin"}>{title}</div>
                        {showPictureIcon()}
                        {showNumComments()}
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    {getPostMetadata()}

                    <div className={"vertical-gap-sm"}/>

                    <div className={"editor"} dangerouslySetInnerHTML={{__html: contents}}/>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"option-box"}>
                        {editButton()}
                        {deleteButton()}
                        {reportButton()}
                        {kakaoButton()}
                        {commentButton()}
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"reaction-buttons"}>

                        <div className={"reaction-group"} onClick={e => react(e, 'LIKE')}>
                            <div className={"reaction-counts reaction-text col-item"}>
                                {reactionCountsByType('like')}
                            </div>

                            <div className={"reaction-text col-item"}>좋아요</div>

                            <img className={"img-icon mid col-item"} src={LikeIcon} alt={"like"}/>
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <div className={"reaction-group"} onClick={e => react(e, 'DISLIKE')}>
                            <img className={"img-icon mid reversed col-item"} src={DislikeIcon} alt={"dislike"}/>

                            <div className={"reaction-text col-item"}>싫어요</div>

                            <div className={"reaction-counts reaction-text col-item"}>
                                {reactionCountsByType('dislike')}
                            </div>
                        </div>
                    </div>

                    <div className={"chip-array"}>
                        {hashTags}
                    </div>
                </form>

                <div className={"vertical-gap-sm"}/>

                {pagination()}

                <div className={"vertical-gap-sm"}/>

                <div className={"comment-box"}>
                    {commentList.map((item, index) => {
                        return (
                            <Comment key={item.comment_id} index={index} postAuthorUserId={authorId} postId={postId}
                                        item={item}/>
                        );
                    })}
                </div>
            </div>
        );
    }

    const renderMobileTablet = () => {
        return (
            <div className={"vertical-container post"}>
                <div className="top">
                    {displayCurrentBoard(boardUrlName, boardName)}
                </div>

                <div className={"vertical-gap-sm"}/>

                <form className={"post-form"}>
                    <div className={"post-title-wrapper horizontal-container vertical-center"}>
                        <div className={"title horizontal-item-first"}>{title}</div>
                        {showPictureIcon()}
                        {showNumComments()}
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    {getPostMetadata()}

                    <div className={"editor"} dangerouslySetInnerHTML={{__html: contents}}/>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"option-box"}>
                        {editButton()}
                        {deleteButton()}
                        {reportButton()}
                        {kakaoButton()}
                        {commentButton()}
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"reaction-buttons"}>

                        <div className={"reaction-group"} onClick={e => react(e, 'LIKE')}>
                            <div className={"reaction-counts reaction-text col-item"}>
                                {reactionCountsByType('like')}
                            </div>

                            <div className={"reaction-text col-item"}>좋아요</div>

                            <img className={"img-icon mid col-item"} src={LikeIcon} alt={"like"}/>
                        </div>

                        <div className={"reaction-group"} onClick={e => react(e, 'DISLIKE')}>
                            <img className={"img-icon mid reversed col-item"} src={DislikeIcon} alt={"dislike"}/>

                            <div className={"reaction-text col-item"}>싫어요</div>

                            <div className={"reaction-counts reaction-text col-item"}>
                                {reactionCountsByType('dislike')}
                            </div>
                        </div>
                    </div>
                </form>

                <div className={"vertical-gap-sm"}/>
                
                <div className={"comment-box"}>
                    {commentList.map((item, index) => {
                        return (
                            <Comment key={item.comment_id} index={index} postAuthorUserId={authorId} postId={postId}
                                     item={item}/>
                        );
                    })}
                </div>

                <div className={"vertical-gap-sm"}/>

                {pagination()}
            </div>
        );
    }

    const renderByDeviceType = () => {
        if(isMobile() || isTablet()) {
            return renderMobileTablet();
        }

        return renderDesktop();
    }

    if(loaded) {
        return (
            <MainLayout children={renderByDeviceType()}/>
        );
    }
}

export default PostView;