import {isMobile} from "../../util/device";
import React, {useEffect} from "react";
import {formatTimestamp} from "../../util/datetime";
import {useNavigate} from "react-router-dom";

export default function HostPostListTable({currentTab, posts}) {
    const navigate = useNavigate();

    useEffect(() => {

    }, [currentTab]);

    const displayCommentsCount = (commentsCount) => {
        if(commentsCount !== 0) {
            return `[${commentsCount}]`;
        }
    }

    function goToPost(boardUrlName, postId) {
        navigate(`/board/${boardUrlName}/post/${postId}`);
    }

    const rows = posts.map((row, index) => {
        if (isMobile()) {
            return (
                <a href={`/board/${row.board_url_name}/post/${row.post_id}`} className={"post-list-body-row"} key={row.post_id}>
                    <div className={"post-title-and-comments"}>
                        <div className={"mobile-title"}>{setValue(row.title)}</div>
                        <div className={"comments-count"}>{displayCommentsCount(row.comments_count)}</div>
                    </div>

                    <div className={"mobile-post-info"}>
                        <div>{formatTimestamp(row.created_at)}  |  {row.board_name}  |  {row.author} </div>
                    </div>
                </a>
            );
        }
        return (
            <tr className={"post-list-body-row"} key={row.post_id} onClick={() => goToPost(row.board_url_name, row.post_id)}>
                <td className={"post-list-body-row-item"} align={"left"}>{index + 1}</td>
                <td className={"post-list-body-row-item"} align={"left"}>{setValue(row.board_name)}</td>
                <td className={"post-list-body-row-item post-title"} align={"left"}>
                    {setValue(row.title)}
                </td>
            </tr>
        )
    });

    function setValue(value) {
        return value;
    }

    const renderTabletOrDesktop = (rows) => {
        return (
            <div className={"post-list-table-page"}>
                <table className={"post-list-table"}>
                    <tbody className={"post-list-body"}>
                    {rows}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderMobile = (rows) => {
        return (
            <div className={"mobile-post-list"}>
                {rows}
            </div>
        )
    }

    const renderByDeviceType = (rows) => {
        if(isMobile()) {
            return renderMobile(rows);
        }
        return renderTabletOrDesktop(rows);
    }

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {renderByDeviceType(rows)}
        </div>
    )
};