import React, {useEffect, useState} from "react";
import {Chip} from "@mui/material";

export const HashTagStack = ({hashtagStrArr, setHashtagStrArr}) => {
    const [hashtagChipArr, setHashtagChipArr] = useState([]);

    useEffect(() => {
        setHashtagChipArr([]);
        hashtagStrArr.forEach(function (hashTag) {
            const hashTagChip = <Chip
                key={hashTag}
                style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    backgroundColor: '#f7f7f7'
                }}
                label={hashTag}
                onDelete={() => handleHashtagDelete(hashTag)}
            />;

            setHashtagChipArr((hashtagArr) => [...hashtagArr, hashTagChip]);
        });
    }, [hashtagStrArr]);

    const handleHashtagDelete = (hashTagStr) => {
        setHashtagStrArr(hashtagStrArr.filter((item) => item !== hashTagStr));
    }

    return (
        <div className={"chip-array"}>
            {hashtagChipArr}
        </div>
    )
}