export const authInfo = (state = {}, action) => {
    switch(action.type) {
        case 'LOGIN':
            return Object.assign({}, state, { ...state, details: action.user, loggedIn: true });
        case 'LOGOUT':
            return Object.assign({}, state, {...state, details: null, loggedIn: false });
        case 'UPDATE_FCM_TOKEN':
            state.details.fcmToken = action.token;
            return state;

        default:
            return state;
    }
};