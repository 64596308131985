import {useEffect} from "react";
import SearchResultPostPreview from "./SearchResultPostPreview";

const SearchResultList = ({keyword, searchResult}) => {
    useEffect(() => {

    }, []);

    return (
        <div className={"search-result-list"}>
            {
                searchResult.map((item) => (
                    <SearchResultPostPreview
                        keyword={keyword} title={item.title} postId={item.post_id} postBoard={item.post_board} boardId={item.board_id} contents={item.contents} firstImageUrl={item.first_image_url}/>
                ))
            }
        </div>
    )
}

export default SearchResultList;