import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import useModal from "../../../hooks/useModal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


const CancelEditPostModal = (props) => {
    const {closeModal} = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function cancelEditPost(event) {
        event.preventDefault();
        navigate(`/board/${props.boardName}`, {replace: true});
        closeModal();
    }

    const handleClose = (e) => {
        e.preventDefault();
        closeModal();
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.contents}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>아니오</Button>
                <Button onClick={e => cancelEditPost(e)}>네</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelEditPostModal;