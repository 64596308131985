import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import PostAction from "../../api/action/PostAction";
import qs from 'qs';
import SearchResultList from "../component/SearchResultList";
import MainLayout from "./MainLayout";

const SearchResultPage = ({titleName}) => {
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [searchResult, setSearchResult] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const keyword = query.keyword;
    const type = query.type;
    const typeInKorean = function(type) {
        if(type === 'post') {
            return '게시물';
        } else if(type === 'hashtag') {
            return '해시태그';
        }
        return '';
    }

    async function getSearchResult(type, keyword, page) {
        const response = await PostAction.searchByKeyword(type, keyword, page);
        return response.data.responseBody;
    }

    async function loadMore(event) {
        const nextPage = page + 1
        setPage(nextPage);

        const moreItemsResponse = await getSearchResult(type, keyword, nextPage);
        const moreItems = moreItemsResponse.result;
        const moreItemsLength = moreItems.length;
        if(moreItemsLength !== 0) {
            setSearchResult(current => [...current, ...moreItems]);
        } else {
            alert('더 이상의 검색 결과가 없습니다.');
        }
    }

    useEffect(() => {
        titleName('검색 결과');

        getSearchResult(type, keyword, page).then((response) => {
            const resultFromRequest = response.result;
            setSearchResult(resultFromRequest)
            setLoaded(true);
        });
    }, [keyword]);

    const showSearchResult = () => {
        if(loaded) {
            return (
                <SearchResultList keyword={keyword} searchResult={searchResult}/>
            );
        }
    }

    return (
        <MainLayout>
            <div className={"main-contents"}>
                <div className={"result-category-title"}>{typeInKorean(type)} 검색 결과</div>
                {showSearchResult()}
                <div className={"load-more"} onClick={loadMore}>===== 더보기 =====</div>
            </div>
        </MainLayout>
        
    )
}

export default SearchResultPage;