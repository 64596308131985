import useModal from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import {isMobile, isTablet} from "../../util/device";
import {TeamSelector} from "../component/TeamSelector";
import React, {useEffect, useRef, useState} from "react";
import {mapRole} from "../../const/role-names";

const EditProfilePage = ({
        name, setName, email, statusMessage, setStatusMessage, allBoards, setAllBoards, favouriteBoards, currentRole,
        authInfo, profileImages, setProfileImages, authProviderName, leagues, favouriteTeamIds, setFavouriteTeamIds,
        headProfileImage
    }) => {
    const {openModal} = useModal();
    const [modalAction, setModalAction] = useState('open');
    const navigate = useNavigate();

    const openChangeStatusMessageModal = (event) => {
        event.preventDefault();
        const propsToPass = {
            open: true,
            title: '상태 메시지 변경',
            current: statusMessage,
            field: 'statusMessage',
            statusMessageChangeHandler: statusMessageChangeHandler
        }
        openModal({type: 'statusMessage', props: propsToPass});
    }

    const profileImageUrls = () => {
        return profileImages;
    }

    function openProfileImagesModal() {
        const propsToPass = {
            open: true,
            profileImageUrls: profileImageUrls()
        }
        openModal({type: "profileImage", props: propsToPass});
    }

    const openChangeNameModal = (event) => {
        event.preventDefault();

        const propsToPass = {
            open: true,
            title: '이름 변경',
            current: name,
            field: "name",
            nameChangeHandler: nameChangeHandler,
            modalAction: modalAction,
            setModalAction: setModalAction
        }
        openModal({type: 'name', props: propsToPass});
    }

    const statusMessageChangeHandler = (newStatusMessage) => {
        setStatusMessage(newStatusMessage);
    }

    const nameChangeHandler = (newName) => {
        setName(newName);
    }

    const renderDesktop = () => {
        return (
            <div className={"vertical-center"} style={{width: '90%'}}>

                <div className={"vertical-gap-sm"}/>

                <div className={"font-xl bold"}>기본 프로필</div>

                <div className={"vertical-gap-mid"}/>

                <div className={"profile-image-wrapper"}>
                    <img className={"profile-image"} alt={"default user"} src={headProfileImage}
                         onClick={() => openProfileImagesModal()}/>
                    <div className={"username"}>{name}</div>
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"horizontal-container vertical-center"}>
                    <div className={"font-lg"} style={{width: '15%'}}>이름</div>

                    <div className={"horizontal-gap-sm"}/>

                    <input
                        className={"input light-grey font-mid"}
                        style={{width: '80%'}}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        readOnly={true}
                        onClick={e => openChangeNameModal(e)}
                    />
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container vertical-center"}>
                    <div className={"font-lg"} style={{width: '15%'}}>이메일 (변경할 수 없습니다)</div>

                    <div className={"horizontal-gap-sm"}/>

                    <input className={"input light-grey font-mid"} style={{width: '80%'}} defaultValue={email}
                           readOnly={true}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container vertical-center"}>
                    <div className={"font-lg"} style={{width: '15%'}}>가입 방식 (변경할 수 없습니다)</div>

                    <div className={"horizontal-gap-sm"}/>

                    <input className={"input light-grey font-mid"} style={{width: '80%'}}
                           defaultValue={authProviderName}
                           readOnly={true}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container vertical-center"}>
                    <div className={"font-lg"} style={{width: '15%'}}>회원 등급 (변경할 수 없습니다)</div>

                    <div className={"horizontal-gap-sm"}/>

                    <input className={"input light-grey font-mid"} style={{width: '80%'}}
                           defaultValue={mapRole[currentRole]} readOnly={true}/>
                </div>

                <div className={"vertical-gap-sm"}/>

                <div className={"horizontal-container vertical-center"}>
                    <div className={"font-lg"} style={{width: '15%'}}>상태 메시지</div>

                    <div className={"horizontal-gap-sm"}/>

                    <input
                        className={"input light-grey font-mid"}
                        style={{width: '80%'}}
                        value={statusMessage}
                        onChange={e => setStatusMessage(e.target.value)}
                        readOnly={true}
                        onClick={e => openChangeStatusMessageModal(e)}
                    />
                </div>

                <div className={"vertical-gap-mid"}/>

                <div className={"font-lg bold"}>좋아하는 팀</div>

                <div className={"vertical-gap-sm"}/>

                <TeamSelector leagues={leagues} favouriteTeamIds={favouriteTeamIds}
                              setFavouriteTeamIds={setFavouriteTeamIds} isAtSignupPage={false}/>
            </div>
        )
    }

    const renderMobileTablet = () => {
        return (
            <div className={"page"}>
                <div className={"form"}>
                    <div className={"vertical-gap-mid"}/>

                    <div>
                        <div className={"font-xl bold"}>기본 프로필</div>
                    </div>

                    <div className={"vertical-gap-mid"}/>

                    <div>
                        <img className={"profile-image"} alt={"default user"} src={headProfileImage}
                             onClick={() => openProfileImagesModal()}/>

                        <div className={"vertical-gap-sm"}/>
                        <div className={"username"}>{name}</div>
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"vertical-container"}>
                        <div className={"font-lg"}>이름</div>

                        <div className={"vertical-gap-sm"}/>

                        <input
                            className={"input light-grey font-mid"}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            readOnly={true}
                            onClick={e => openChangeNameModal(e)}
                        />
                    </div>

                    <div className={"vertical-gap-mid"}/>

                    <div className={"vertical-container"}>
                        <div className={"font-lg"}>이메일 (변경할 수 없습니다)</div>

                        <div className={"vertical-gap-sm"}/>

                        <input className={"input light-grey font-mid"} defaultValue={email} readOnly={true}/>
                    </div>

                    <div className={"vertical-gap-mid"}/>

                    <div className={"vertical-container"}>
                        <div className={"font-lg"}>가입 방식 (변경할 수 없습니다)</div>

                        <div className={"vertical-gap-sm"}/>

                        <input className={"input light-grey font-mid"} defaultValue={authProviderName} readOnly={true}/>
                    </div>

                    <div className={"vertical-gap-mid"}/>

                    <div className={"vertical-container"}>
                        <div className={"font-lg"}>회원 등급 (변경할 수 없습니다)</div>

                        <div className={"vertical-gap-sm"}/>

                        <input className={"input light-grey font-mid"} defaultValue={mapRole[currentRole]}
                               readOnly={true}/>
                    </div>

                    <div className={"vertical-container"}>
                        <div className={"font-lg"}>상태 메시지</div>

                        <div className={"vertical-gap-sm"}/>

                        <input
                            className={"input light-grey font-mid"}
                            value={statusMessage}
                            onChange={e => setStatusMessage(e.target.value)}
                            readOnly={true}
                            onClick={e => openChangeStatusMessageModal(e)}
                        />
                    </div>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"font-lg bold"}>좋아하는 팀</div>

                    <div className={"vertical-gap-sm"}/>

                    <TeamSelector leagues={leagues} favouriteTeamIds={favouriteTeamIds}
                                  setFavouriteTeamIds={setFavouriteTeamIds} isAtSignupPage={false}/>
                </div>
            </div>
        )
    }

    const renderByDeviceType = () => {
        if (authInfo.loggedIn) {
            if (isMobile() || isTablet()) {
                return renderMobileTablet();
            }

            return renderDesktop();
        } else {
            navigate('/', {replace: true});
        }
    }

    return (
        renderByDeviceType()
    )
};

export default EditProfilePage;
