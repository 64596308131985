import React, {useEffect, useState} from "react";

const Board = ({index, props}) => {
    function loadChildrenBoards(childrenBoards) {
        return childrenBoards.map((item, index) => {
            return (
                <div key={item.board_id} className={"subitem-board"}>
                    <a href={`/board/${item.url_name}`} className={"subitem-board-title"}>{item.name}</a>
                    {loadChildrenBoards(item.children_boards)}
                </div>
            );
        })
    }

    function loadBoards() {
        if(props.name === '전체 게시판') {
            return;
        }

        return (
            <div className={"board-item"} key={props.board_id}>
                <a href={`/board/${props.url_name}`} className={"board-item-header"}>
                    <div className="board-title"><b>{props.name}</b></div>
                    <div style={{ marginTop: '12px' }}/>
                </a>
                {loadChildrenBoards(props.children_boards)}
            </div>
        )
    }

    return loadBoards();
}

export default Board;