import {useEffect} from "react";

const SearchResultPostPreview = ({key, keyword, title, postId, postBoard, boardId, contents, firstImageUrl}) => {
    useEffect(() => {

    }, []);

    const highlightText = (title, keyword) => {
        if (keyword !== '' && title.includes(keyword)) {
            const parts = title.split(new RegExp(`(${keyword})`, 'gi'));

            return (
                <>
                    {parts.map((part, index) =>
                        part.toLowerCase() === keyword.toLowerCase() ? (
                            <mark className={"matching-keyword"} key={index}>{part}</mark>
                        ) : (
                            part
                        ),
                    )}
                </>
            );
        }
        return title;
    }

    const displayThumbnail = (firstImageUrl) => {
        if(firstImageUrl !== null) {
            return (
                <img className={"thumbnail-img"} src={firstImageUrl} alt={"Thumbnail"}/>
            );
        }
    }

    return (
        <div className={"search-result-item"}>
            <div className={"search-result-board-and-title"}>
                <a href={`/board/${boardId}/`} className={"search-result-board"}>{postBoard}</a>
                <a href={`/board/${boardId}/post/${postId}`} className={"search-result-title"}>{highlightText(title, keyword)}</a>
            </div>

            <div className={"search-result-contents"}>{highlightText(contents, keyword)}</div>

            {displayThumbnail(firstImageUrl)}
        </div>
    )
}

export default SearchResultPostPreview;