import ApiUtils from "./APIUtils";

class Report {
    constructor() {
        this.URL_PREFIX = "/v1/report";
    }

    reportComment(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + '/comment', data, dispatch);
    }

    reportPost(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + '/post', data, dispatch);
    }
}

export default new Report();