import User from "../User";

export default {
    details: (dispatch = null) => {
        return User.details(dispatch).then((resp) => {
            return resp;
        });
    },

    detailsWithUserId: (userId, dispatch = null) => {
        return User.detailsWithUserId(userId, dispatch).then((resp) => {
            return resp;
        });
    },

    edit: (data, dispatch = null) => {
        return User.edit(data, dispatch).then((resp) => {
            return resp;
        });
    },

    delete: (dispatch = null) => {
        return User.delete(dispatch).then((resp) => {
            return resp;
        });
    }
};