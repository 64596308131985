import ApiUtils from "./APIUtils";

class BaseballTeamApi {
    constructor() {
        this.URL_PREFIX = "/v1/baseball/team";
    }

    getAll() {
        return ApiUtils.get(`${this.URL_PREFIX}/all`);
    }

    getAllFavouriteTeams() {
        return ApiUtils.get(`${this.URL_PREFIX}/favourites`);
    }

    edit(data) {
        return ApiUtils.put(`${this.URL_PREFIX}/`, data);
    }
}

export default new BaseballTeamApi();