import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import ImageAction from "../../../api/action/ImageAction";
import useModal from "../../../hooks/useModal";

const RESP_CODE = require('../../../const/response-code');

const ProfileImageModal = (props) => {
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentImage, setCurrentImage] = useState('');
    const [profileImages, setProfileImages] = useState([]);
    const {closeModal} = useModal();

    const DefaultUser = `${process.env.REACT_APP_PUBLIC_URL}/images/default_user.svg`;
    const AddImageIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/add_image.svg`;
    const DirectionArrowIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/direction-arrow.svg`;
    const DeleteIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/delete.svg`;

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    useEffect(() => {
        const images = props.profileImageUrls;
        if(images !== undefined && images !== null) {
            setProfileImages(images);
            if(images.length > 0) {
                setCurrentIndex(0);
            }
        }
    }, []);

    const onLoadFile = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('img', file);

        const result = await ImageAction.uploadProfileImage(formData, dispatch);
        if(result !== null) {
            const respCode = result.data.responseCode;
            if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                const newImageUrl = result.data.responseBody;

                setProfileImages([...profileImages, newImageUrl]);
                handleCurrentIndex(profileImages.length);
                handleCurrentImage(profileImages[currentIndex]);
            } else {
                alert(`프로필 이미지 업로드 중 에러가 발생했습니다. [${respCode}]`);
            }
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        setCurrentIndex(0);
        closeModal();
    }

    const getCurrentImage = () => {
        if(currentIndex === -1) {
            return DefaultUser
        }
        return profileImages[currentIndex];
    }

    function removeImageFromProfileImagesArray(currentImage) {
        setProfileImages(profileImages.filter((image) => image !== currentImage));
    }

    function handleCurrentIndex(index) {
        setCurrentIndex(index);
    }

    function handleCurrentImage(image) {
        setCurrentImage(image);
    }

    function previousImage(event) {
        event.preventDefault();
        if(currentIndex === 0) {
            return;
        }

        handleCurrentIndex(currentIndex - 1);
        handleCurrentImage(profileImages[currentIndex]);
    }

    function nextImage(event) {
        event.preventDefault();
        if(currentIndex === profileImages.length - 1) {
            return;
        }

        handleCurrentIndex(currentIndex + 1);
        handleCurrentImage(profileImages[currentIndex]);
    }

    function deleteImage(event) {
        event.preventDefault();

        const imageToDelete = getCurrentImage();

        ImageAction.deleteProfileImage({
            path: imageToDelete
        }, dispatch).then((response) => {
            if(response !== null) {
                const respCode = response.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {

                    removeImageFromProfileImagesArray(imageToDelete);

                    // 맨 처음에 있는 이미지를 제거한 경우
                    if(currentIndex === 0) {
                        if(profileImages.length > 1) {
                            handleCurrentImage(profileImages[currentIndex]);
                        } else {
                            handleCurrentIndex(-1);
                        }
                    }

                    // 맨 마지막에 있는 이미지를 제거한 경우
                    else if(currentIndex === profileImages.length - 1) {
                        handleCurrentIndex(currentIndex - 1);
                        handleCurrentImage(profileImages[currentIndex]);
                    }

                    else {
                        handleCurrentIndex(currentIndex + 1);
                        handleCurrentImage(profileImages[currentIndex])
                    }
                }
            }
        });
    }

    const deleteIcon = () => {
        if(profileImages.length > 0) {
            return (
                <img className={"mid"} src={DeleteIcon} onClick={e => deleteImage(e)} />
            );
        }
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            fullWidth={true}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle id="alert-dialog-title">
                프로필 사진
            </DialogTitle>

            <div className={"horizontal-container"}>
                <img className={"modal-image"} src={getCurrentImage()}/>
            </div>

            <DialogActions className={"horizontal-container"}>
                <img className={"mid"} src={DirectionArrowIcon} onClick={e => previousImage(e)} />
                <img className={"mid reversed"} src={DirectionArrowIcon} onClick={e => nextImage(e)}/>

                {deleteIcon()}

                <label htmlFor={"files"}>
                    <img className={"mid"} src={AddImageIcon}/>
                </label>
                <input id={"files"} type={"file"} accept={"img/*"} className={"upload-image"} onChange={onLoadFile}/>

                <Button onClick={handleClose}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ProfileImageModal;