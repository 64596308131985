export const shareKakao = (event, imageUrl = null, route, title) => {
    event.preventDefault();

    if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
            kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
        }

        kakao.Link.sendDefault({
            objectType: "feed",
            content: {
                title: title,
                imageUrl: imageUrl === null ? '../asset/solid_white.svg' : imageUrl,
                link: {
                    mobileWebUrl: route,
                    webUrl: route
                }
            },
            buttons: [
                {
                    title: "더 보기",
                    link: {
                        mobileWebUrl: route,
                        webUrl: route
                    }
                }
            ]
        });
    }
};