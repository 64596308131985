import {authInfo} from "./user";
import {combineReducers} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {modalSlice} from "../../store/modalSlice";
import { snackbarSlice } from "../../store/snackbarSlice";

const persistConfig = {
    key: "root",
    storage
};

const rootReducer = combineReducers({
    authInfo: authInfo,
    modal: modalSlice.reducer,
    snackbar: snackbarSlice.reducer
});

const customPersistReducer = persistReducer(persistConfig, rootReducer);
export default customPersistReducer;