import Comment from "../Comment";

export default {
    create: (data, dispatch = null) => {
        return Comment.create(data, dispatch).then((resp) => {
            return resp;
        });
    },

    edit: (data, dispatch = null) => {
        return Comment.edit(data, dispatch).then((resp) => {
            return resp;
        })
    },

    get: (pid) => {
        return Comment.get(pid).then((resp) => {
            return resp;
        })
    },

    delete: (data, dispatch = null) => {
        return Comment.delete(data, dispatch).then((resp) => {
            return resp;
        });
    }
};