import { useState, createContext } from "react";

export const SnackbarStateContext = createContext();
export const SnackbarSetterContext = createContext();

function SnackbarProvider({ children, store }) {
    const [state, setState] = useState({
        type: null,
        props: null,
    });

    return (
        <SnackbarSetterContext.Provider value={setState}>
            <SnackbarStateContext.Provider value={state}>
                {children}
            </SnackbarStateContext.Provider>
        </SnackbarSetterContext.Provider>
    );
}

export default SnackbarProvider;