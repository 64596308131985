import Reaction from "../Reaction";

export default {
    react: async (data, dispatch = null) => {
        return await Reaction.create(data, dispatch).then((resp) => {
            return resp;
        });
    },

    getCounts: async (postId) => {
        return await Reaction.getCounts(postId);
    }
};
