import React, {useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box, ClickAwayListener, Container, Toolbar} from "@mui/material";
import {createSearchParams, useNavigate} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { Menu, MenuItem} from '@mui/material';
import NotificationAction from '../../api/action/NotificationAction';
import { isMobile } from "../../util/device";
import { useSelector, useDispatch } from 'react-redux';
import AuthAction from '../../api/action/AuthAction';
import allActions from "../../redux/action";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { optPrimaryMyAccount, optSecondaryMyAccount } from '../../const/menu-items';
import { Main } from './Main';
import { MobileAppBar } from './MobileAppBar';
import { DrawerHeader } from './DrawerHeader';
import { drawerWidth } from './values';

import RESP_CODE from "../../const/response-code";
import {Notifications} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";

function MyAccountHeader() {
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const [open, setOpen] = useState(false);
    const [invisible, setInvisible] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();
    const theme = useTheme();

    const isNotiPopupOpen = Boolean(anchorEl);

    useEffect(() => {

        NotificationAction.history().then((resp) => {
            console.log(resp);
            if(resp === null) {
                return;
            }

            const responseCode = resp.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const unreadCount = resp.data.responseBody[0];
                setUnreadNotificationsCount(unreadCount);
                if(unreadCount > 0) {
                    setInvisible(false);
                }

                const notificationData = resp.data.responseBody[1];

                if(notificationData) {
                    setNotificationMessages(notificationData);
                }
            }
        });
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    function navigateToPost(notificationId, urlName, postId) {
        NotificationAction.markAllAsRead(dispatch).then(response => {
            if(response !== null) {
                const respCode = response.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                    const respBody = response.data.responseBody;
                }
            }
        });
        
        navigate(`/board/${urlName}/post/${postId}`);
        handleClose();
    }

    function notificationHistory() {
        return notificationMessages.map(item => {
            return (
                <MenuItem key={item.notification_id} onClick={e => navigateToPost(item.notification_id, item.url_name, item.post_id)}>{item.message}</MenuItem>
            )
        })
    }

    function goToPage(e, item) {
        e.preventDefault();

        const key = item.key;

        if(key === 'logout') {
            setOpen(false);
            logout();
            return;
        }

        if(key === 'basic') {
            setOpen(false);
            return;
        }

        navigate(item.url);
        setOpen(false);
    }

    async function logout() {
        if (!authInfo.loggedIn) {
            return;
        }

        await AuthAction.logout(
            authInfo.details.email
        ).catch((error) => {
            console.error(`로그아웃 중에 에러가 발생했습니다. ${error}`);
        }).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                dispatch(allActions.userActions.logout());
                navigate('/', {replace: true});
            }
        });
    }

    function secondaryOption() {
        return (
            optSecondaryMyAccount.map((item, index) => {
                return (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={e => goToPage(e, item)}>
                            <ListItemText primary={item.name}/>
                        </ListItemButton>
                    </ListItem>
                )
            })
        )
    }

    const listMenuItem = (item) => {
        return (
            <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={e => goToPage(e, item)}>
                    <ListItemText primary={item.name}/>
                </ListItemButton>
            </ListItem>
        )
    }

    const handleNotificationClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const renderMobile = () => {
        return (
            <ClickAwayListener onClickAway={handleDrawerClose}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <CssBaseline />
                    <MobileAppBar position="fixed" open={open} style={{ boxShadow: 'none'}}>
                        <Toolbar style={{backgroundColor: '#1d2c6b', display: "flex", justifyContent: "space-between"}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{mr: 2, ...(open && {display: 'none'})}}
                            >

                                <MenuIcon/>

                            </IconButton>

                            <a href={"/"} style={{ minWidth: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-yagooforum-en-navy.svg`}
                                     alt={"야구포럼 로고"}/>
                            </a>

                            <div className={"horizontal-gap-sm"}/>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={isNotiPopupOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {notificationHistory()}
                            </Menu>

                            <Badge badgeContent={unreadNotificationsCount} invisible={invisible}
                                   style={{cursor: 'pointer'}} showZero>
                                <Notifications
                                    onClick={handleNotificationClick}
                                />
                            </Badge>
                        </Toolbar>
                    </MobileAppBar>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                            {optPrimaryMyAccount.map((item, index) => (
                                listMenuItem(item)
                            ))}
                        </List>
                        <Divider />
                        <List>
                            {secondaryOption()}
                        </List>
                    </Drawer>
                    <Main style={{ paddingTop: '0', paddingBottom: '0' }} open={open}>
                        <DrawerHeader />
                    </Main>
                </Box>
            </ClickAwayListener>
        );
    }

    const renderTabletDesktop = () => {
        return (
            <ClickAwayListener onClickAway={handleDrawerClose}>
                <Box sx={{ flexGrow: 1 }} style={{ width: '100%' }}>
                    <AppBar position="static" elevation={0} style={{ backgroundColor: "#1d2c6b" }}>
                        <Container className={"PrimaryHeader"} style={{ paddingLeft: "30px", paddingRight: "30px" }}  maxWidth="xl">
                        <Toolbar style={{ minHeight: "30px", display: "flex", justifyContent: "space-between" }} sx={{ boxShadow: 0 }} disableGutters>
                            <a href={"/"} className={"main-logo"}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-yagooforum-en-navy.svg`} alt={"야구포럼 로고"}/>
                            </a>

                            <div className={"horizontal-gap-sm"}/>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={isNotiPopupOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {notificationHistory()}
                            </Menu>

                            <Badge badgeContent={unreadNotificationsCount} invisible={invisible} style={{ cursor: 'pointer' }} showZero>
                                <Notifications
                                    onClick={handleNotificationClick}
                                />
                            </Badge>
                        </Toolbar>
                    </Container>
                    </AppBar>
                </Box>
            </ClickAwayListener>
        );
    }

    function renderByDeviceType() {
        if(isMobile()) {
            return renderMobile();
        }
        return renderTabletDesktop();
    }

    return renderByDeviceType();
}

export default MyAccountHeader;