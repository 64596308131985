import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditProfilePage from "./EditProfilePage";
import ChangePasswordPage from "./ChangePasswordPage";
import { isMobile } from "../../util/device";
import {useDispatch, useSelector} from "react-redux";
import UserAction from "../../api/action/UserAction";
import RESP_CODE from "../../const/response-code";
import BoardAction from "../../api/action/BoardAction";
import { getAuthProvider } from "../../util/data";

import allActions from "../../redux/action";
import MyAccountHeader from "../component/MyAccountHeader";
import { PasswordAuthenticationPage } from "./PasswordAuthenticationPage";
import BaseballTeamAction from "../../api/action/BaseballTeamAction";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import DeregisterAccountPage from "./DeregisterAccountPage";

export default function MyAccountPage({titleName}) {
    const navigate = useNavigate();
    const [currentSubPage, setCurrentSubPage] = useState('editProfile');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [headProfileImage, setHeadProfileImage] = useState('');
    const [profileImages, setProfileImages] = useState([]);
    const [favouriteBoards, setFavouriteBoards] = useState([]);
    const [allBoards, setAllBoards] = useState([]);
    const [authProvider, setAuthProvider] = useState('');
    const authInfo = useSelector(state => state.authInfo);
    const [isPwdAuthComplete, setIsPwdAuthComplete] = useState(false);
    const dispatch = useDispatch();
    const [leagues, setLeagues] = useState([]);
    const [favouriteTeamIds, setFavouriteTeamIds] = useState([]);
    const DefaultUserIcon = `${process.env.REACT_APP_PUBLIC_URL}/images/default_user.svg`;

    const handlePasswordAuthentication = () => {
        setIsPwdAuthComplete(complete => !complete);
    }

    async function getAllBoards() {
        return BoardAction.getAll();
    }

    useEffect(() => {
        if(!authInfo.loggedIn) {
            dispatch(allActions.userActions.logout());
            navigate('/', {replace: true});
        }

        BaseballTeamAction.getAllFavouriteTeams().then(response => {
            if(response === null) {
                return;
            }

            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const data = response.data.responseBody;
                setLeagues(data.leagues);
                setFavouriteTeamIds(data.favouriteTeamIds);
            }
        });

        UserAction.details(dispatch).then(response => {
            if(response !== null) {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = response.data.responseBody;
                    setName(data.name);
                    setEmail(data.email);
                    setAuthProvider(data.authProvider);
                    setStatusMessage(data.statusMessage);

                    const profileImageUrls = data.profileImageUrls;
                    setProfileImages([...profileImageUrls]);

                    if(profileImageUrls.length !== 0) {
                        const headProfileFromResponse = profileImageUrls[0];
                        setHeadProfileImage(headProfileFromResponse);

                    } else {
                        setHeadProfileImage(DefaultUserIcon);
                    }

                    setRole(data.role);
                }
            } else {
                navigate('/', {replace: true});
            }
        });

        getAllBoards().then(response => {
            const responseCode = response.data.responseCode;

            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const data = response.data.responseBody;
                setAllBoards(data);
            }
        });

        titleName('마이 페이지');
    }, []);

    const authProviderName = function(authProvider) {
        const mapAuthProvider = {
            'GOOGLE': '구글',
            'NAVER': '네이버',
            'KAKAO': '카카오',
            'DEFAULT': '이메일'
        }

        return mapAuthProvider[authProvider];
    }(authProvider);

    const editProfile = () => {
        return (
            <EditProfilePage
                name={name}
                setName={setName}
                email={email}
                statusMessage={statusMessage}
                setStatusMessage={setStatusMessage}
                profileImages={profileImages}
                setProfileImages={setProfileImages}
                allBoards={allBoards}
                setAllBoards={setAllBoards}
                favouriteBoards={favouriteBoards}
                currentRole={role}
                authInfo={authInfo}
                authProviderName={authProviderName}
                leagues={leagues}
                favouriteTeamIds={favouriteTeamIds}
                setFavouriteTeamIds={setFavouriteTeamIds}
                headProfileImage={headProfileImage}
            />
        );
    }

    const menuPasswordChange = () => {
        if(getAuthProvider() !== 'DEFAULT') {
            return;
        }

        if(isMobile()) {
            return (
                <a href="#" onClick={e => changeSubPage("changePassword")}>패스워드 변경</a>
            )
        } else {
            return (
                <Tab onClick={e => setCurrentSubPage("changePassword")}>
                    패스워드 변경
                </Tab>
            );
        }
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    function subPage() {
        switch(currentSubPage) {
            case "editProfile":
                if(isPwdAuthComplete || authProvider !== 'DEFAULT') {
                    return editProfile();
                }
                return (
                    <PasswordAuthenticationPage
                        authInfo={authInfo}
                        authHandler={handlePasswordAuthentication}
                        navigate={navigate}
                    />
                );

            case "changePassword": return (
                <ChangePasswordPage/>
            );

            case "deregisterAccount": return deregisterAccount();

            default:
                return editProfile();
        }
    }

    const deregisterAccount = () => {
        return (
            <DeregisterAccountPage/>
        );
    }

    const changeSubPage = (subPage) => {
        setCurrentSubPage(subPage);
        closeNav();
    }

    const renderTabletDesktop = () => {
        return (
            <div className={"vertical-container vertical-center"}>
                <MyAccountHeader/>
                <div className={"vertical-gap-mid"}/>

                <Tabs style={{ width: '90%' }}>
                    <TabList>
                        <Tab onClick={e => setCurrentSubPage("editProfile")}>내 프로필</Tab>
                        {menuPasswordChange()}
                        <Tab onClick={e => setCurrentSubPage("deregisterAccount")}>회원 탈퇴</Tab>
                    </TabList>

                    {subPage()} 
                </Tabs>
            </div>
        );
    }

    const renderMobile = () => {
        return (
            <div className={"myaccount-layout"}>
                <MyAccountHeader/>

                {subPage()}
            </div>
        );
    };

    const renderByDeviceType = () => {
        if(authInfo.loggedIn) {
            if(isMobile()) {
                return renderMobile();
            }

            return renderTabletDesktop();
        } else {
            navigate('/', {replace: true});
        }
    }

    return (
        renderByDeviceType()
    )
}