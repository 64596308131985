import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import useModal from "../../../hooks/useModal";

const TermsModal = (props) => {
    const dispatch = useDispatch();
    const {closeModal} = useModal();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    const handleClose = (e) => {
        e.preventDefault();
        closeModal();
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            fullWidth={true}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <div className={"textarea-agreement"}>{props.contents}</div>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}
export default TermsModal;