import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import useModal from "../../../hooks/useModal";
import UserAction from "../../../api/action/UserAction";
import RESP_CODE from "../../../const/response-code";
import useSnackbar from "../../../hooks/useSnackbar";
import {useDispatch} from "react-redux";

const ChangeNameModal = (props) => {
    const [contentsToEdit, setContentsToEdit] = useState(props.current);
    const {closeModal} = useModal();
    const {openSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    function onChangeContents(e) {
        e.preventDefault();
        setContentsToEdit(e.target.value);
    }

    const handleClose = (e) => {
        setContentsToEdit('');
        closeModal();
    }

    const editProfile = (e) => {
        e.preventDefault();

        if(props.current === contentsToEdit) {
            openSnackbar({isOpen: true, message: "변경하려는 이름이 기존과 동일합니다.", severity: "error"});
            return;
        }

        // 이름은 반드시 3글자 이상이어야 한다.
        if(contentsToEdit.length < 3 || contentsToEdit.length > 9) {
            openSnackbar({isOpen: true, message: "이름은 3글자 이상 9글자 이하만 가능합니다.", severity: "error"});
            return;
        }

        UserAction.edit(
            {
                key: props.field,
                value: contentsToEdit
            }, dispatch
        ).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                props.nameChangeHandler(contentsToEdit);
                setContentsToEdit('');
                openSnackbar({isOpen: true, message: `이름을 ${contentsToEdit}(으)로 변경했습니다.`, severity: "success"});
                closeModal();
            }
            else {
                openSnackbar({isOpen: true, message: `에러가 발생했습니다. ${responseCode}`, severity: "error"});
            }
        });
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <input className={"input light-grey"} defaultValue={contentsToEdit}
                   style={{outline: "none", width: '90%'}} onChange={e => onChangeContents(e)}/>

        <DialogActions>
            <Button onClick={e => handleClose(e)}>취소</Button>
            <Button onClick={e => editProfile(e)}>변경</Button>
        </DialogActions>
    </Dialog>
)
}

export default ChangeNameModal;