import {Cookies} from "react-cookie";

const cookie = new Cookies();

function getAuthDetails() {
    const rootData = JSON.parse(localStorage.getItem('persist:root'));
    const authInfo = JSON.parse(rootData.authInfo)
    if(authInfo !== null && authInfo.details !== null) {
        return authInfo.details;
    } else {
        return null;
    }
}

export function getRole() {
    const authDetails = getAuthDetails();
    if(authDetails !== null && authDetails !== undefined) {
        return authDetails.role;
    }
    return null;
}

export function getUserId() {
    const authDetails = getAuthDetails();
    if(authDetails !== null && authDetails !== undefined) {
        return authDetails.id;
    }
    return null;
}

export function getAuthProvider() {
    const authDetails = getAuthDetails();
    if(authDetails !== null && authDetails !== undefined) {
        return authDetails.authProvider;
    }
    return null;
}

export function removeFromLocalStorage(name) {
    localStorage.removeItem(name);
}

export function removeCookie(name) {
    return cookie.remove(name);
}

export async function clearAll() {
    removeFromLocalStorage('persist:root');
    removeCookie('accessToken');
    removeCookie('refreshToken');
}