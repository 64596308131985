import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import useModal from "../../../hooks/useModal";
import { useEffect } from "react";
import NotificationAction from "../../../api/action/NotificationAction";
import RESP_CODE from "../../../const/response-code";
import {useDispatch} from "react-redux";

const AlertModal = (props) => {
    const {closeModal} = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
        NotificationAction.markAsRead({
            notification_id: props.notificationId
        }, dispatch).then(response => {
            if(response !== null) {
                const respCode = response.data.responseCode;
                if(respCode === RESP_CODE.REQUEST_SUCCESS) {
                    const respBody = response.data.responseBody;
                }
            }
        });
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    const handleClose = (e) => {
        e.preventDefault();
        const navigate = props.navigate;
        const nextAction = props.nextAction;
        if(navigate && nextAction === 'login') {
            navigate('/login');
        }
        closeModal();
    }

    const displayTitle = (title) => {
        if(title !== undefined && title !== null && title !== '') {
            return (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            );
        }
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            {displayTitle(props.title)}
            <input className={"input"} defaultValue={props.message} style={{ outline: "none", width: '90%' }}/>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertModal;