import InquiryAction from "../../api/action/InquiryAction";
import React, {useEffect, useRef, useState} from "react";
import RESP_CODE from "../../const/response-code";
import {useNavigate} from "react-router-dom";

import MainLayout from "./MainLayout";
import {isEmailValid} from "../../util/validator";
import useSnackbar from "../../hooks/useSnackbar";
import {useDispatch} from "react-redux";

export const InquiryPage = ({titleName}) => {
    const [inquiryType, setInquiryType] = useState('IMPROVEMENT_SUGGESTION');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refEmail = useRef(null);
    const refContents = useRef(null);
    const {openSnackbar} = useSnackbar();

    useEffect(() => {
        titleName('문의 및 신고');
    }, []);

    async function createInquiry(event) {
        event.preventDefault();

        const email = refEmail.current.value;
        if(email === '')
        if(!isEmailValid(email)) {
            openSnackbar({isOpen: true, message: "올바르지 않은 형식의 이메일 입니다.", severity: "error"});
            return;
        }

        const contents = refContents.current.value;
        if(contents.length === 0) {
            openSnackbar({isOpen: true, message: "내용을 입력해 주시기 바랍니다.", severity: "error"});
            return;
        }

        const response = await InquiryAction.create({
            email: email,
            inquiry_type: inquiryType,
            contents: contents
        }, dispatch);

        if(response !== null) {
            const responseCode = response.data.responseCode;

            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                openSnackbar({isOpen: true, message: "성공적으로 등록되었습니다.", severity: "success"});
                navigate('/');
            }
        }
    }

    const inquiryTypeMenu = () => {
        const options = [
            { key: 'IMPROVEMENT_SUGGESTION', label: '서비스 건의사항' },
            { key: 'BUG_REPORT', label: '버그 신고' },
            { key: 'ADVERTISEMENT', label: '광고 문의' },
            { key: 'OTHERS', label: '기타' }
        ];
        return (
            options.map(value => {
                return <option key={value.key} value={value.key}>{ value.label }</option>
            })
        );
    }

    function handleTypeChange(e) {
        e.preventDefault();
        setInquiryType(e.target.value);
    }

    return (
        <MainLayout>
            <div className={"main-contents"}>
                <div className={"dropdown-wrapper"}>
                    <select className={"dropdown-select"} onChange={e => handleTypeChange(e)}>
                        {inquiryTypeMenu()}
                    </select>
                </div>

                <div className={"vertical-gap-sm"}/>

                <input ref={refEmail} className={"input2"} placeholder={"이메일 주소를 입력해 주세요"}/>

                <div className={"vertical-gap-sm"}/>

                <textarea className={"textbox1"} ref={refContents}/>

                <div className={"vertical-gap-sm"}/>

                <div className={"centered-item"}>
                    <button className={"button font-mid"}
                            onClick={event => createInquiry(event)}>등록
                    </button>
                </div>
                <div className={"vertical-gap-mid"}/>
            </div>
        </MainLayout>
    )
}