import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import "./App.css";

import MainPage from './ui/page/MainPage';
import SignupPage from './ui/page/SignupPage'

import Page404 from "./ui/page/Page404";
import OAuth2Kakao from "./ui/page/OAuth2Kakao";
import OAuth2Naver from "./ui/page/OAuth2Naver";
import OAuth2Google from "./ui/page/OAuth2Google";
import PostView from "./ui/page/PostView";
import LoginPage from "./ui/page/LoginPage";
import EditPost from "./ui/page/EditPost";
import BoardPage from "./ui/page/BoardPage";
import MyAccountPage from "./ui/page/MyAccountPage";
import SearchResultPage from "./ui/page/SearchResultPage";
import {AllBoardsPage} from "./ui/page/AllBoardsPage";
import HealthCheckPage from "./ui/page/HealthCheckPage";
import ForgotPasswordPage from "./ui/page/ForgotPasswordPage";
import ChangePasswordWithCodePage from "./ui/page/ChangePasswordWithCodePage";
import {InquiryPage} from "./ui/page/InquiryPage";
import RouteChangeTracker from "./analytics/RouteChangeTracker";
import UserDeleteCompletePage from "./ui/page/UserDeleteCompletePage";
import DeregisterAccountPage from "./ui/page/DeregisterAccountPage";
import { OAuth2SignupPage } from "./ui/page/OAuth2SignupPage";
import { useDispatch, useSelector } from "react-redux";
import allActions from "./redux/action";
import UserAction from "./api/action/UserAction";
import useSnackbar from "./hooks/useSnackbar";
import RESP_CODE from './const/response-code';
import {onMessage} from "firebase/messaging";
import {messaging, getFirebaseToken} from "./firebase";
import useModal from "./hooks/useModal";

const useTitle = () => {
    const [title, setTitle] = useState('');

    const updateTitle = () => {
        document.title = title;
    };
    useEffect(updateTitle, [title]);

    return setTitle;
};

function App() {
    const changeTitle = useTitle();
    const authInfo = useSelector(state => state.authInfo);
    const dispatch = useDispatch();
    const {openSnackbar} = useSnackbar();
    const {closeModal} = useModal();
    const location = useLocation();

    RouteChangeTracker();

    useEffect(() => {
        console.log(`Public URL: ${process.env.PUBLIC_URL}`);
    }, []);

    useEffect(() => {
        closeModal();
    }, [location]);

    function titleFromChildComponent(title) {
        changeTitle(title);
    }

    useEffect(() => {
        if(authInfo !== null && authInfo.loggedIn && authInfo.details.firebaseToken === null && "Notification" in window && window.Notification?.permission === "granted") {
            getFirebaseToken().then((firebaseToken) => {
                if(firebaseToken) {
                    UserAction.edit({
                        key: 'firebaseToken',
                        value: firebaseToken
                    }, dispatch).then((response) => {
                        if(response != null) {
                            const responseCode = response.data.responseCode;
                            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                                dispatch(allActions.userActions.updateFcmToken(firebaseToken));
                            }
                        }
                    }).catch((error) => {
                        console.error(`Error while updating the user's FCM token: ${error}`);
                    });
                } else {
                    console.log("No registration token is available.");
                }
            })
        }
    }, [authInfo]);

    const onMessageListener = (async() => {
        const messagingResolve = await messaging;
        if(messagingResolve) {
            onMessage(messagingResolve, (payload) => {
                if(Notification.permission === 'denied' || Notification.permission === 'default') {
                    openSnackbar({isOpen: true, message: "알림이 차단된 상태입니다. 알림 권한을 허용해 주세요.", severity: "error"});
                } else {
                    const { notification: { title, body } } = payload;
                    let notification = new Notification(title, {
                        icon: '/public/logo192.png'
                    });
                }
            });
        }
    })();

    return (
        <Routes>
            <Route exact path="/">
                <Route path="" element={<MainPage titleName={titleFromChildComponent}/>}/>
                <Route path={"login"} element={<LoginPage titleName={titleFromChildComponent}/>}/>
                <Route path="signup" element={ <SignupPage titleName={titleFromChildComponent}/>}/>
                <Route path={"myaccount"} element={ <MyAccountPage titleName={titleFromChildComponent}/> }/>
                <Route path={"change_password"} element={<ChangePasswordWithCodePage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/user/"}>
                <Route path={"delete"} element={<DeregisterAccountPage titleName={titleFromChildComponent}/>}/>
                <Route path={"delete/complete"} element={<UserDeleteCompletePage titleName={titleFromChildComponent}/>}/>
            </Route>

            {/*<Route exact path={"/news/"}>*/}
            {/*    <Route path={"baseball"} element={<NewsPage titleName={titleFromChildComponent} />}/>*/}
            {/*</Route>*/}

            <Route exact path={"/board/"}>
                <Route path={":boardUrlName"} element={<BoardPage titleName={titleFromChildComponent} />}/>
                <Route path={":boardUrlName/post/create"} element={<EditPost titleName={titleFromChildComponent}/>}/>
                <Route path={":boardUrlName/post/:postId"} element ={<PostView titleName={titleFromChildComponent}/>}/>
                <Route path={":boardUrlName/post/:postId/edit"} element={<EditPost titleName={titleFromChildComponent}/>}/>
                <Route path="all-boards" element={<AllBoardsPage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route path={"/inquiry"}>
                <Route path={""} element={<InquiryPage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route path={"/search"}>
                <Route path={""} element={<SearchResultPage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route exact path={"/health"}>
                <Route path={""} element={<HealthCheckPage/>}/>
            </Route>

            <Route exact path={"/forgot_password"}>
                <Route path={""} element={<ForgotPasswordPage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route path={"/oauth2/"}>
                <Route path={"kakao"} element={<OAuth2Kakao/>}/>
                <Route path={"naver"} element={<OAuth2Naver/>}/>
                <Route path={"google"} element={<OAuth2Google/>}/>
                <Route path={"signup"} element={<OAuth2SignupPage titleName={titleFromChildComponent}/>}/>
            </Route>

            <Route path="*" element={<Page404/>}/>
        </Routes>
    );
}

export default App;