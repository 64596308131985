import Board from "../Board";

export default {
    create: (data, dispatch = null) => {
        return Board.create(data, dispatch).then((resp) => {
            return resp;
        });
    },

    edit: (data, dispatch = null) => {
        return Board.edit(data, dispatch).then((resp) => {
            return resp;
        })
    },

    get: (pid) => {
        return Board.get(pid).then((resp) => {
            return resp;
        });
    },

    getByUrlName: async (urlName) => {
        return Board.getByUrlName(urlName).then((resp) => {
            return resp;
        });
    },

    getAll: () => {
        return Board.getAll().then((resp) => {
            return resp;
        });
    },
};