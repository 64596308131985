import Notification from "../Notification";

export default {
    history: async (dispatch = null) => {
        return await Notification.history(dispatch).then((resp) => {
            return resp;
        });
    },

    markAllAsRead: async (dispatch = null) => {
        return await Notification.markAllAsRead(dispatch).then((resp) => {
            return resp;
        });
    },

    markAsRead: async (data, dispatch = null) => {
        return await Notification.markAsRead(data, dispatch).then((resp) => {
            return resp;
        });
    }
};
