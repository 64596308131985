import Auth from "../Auth";

const RESP_CODE = require('../../const/response-code');

export default {
    login: async (email, password, authProvider) => {
        return await Auth.login({
            email: email,
            password: password,
            authProvider: authProvider
        }).then((resp) => {
            return resp;
        });
    },

    authenticate: async(email, password, authProvider) => {
        return await Auth.authenticate({
            email: email,
            password: password,
            authProvider: authProvider
        }).then((resp) => {
            return resp;
        });
    },

    logout: async (email) => {
        return Auth.logout({
            email: email
        }).then((resp) => {
            return resp;
        });
    },

    signup: (email, password, username, authProvider, favouriteTeamIds) => {
        return Auth.signup({
            email: email,
            password: password,
            username: username,
            authProvider: authProvider,
            favourite_team_ids: favouriteTeamIds
        }).then((resp) => {
            return resp;
        });
    },

    sendPhoneVerificationCode: (phone_number) => {
        return Auth.sendPhoneVerificationCode({
            phone_number: phone_number,
        }).then((resp) => {
            return resp;
        });
    },

    verifyPhoneNumberVerificationCode: (phone_number, verification_code) => {
        return Auth.verifyCode({
            phone_number: phone_number,
            verification_code: verification_code
        }).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                alert('회원님의 휴대폰이 인증되었습니다.');
            }
            return resp;
        })
    },

    refreshToken: (email) => {
        return Auth.refreshToken({
            email: email
        }).then((resp) => {
           return resp;
        });
    },

    verifyReCaptchaToken: (token, secretKey) => {
        return Auth.verifyReCaptchaToken({
            reCaptchaToken: token,
            reCaptchaSecretKey: secretKey
        });
    },

    createTempChangePasswordLink: (email) => {
        return Auth.createTempChangePasswordLink({
            email: email
        });
    },

    checkTempChangePasswordLinkValidity: (code) => {
        return Auth.checkTempChangePasswordLinkValidity(code);
    },

    changePassword: (data) => {
        return Auth.changePassword(data);
    },

    changePasswordWithCode: (data) => { 
        return Auth.changePasswordWithCode(data);
    },

    sendEmailVerificationCode: (email) => {
        return Auth.sendEmailVerificationCode(email);
    },

    verifyEmailVerificationCode: (data) => {
        return Auth.verifyEmailVerificationCode(data);
    }
};
