module.exports = Object.freeze({
    REQUEST_SUCCESS: 1000,
    EMAIL_ALREADY_TAKEN: 1001,
    WRONG_PASSWORD: 1002,
    CALL_USING_INVALID_CREDENTIALS: 1003,
    INVALID_REGION: 1004,
    PROVINCE_METROPOLITAN_INFO_NOT_GIVEN: 1005,
    CITY_COUNTY_DISTRICT_INFO_NOT_GIVEN: 1006,
    WEAK_PASSWORD: 1007,
    MOBILE_PHONE_NOT_VERIFIED: 1008,
    REDIS_ERROR: 1009,
    REDIS_KEY_EXPIRED: 1010,
    REDIS_INVALID_VERIFICATION_CODE: 1011,
    USER_NOT_FOUND: 1012,
    JWT_ACCESS_TOKEN_EXPIRED: 1013,
    JWT_REFRESH_TOKEN_EXPIRED: 1014,
    NO_JWT_FOR_EMAIL: 1015,
    UNAUTHORIZED_JWT_NOT_INCLUDED: 1016,
    OAUTH2_ALREADY_TAKEN: 1017,
    JWT_ACCESS_TOKEN_NOT_INCLUDED: 1018,
    JWT_REFRESH_TOKEN_NOT_INCLUDED: 1019,
    JWT_INVALID_ACCESS_TOKEN: 1020,
    JWT_INVALID_REFRESH_TOKEN: 1021,
    FAILURE_IMAGE_UPLOAD: 1022,
    POST_NOT_FOUND: 1023,
    JWT_ACCESS_TOKEN_NOT_DECODED: 1024,
    NO_ADMIN: 1025,
    DB_ERROR: 1026,
    NO_BOARD: 1027,
    NO_BOARD_LIST: 1028,
    COMMENT_NOT_SAVED: 1029,
    USER_ID_FROM_REQUEST_NOT_IDENTICAL_WITH_POST_USER_ID: 1030,
    BOARD_ALREADY_EXISTS: 1031,
    NO_SUCH_FIELD: 1032,
    ILLEGAL_ACCESS: 1033,
    MALFORMED_URL: 1034,
    IOEXCEPTION: 1035,
    USERNAME_ALREADY_TAKEN: 1036,
    VERIFICATION_CODE_NOT_FOUND: 1037,
    UNAUTHORIZED_PASSWORD_CHANGE_REQUEST: 1038,
    NEW_PASSWORD_IDENTICAL_WITH_OLD_ONE: 1039,
    MAX_BOARDS_EXCEEDED: 1040,
    FAILURE_PHONE_VERIFICATION_SMS_REQUEST: 1041,
    FAILURE_OAUTH2: 1042,
    COMMENT_NOT_FOUND: 1043,
    INVALID_PASSWORD: 1044,
    INVALID_EMAIL: 1045,
    ALREADY_FOLLOWING: 1046,
    CANNOT_FOLLOW_YOURSELF: 1047,
    INVALID_LENGTH: 1048,
    UNKNOWN_ERROR: 1049,
    PASSWORD_NOT_GIVEN: 1050,
    EMAIL_NOT_GIVEN: 1051,
    AUTH_PROVIDER_NOT_GIVEN: 1052,
    OAUTH2_EMAIL_NOT_TAKEN: 1053,
    NO_KAKAO_ACCOUNT: 1054,
    NO_KAKAO_ACCESS_TOKEN: 1055,
    NO_KAKAO_REFRESH_TOKEN: 1056,
    OAUTH2_KAKAO_USER_INFO_NOT_GIVEN: 1057,
    OAUTH2_KAKAO_ACCOUNT_INFO_NOT_GIVEN: 1058,
    OAUTH2_KAKAO_PROFILE_NOT_GIVEN: 1059,
    GENERATED_CODE_IS_NOT_GIVEN: 1060,
    EMAIL_ALREADY_TAKEN_WITH_OTHER_AUTH_PROVIDER: 1061,
    NO_NAVER_ACCESS_TOKEN: 1062,
    NO_NAVER_REFRESH_TOKEN: 1063,
    OAUTH2_NAVER_USER_INFO_NOT_GIVEN: 1064,
    NO_GOOGLE_ACCESS_TOKEN: 1065,
    OAUTH2_GOOGLE_USER_INFO_NOT_GIVEN: 1066,
    FAILURE_SEND_EMAIL: 1067,
    FIREBASE_TOKEN_REQUIRED: 1068,
    NOT_ALLOWED_TO_POST_FOR_ROOKIES: 1069,
    BLOCKED_USER: 1070,
    EMAIL_VERIFICATION_EXPIRED: 1071,
    PHONE_VERIFICATION_EXPIRED: 1072,
    NO_BASEBALL_LEAGUES: 1073,
    NO_BASEBALL_TEAMS: 1074,
    USER_ID_IS_GIVEN_NULL: 1075,
    INVALID_SEARCH_CRITERIA: 1076,
    NO_NEWS_ID: 1077,
    NO_MORE_POSTS_ALLOWED_ROOKIE: 1078,
    NO_MORE_POSTS_ALLOWED_SINGLE_A: 1079,
    NO_MORE_POSTS_ALLOWED_DOUBLE_A: 1080,
    NO_MORE_POSTS_ALLOWED_TRIPLE_A: 1081,
    NO_PROFILE_FOUND: 1082
});