import config from "../config";
import axios from "axios";
import RESP_CODE from "../const/response-code";
import allActions from "../redux/action";

class APIUtils {
    constructor() {
        this.BASE_URL = config.apiBaseUrl;
    }

    get(path, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    getURLParams(path, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    post(path, data = null, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    postMultipart(path, data, dispatch = null) {
        const url = this.BASE_URL + path;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    put(path, data, dispatch = null) {
        const url = this.BASE_URL + path;

        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    delete(path, data, dispatch = null) {
        const url = this.BASE_URL + path;

        return axios.delete(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
                "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS"
            },

            withCredentials: true,
            credentials: 'include'
        }).then((resp) => {
            return this.processResponse(resp, dispatch);
        });
    }

    async processResponse(response, dispatch = null) {
        const responseCode = response.data.responseCode;
        if (responseCode !== RESP_CODE.REQUEST_SUCCESS) {
            if (responseCode === RESP_CODE.JWT_REFRESH_TOKEN_EXPIRED ||
                responseCode === RESP_CODE.JWT_INVALID_REFRESH_TOKEN ||
                responseCode === RESP_CODE.JWT_INVALID_ACCESS_TOKEN ||
                responseCode === RESP_CODE.JWT_ACCESS_TOKEN_NOT_INCLUDED) {
                if(dispatch !== null) {
                    alert(`회원님께서 로그아웃 되셨습니다. 다시 로그인 해주시겠어요? [${responseCode}]`);
                    dispatch(allActions.userActions.logout());
                    return null;
                }
                return null;
            } else {
                return response;
            }
        }
        return response;
    }
}

export default new APIUtils();
