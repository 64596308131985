import ApiUtils from "./APIUtils";

class MainApi {
    constructor() {
        this.URL_PREFIX = "/v1/main";
    }

    get(dispatch = null) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}`, dispatch);
    }
}

export default new MainApi();