import React, {useCallback, useEffect, useRef, useState} from 'react';
import PostAction from "../../api/action/PostAction";
import {getRole, getUserId} from "../../util/data";
import {useNavigate, useParams} from "react-router-dom";
import Editor from "../component/Editor";
import HashTagAction from "../../api/action/HashTagAction";
import {isMobile, isTablet} from "../../util/device";
import MainLayout from './MainLayout';
import BoardAction from '../../api/action/BoardAction';
import useModal from "../../hooks/useModal";
import {isPostTitleValid} from "../../util/validator";
import useSnackbar from '../../hooks/useSnackbar';
import {useDispatch} from "react-redux";
import { validateUrl } from '../../util/validator';
import {HashTagStack} from "../component/HashTagStack";

const RESP_CODE = require('../../const/response-code');

const EditPost = ({titleName}) => {
    const [title, setTitle] = useState(null);
    const refTitle = useRef(title);
    const [contents, setContents] = useState(null);
    const refContents = useRef(contents);
    const [type, setType] = useState('COMMON');
    const navigate = useNavigate();
    const params = useParams();
    const post_id = params.postId;
    const boardUrlName = params.boardUrlName;
    const [boardName, setBoardName] = useState(null);
    const [parentBoardName, setParentBoardName] = useState(null);
    const [parentBoardUrl, setParentBoardUrl] = useState(null);
    const {openModal} = useModal();
    const {openSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    // onChange로 관리할 문자열
    const [hashtag, setHashtag] = useState('')

    // 해시태그 스트링을 담을 배열
    const [hashtagStrArr, setHashtagStrArr] = useState([]);

    // 첨부파일 이미지 URL을 담을 배열
    const [imagesArr, setImagesArr] = useState([]);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(post_id != null) {
            PostAction.get(post_id).then((result) => {
                const responseCode = result.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const data = result.data.responseBody;
                    setTitle(data.title);
                    setContents(data.contents);
                    setType(data.post_type);

                    const postHashTags = data.post_hash_tags;
                    setHashtagStrArr(postHashTags);

                    const imageUrls = data.image_urls;
                    if(imageUrls !== null) {
                        setImagesArr(data.image_urls);
                    }

                    const select = document.querySelector('select')
                    select.value = data.post_type;
                }
            });

            titleName('게시물 수정');
        } else {
            titleName('신규 게시물 작성');
        }

        BoardAction.getByUrlName(boardUrlName).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const data = response.data.responseBody;
                const boardName = data.name;
                setBoardName(boardName);

                const parentBoardName = data.parentBoardName;
                setParentBoardName(parentBoardName);

                const parentBoardUrl = data.parentBoardUrl;
                setParentBoardUrl(parentBoardUrl);
            }
        });
        setLoaded(true);
    }, [post_id, boardUrlName]);

    function handleTypeChange(event) {
        setType(event.target.value);
    }

    const postOptionMenu = () => {
        const allowedRoles = ['ADMIN', 'OWNER'];
        if(allowedRoles.includes(getRole())) {
            const options = [
                { key: 'COMMON', label: '일반' },
                { key: 'NOTICE', label: '공지' }
            ];
            return (
                <div className={"dropdown-wrapper"}>
                    <select className={"dropdown-select"} onChange={e => handleTypeChange(e)}>
                        {options.map(value => {
                            return <option key={value.key} value={value.key}>{ value.label }</option>
                        })}
                    </select>
                </div>  
            );
        }
    }

    const addHashtag = useCallback(
        (e) => {
            // hashtag 값이 빈 값이면 아무것도 하지 않는다.
            if(hashtag.replace(/\s/g,'') === '') {
                return;
            }

            // 같은 이름의 해시태그가 이미 있으면 아무것도 하지 않는다.
            if(hashtagStrArr.includes(hashtag)) {
                console.log('already included');
                setHashtag('');
                return;
            }

            HashTagAction.create({
                name: hashtag
            }).then((response) => {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    setHashtagStrArr((hashtagStrArr) => [...hashtagStrArr, hashtag]);
                }
            });
            setHashtag('');
        },
        [hashtag, hashtagStrArr]
    );

    const onChangeHashtag = (event) => {
        setHashtag(event.target.value.trim());
    }


    const insertLinkParentBoard = () => {
        if(parentBoardName !== null && parentBoardUrl !== null) {
            return (
                <div className={"horizontal-container"}>
                    <div className={"horizontal-gap-sm"}/>
                    <div className={"font-lg"}> / </div>
                    <div className={"horizontal-gap-sm"}/>
                    <a className={"link font-lg"} href={`/board/${parentBoardUrl}`}>{parentBoardName}</a>
                </div>
            )
        }
    }

    const displayCurrentBoard = () => {
        return (
            <div className="horizontal-container pd-mid vertical-center">
                <a className={"link font-lg"} href={`/`}>홈</a>
                {insertLinkParentBoard()}
                <div className={"horizontal-gap-sm"}/>
                <div className={"font-lg"}> / </div>
                <div className={"horizontal-gap-sm"}/>
                <a className={"link fontweight-mid font-xl"} href={`/board/${boardUrlName}`}>{boardName}</a>
            </div>
        );
    }


    async function process(event) {
        event.preventDefault();

        const currentTitle = refTitle.current.value;

        if(!isPostTitleValid(currentTitle)) {
            openSnackbar({isOpen: true, message: "제목은 최소 1글자부터 40자 사이만 가능합니다.", severity: "error"});
            return;
        }

        const user_id = getUserId();
        if(user_id === null) {
            openSnackbar({isOpen: true, message: '게시물을 작성하기 위해서는 먼저 로그인 해주세요.', severity: "error"});
            return;
        }

        if(user_id === undefined) {
            openSnackbar({isOpen: true, message: '알 수 없는 에러가 발생했습니다. 잠시 뒤 다시 시도해 주세요.', severity: "error"});
            return;
        }

        const currentContents = refContents.current.value;

        if (currentContents.trim() === '') {
            openSnackbar({isOpen: true, message: '내용을 입력해 주세요.', severity: 'error'});
        } else {
            // contents를 HTML 문서로 변환해준 변수 생성
            const parser = new DOMParser();
            const doc = parser.parseFromString(currentContents, 'text/html');
            const aTags = doc.getElementsByTagName('a');
            let imageUrls = [];
            for(let i=0; i<aTags.length; i++) {
                imageUrls.push(aTags[i].href);
            }

            const pTags = doc.getElementsByTagName('p');
            for(let i=0; i<pTags.length; ++i) {
                pTags[i].classList.add('p-text');
                
                let contents = pTags[i].innerHTML.trim().split(' ');
                for(let i=0; i<contents.length; ++i) {
                    if(validateUrl(contents[i])) {
                        contents[i] = `<a href="${contents[i]}">${contents[i]}</a>`
                    }
                }

                contents = contents.join(' ');
                pTags[i].innerHTML = contents;
            }

            const data = {
                title: currentTitle.trim(),
                contents: doc.documentElement.outerHTML,
                board_name: boardUrlName,
                post_type: type,
                image_urls: imageUrls,
                hashtags: hashtagStrArr
            };

            if (post_id === null || post_id === undefined) {
                await create(data, dispatch);
            } else {
                data.post_id = post_id;
                await edit(data, dispatch);
            }
        }
    }

    async function create(data, dispatch) {
        await PostAction.create(data, dispatch).then(resp => {
            if(resp !== null) {
                const code = resp.data.responseCode;
                if (code === RESP_CODE.REQUEST_SUCCESS) {
                    navigate(`/board/${boardUrlName}`, {replace: true});
                } else if (code === RESP_CODE.NO_MORE_POSTS_ALLOWED_ROOKIE) {
                    const propsToPass = {
                        open: true,
                        message: '루키 등급 회원님께서 한 달간 작성할 수 있는 게시물의 수 (1건)를 초과했습니다.',
                    }
                    openModal({type: 'alert', props: propsToPass});
                } else if (code === RESP_CODE.NO_MORE_POSTS_ALLOWED_SINGLE_A) {
                    const propsToPass = {
                        open: true,
                        message: '싱글 A 등급 회원님께서 한 달간 작성할 수 있는 게시물의 수 (2건)를 초과했습니다.',
                    }
                    openModal({type: 'alert', props: propsToPass});
                } else if (code === RESP_CODE.NO_MORE_POSTS_ALLOWED_DOUBLE_A) {
                    const propsToPass = {
                        open: true,
                        message: '더블 A 등급 회원님께서 한 달간 작성할 수 있는 게시물의 수 (3건)를 초과했습니다.',
                    }
                    openModal({type: 'alert', props: propsToPass});
                } else if (code === RESP_CODE.NO_MORE_POSTS_ALLOWED_TRIPLE_A) {
                    const propsToPass = {
                        open: true,
                        message: '트리플 A 등급 회원님께서 한 달간 작성할 수 있는 게시물의 수 (5건)를 초과했습니다.',
                    }
                    openModal({type: 'alert', props: propsToPass});
                }
            }
        }).catch(error => {
            console.error(`에러가 발생했습니다. ${error}`);
        });
    }

    async function edit(data, dispatch) {
        await PostAction.edit(data, dispatch).then((resp) => {
            if(resp !== null) {
                const code = resp.data.responseCode;
                if (code === RESP_CODE.REQUEST_SUCCESS) {
                    navigate(`/board/${boardUrlName}`, {replace: true});
                } else {
                    alert('An error occurred while creating a post.');
                }
            }
        }).catch(error => {
            console.error(`에러가 발생했습니다. ${error}`);
        });
    }

    const displayTitle = () => {
        return (
            <input
                ref={refTitle}
                className={"post-title-wrapper title fontweight-mid"}
                defaultValue={title}
                placeholder={"제목을 입력하세요"}
            />
        );
    }

    const displayProcessButtonGroup = () => {
        return (
            <div className={"horizontal-center"}>
                <div className={"space-between button-group1"}>
                    <button className={"button4"} type="submit" onClick={e => process(e)}>
                        등록하기
                    </button>

                    <div className={"horizontal-gap-sm"}/>

                    <button className={"button4-reversed"} type="submit"
                            onClick={e => processCancel(e)}>
                        취소하기
                    </button>
                </div>
            </div>
        );
    }

    const processCancel = () => {
        const propsToPass = {
            open: true,
            title: '게시물 작성을 취소하시겠습니까?',
            contents: '게시물 작성을 취소하시면, 지금까지 작성한 내용들은 남지 않습니다.',
            boardName: boardUrlName
        };
        const modalName = 'cancelEditPost';
        openModal({type: modalName, props: propsToPass});
    }

    const contentsChangeHandler = (e) => {
        console.log(e.target.value);
    }

    const renderDesktop = () => {
        return (
            <div className={"main-contents"}>
                {displayCurrentBoard()}

                <form className={"post-form"}>
                    {postOptionMenu()}

                    <div className={"vertical-gap-sm"}/>

                    {displayTitle()}

                    <div className={"vertical-gap-sm"}/>

                    <Editor quillRef={refContents} contents={contents}/>
                </form>

                <div className={"vertical-gap-sm"}/>

                <div className={"hashtag-input-wrapper"}
                     style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input
                        id={"hashtag-input"}
                        className={"text-field padding-input"}
                        value={hashtag}
                        onChange={onChangeHashtag}
                        placeholder={"해시태그를 추가해 보세요"}
                    />

                    <div className={"horizontal-gap-sm"}/>

                    <div style={{textAlign: 'center', alignItems: 'center', width: '15%'}} onClick={e => addHashtag(e)}>
                        <button className={"button2"}>추가</button>
                    </div>
                </div>

                <div className={"vertical-gap-sm"}/>

                <HashTagStack hashtagStrArr={hashtagStrArr} setHashtagStrArr={setHashtagStrArr}/>

                <div className={"vertical-gap-sm"}/>

                {displayProcessButtonGroup()}
            </div>
        );
    }

    const renderMobileTablet = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '6px',
                paddingRight: '6px'
            }}>
                <div className="top">
                    {displayCurrentBoard()}
                </div>

                <form className={"post-form"}>
                    {postOptionMenu()}

                    <div className={"vertical-gap-sm"}/>

                    {displayTitle()}

                    <div className={"editor"}>
                        <Editor quillRef={refContents} contents={contents}/>
                    </div>
                </form>

                <div className={"vertical-gap-sm"}/>

                <div className={"hashtag-input-wrapper"}>
                    <input
                        id={"hashtag-input"}
                        className={"text-field padding-input"}
                        value={hashtag}
                        onChange={onChangeHashtag}
                        placeholder={"해시태그를 추가해 보세요"}
                        style={{
                            width: '80%'
                        }}
                    />

                    <div className={"horizontal-gap-sm"}/>

                    <div style={{ textAlign: 'center', alignItems: 'center', width: '15%'}} onClick={e => addHashtag(e)}>
                        <button className={"button2"}>추가</button>
                    </div>
                </div>

                <div className={"vertical-gap-sm"}/>

                <HashTagStack hashtagStrArr={hashtagStrArr} setHashtagStrArr={setHashtagStrArr}/>

                <div className={"vertical-gap-sm"}/>

                {displayProcessButtonGroup()}

                <div className={"vertical-gap-mid"}/>
            </div>
        );
    }

    const renderByDeviceType = () => {
        if(isMobile() || isTablet()) {
            return renderMobileTablet();
        }

        return renderDesktop();
    }

    if(loaded) {
        return (
            <MainLayout children={renderByDeviceType()}/>
        );
    }
}

export default EditPost;