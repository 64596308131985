import Profile from "../Profile";

const RESP_CODE = require('../../const/response-code');

export default {
    edit: (data) => {
        return Profile.edit(data).then((resp) => {
            return resp;
        });
    }
};