import ApiUtils from "./APIUtils";

class CommentApi {
    constructor() {
        this.URL_PREFIX = "/v1/comment";
    }

    get(pid) {
        return ApiUtils.getURLParams(`${this.URL_PREFIX}/${pid}`);
    }

    create(data, dispatch = null) {
        return ApiUtils.post(this.URL_PREFIX + "/", data, dispatch);
    }

    edit(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/", data, dispatch);
    }

    // soft delete
    delete(data, dispatch = null) {
        return ApiUtils.put(this.URL_PREFIX + "/delete", data, dispatch);
    }
}

export default new CommentApi();