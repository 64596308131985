import React, {useState, useEffect, useRef} from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import TermsAction from "../../api/action/TermsAction";
import {generateRandomString} from "../../util/security";
import AuthAction from "../../api/action/AuthAction";
import allActions from "../../redux/action";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import useModal from "../../hooks/useModal";
import PasswordStrengthBar from 'react-password-strength-bar';
import { isEmailValid, isPasswordValid } from '../../util/validator';
import MainLayoutWithoutSecondaryHeader from './MainLayoutWithoutSecondaryHeader';
import useSnackbar from '../../hooks/useSnackbar';
import ReCAPTCHA from "react-google-recaptcha";
import BaseballTeamAction from "../../api/action/BaseballTeamAction";
import {TeamSelector} from "../component/TeamSelector";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const RESP_CODE = require('../../const/response-code');

const SignupPage = ({titleName}) => {
    const refEmail = useRef();
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [username, setUsername] = useState('');
    const recaptchaRef = React.createRef();
    const [validToken, setValidToken] = useState(null);
    const [agreedWithTerms, setAgreedWithTerms] = useState(false);
    const [agreedWithPrivateInfoPolicy, setAgreedWithPrivateInfoPolicy] = useState(false);
    const refPhoneNumber = useRef();
    const refPhoneVerificationCode = useRef();
    const [phoneVerified, setPhoneVerified] = useState(false);
    const PHONE_VERIFICATION_SECONDS = 180;
    const [phoneVerificationSeconds, setPhoneVerificationSeconds] = useState(PHONE_VERIFICATION_SECONDS);
    const [phoneVerificationPending, setPhoneVerificationPending] = useState(false);
    const [terms, setTerms] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {openModal} = useModal();
    const {openSnackbar} = useSnackbar();
    const authInfo = useSelector(state => state.authInfo);
    const refEmailVerificationCode = useRef();
    const [emailVerified, setEmailVerified] = useState(false);
    const [leagues, setLeagues] = useState([]);
    const [loaded, setLoaded] = useState(false);

    // alert level은 success, info, warning, error 중 하나 선택 가능.
    const [alertLevel, setAlertLevel] = useState('error');
    const [emailVerificationPending, setEmailVerificationPending] = useState(false);

    const EMAIL_VERIFICATION_SECONDS = 600;
    const [emailVerificationSeconds, setEmailVerificationSeconds] = useState(EMAIL_VERIFICATION_SECONDS);
    const [favouriteTeamIds, setFavouriteTeamIds] = useState([]);

    const state = generateRandomString(32);
    const KAKAO_OAUTH2_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`;
    const NAVER_OAUTH2_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}&state=${state}`;
    const GOOGLE_OAUTH2_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&response_type=code&include_granted_scopes=true&state=${state}&scope=https://www.googleapis.com/auth/userinfo.email`;

    useEffect(() => {
        titleName("회원가입 - 야구포럼 (YagooForum)");

        // 이 페이지도 로그인 한 회원에게는 보일 필요가 없으므로, 로그인 상태인 경우에는 메인 화면으로 강제 리다이렉트 시킨다.
        if(authInfo.loggedIn) {
            navigate('/', {replace: true});
            return;
        }

        TermsAction.get().then((response) => {
            const responseData = response.data.responseBody;
            setTerms(responseData.terms);
            setPrivacyPolicy(responseData.privacyPolicy);
        });

        BaseballTeamAction.getAll().then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const responseBody = response.data.responseBody;
                setLeagues(responseBody.leagues);
            }
        });

        setLoaded(true);
    }, []);

    useEffect(() => {
        if(emailVerificationPending) {
            const id = setInterval(() => {
                setEmailVerificationSeconds((seconds) => seconds - 1);
            }, 1000);

            if(emailVerificationSeconds === 0) {
                setEmailVerificationPending(false);
                setEmailVerificationSeconds(EMAIL_VERIFICATION_SECONDS);
                clearInterval(id);
            }

            return () => clearInterval(id);
        }
    }, [emailVerificationSeconds, emailVerificationPending]);

    useEffect(() => {
        if(phoneVerificationPending) {
            const id = setInterval(() => {
                setPhoneVerificationSeconds((seconds) => seconds - 1);
            }, 1000);

            if(phoneVerificationSeconds === 0) {
                setPhoneVerificationPending(false);
                setPhoneVerificationSeconds(PHONE_VERIFICATION_SECONDS);
                clearInterval(id);
            }

            return () => clearInterval(id);
        }
    }, [phoneVerificationSeconds, phoneVerificationPending]);

    const checkIfAllAgreed = () => {
        if(agreedWithTerms === false) {
            openSnackbar({isOpen: true, message: '이용약관에 동의해 주시기 바랍니다.', severity: 'error'});
            return false;
        }

        if(agreedWithPrivateInfoPolicy === false) {
            openSnackbar({isOpen: true, message: '개인정보처리방침에 동의해 주시기 바랍니다.', severity: 'error'});
            return false;
        }

        return true;
    }

    async function processSignup(e) {
        e.preventDefault();
        
        if(!checkIfAllAgreed()) {
            return;
        }

        if(password !== rePassword) {
            openSnackbar({isOpen: true, message: '비밀번호가 일치하지 않습니다', severity: 'error'});
            return;
        }

        if(username === '') {
            openSnackbar({isOpen: true, message: '닉네임을 입력하여 주시기 바랍니다.', severity: 'error'});
            return;
        }

        if(username.length < 3 || username.length > 9) {
            openSnackbar({isOpen: true, message: '이름은 3글자 이상 9글자 이하만 가능합니다.', severity: 'error'});
            return;
        }

        const email = refEmail.current.value;
        if(email === '') {
            openSnackbar({isOpen: true, message: '이메일을 입력하여 주시기 바랍니다.', severity: 'error'});
            return;
        }

        if(!isEmailValid(email)) {
            openSnackbar({isOpen: true, message: '이메일 형식이 올바르지 않습니다.', severity: 'error'});
            return;
        }

        if(password === '') {
            openSnackbar({isOpen: true, message: '패스워드를 입력하여 주시기 바랍니다.', severity: 'error'});
            return;
        }

        if(!isPasswordValid(password)) {
            openSnackbar({isOpen: true, message: "패스워드는 8자 이상 50자 이하여야 하며, 최소 1개 이상의 특수문자와 소문자, 대문자의 조합으로 구성되어야 합니다.", severity: 'error'});
            return;
        }

        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();

        if(token) {
            const reCaptchaVerificationResponse = await AuthAction.verifyReCaptchaToken(token, process.env.REACT_APP_RECAPTCHA_SECRET_KEY);
            const verificationResult = reCaptchaVerificationResponse.data.responseBody.success;
            if(verificationResult) {
                setValidToken(token);
            }
        } else {
            alert('자동가입 방지를 위한 인증을 해 주시기 바랍니다.');
            return;
        }

        AuthAction.signup(email, password, username, 'DEFAULT', favouriteTeamIds).then((resp) => {
            if(resp.data.responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const userDetails = resp.data;
                const loggedInUser = allActions.userActions.login(userDetails);
                dispatch(loggedInUser);
                openSnackbar({isOpen: true, message: `환영합니다, ${username} 님!`, severity: 'success'});
                navigate('/', { replace: true });
            } else {
                let msg = resp.data['message'];
                openSnackbar({isOpen: true, message: msg, severity: 'error'});
            }
        })
    }

    function toSignupWithOAuth2Provider(e, url) {
        e.preventDefault();
        window.location.replace(url);
    }

    const onClickAgreedWithTerms = () => {
        setAgreedWithTerms(agreedWithTerms => !agreedWithTerms);
    };

    const onClickAgreedWithPrivateInfoPolicy = () => {
        setAgreedWithPrivateInfoPolicy(agreedWithPrivateInfoPolicy => !agreedWithPrivateInfoPolicy);
    }

    const openTermsModal = () => {
        const propsToPass = {
            open: true,
            title: '이용 약관',
            contents: terms
        };
        openModal({type: 'terms', props: propsToPass});
    }

    const openPrivacyPolicyModal = () => {
        const propsToPass = {
            open: true,
            title: '개인정보 처리방침',
            contents: privacyPolicy
        };
        openModal({type: 'terms', props: propsToPass});
    }

    const getEmailVerificationCode = (event) => {
        event.preventDefault();
        const email = refEmail.current.value;

        if(email.trim().length === 0) {
            const propsToPass = {
                open: true,
                message: '이메일을 입력해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(!isEmailValid(email)) {
            const propsToPass = {
                open: true,
                message: '올바르지 않은 이메일 입니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        AuthAction.sendEmailVerificationCode({ email: email }).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const propsToPass = {
                    open: true,
                    message: `입력하신 이메일 주소 ${email}로 인증코드를 전송했습니다. 확인 후 인증코드를 아래 입력란에 입력해 주시기 바랍니다.`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});

                setEmailVerificationPending(true);
            } else if(responseCode === RESP_CODE.EMAIL_ALREADY_TAKEN) {
                const propsToPass = {
                    open: true,
                    message: '입력하신 이메일로 이미 가입하신 회원이 있습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else if(responseCode === RESP_CODE.FAILURE_SEND_EMAIL) {
                const propsToPass = {
                    open: true,
                    message: '유효하지 않은 이메일 입니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else {
                const propsToPass = {
                    open: true,
                    message: `이메일 인증 중 에러가 발생했습니다. [${responseCode}]`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            }
        })
    }

    const getPhoneNumberVerificationCode = (event) => {
        event.preventDefault();
        const phoneNumber = refPhoneNumber.current.value;

        const strRegexPhoneNumber = '^01[0-9]';
        const regexPhoneNumber = new RegExp(strRegexPhoneNumber);
        if((phoneNumber.trim().length > 11 || phoneNumber.trim().length < 10) || !regexPhoneNumber.test(phoneNumber)) {
            const propsToPass = {
                open: true,
                message: '휴대폰 번호가 올바르지 않습니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        AuthAction.sendPhoneVerificationCode(phoneNumber).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const propsToPass = {
                    open: true,
                    message: `입력하신 번호 ${phoneNumber}로 인증코드를 전송했습니다. 확인 후 인증코드를 아래 입력란에 입력해 주시기 바랍니다.`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});

                setPhoneVerificationPending(true);
            } else {
                const propsToPass = {
                    open: true,
                    message: `휴대폰 인증번호 발송 중 에러가 발생했습니다. [${responseCode}]`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            }
        });
    }

    const verifyPhoneNumberVerificationCode = (event) => {
        event.preventDefault();
        const phoneNumber = refPhoneNumber.current.value;
        const phoneNumberVerificationCode = refPhoneVerificationCode.current.value;
        if(phoneNumberVerificationCode.trim().length === 0) {
            const propsToPass = {
                open: true,
                message: '인증번호를 입력해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(!phoneVerificationPending) {
            const propsToPass = {
                open: true,
                message: '먼저 인증번호 받기를 진행해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(phoneVerificationSeconds <= 0) {
            const propsToPass = {
                open: true,
                message: '인증 유효 시간이 지났습니다. 인증번호 받기를 다시 진행해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        AuthAction.verifyPhoneNumberVerificationCode(phoneNumber, phoneNumberVerificationCode).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const propsToPass = {
                    open: true,
                    message: '휴대폰 인증이 완료되었습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else if(responseCode === RESP_CODE.VERIFICATION_CODE_NOT_FOUND) {
                const propsToPass = {
                    open: true,
                    message: '인증 코드가 유효하지 않습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else if(responseCode === RESP_CODE.REDIS_INVALID_VERIFICATION_CODE) {
                const propsToPass = {
                    open: true,
                    message: '잘못된 인증 코드를 입력하였습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else {
                const propsToPass = {
                    open: true,
                    message: `휴대폰 인증 중 오류가 발생했습니다. [${responseCode}]`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            }
        })
    }

    const verifyEmailVerificationCode = (event) => {
        event.preventDefault();
        const email = refEmail.current.value;
        const emailVerificationCode = refEmailVerificationCode.current.value;
        if(emailVerificationCode.trim().length === 0) {
            const propsToPass = {
                open: true,
                message: '인증번호를 입력해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(!emailVerificationPending) {
            const propsToPass = {
                open: true,
                message: '먼저 인증번호 받기를 진행해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        if(emailVerificationSeconds <= 0) {
            const propsToPass = {
                open: true,
                message: '인증 유효 시간이 지났습니다. 인증번호 받기를 다시 진행해 주시기 바랍니다.',
                navigate: null
            };
            openModal({type: 'alert', props: propsToPass});
            return;
        }

        AuthAction.verifyEmailVerificationCode({email: email, verification_code: emailVerificationCode}).then((response) => {
            const responseCode = response.data.responseCode;
            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const propsToPass = {
                    open: true,
                    message: '이메일 인증이 완료되었습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
                setEmailVerified(true);

                // 이메일 인증이 더 이상 pending 상태가 아니므로.
                setEmailVerificationPending(false);
            } else if(responseCode === RESP_CODE.VERIFICATION_CODE_NOT_FOUND) {
                const propsToPass = {
                    open: true,
                    message: '인증 코드가 유효하지 않습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else if(responseCode === RESP_CODE.REDIS_INVALID_VERIFICATION_CODE) {
                const propsToPass = {
                    open: true,
                    message: '잘못된 인증 코드를 입력하였습니다.',
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            } else {
                const propsToPass = {
                    open: true,
                    message: `이메일 인증 중 오류가 발생했습니다. [${responseCode}]`,
                    navigate: null
                };
                openModal({type: 'alert', props: propsToPass});
            }
        })
    }

    const displayRemainingTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        if(remainingSeconds < 10) {
            remainingSeconds = '0' + remainingSeconds;
        }
        return `${minutes}:${remainingSeconds}`;
    }

    const signupForm = () => {
        if(localStorage.getItem("loggedInUser") !== null) {
            return <Navigate to='/' replace={true}/>
        } else {
            return (
                <div className={"page"}>
                    <form className={"form"}>
                        <div className={"vertical-gap-mid"}/>

                        <input className={"text-field padding-input signup"} placeholder={"(필수) 닉네임"}
                               autoComplete={"off"}
                               onChange={e => setUsername(e.target.value)}/>

                        <div className={"horizontal-container space-between signup-input-row"}>
                            <input className={"text-field padding-input signup-email"}
                                   ref={refEmail}
                                   placeholder={"(필수) 이메일"}
                                   autoComplete={"off"}/>

                            <button type="submit" className={"btn-verification"}
                                    onClick={e => getEmailVerificationCode(e)}>
                                인증번호 받기
                            </button>
                        </div>

                        <div className={"horizontal-container space-between signup-input-row"}>
                            <input
                                className={"text-field2 padding-input signup-email-verification-code"}
                                placeholder={"(필수) 이메일 인증번호"}
                                ref={refEmailVerificationCode}
                                autoComplete={"off"}/>

                            <div style={{
                                display: emailVerificationPending ? 'block' : 'none',
                                fontSize: '10px'
                            }}>
                                {displayRemainingTime(emailVerificationSeconds)}
                            </div>

                            <CheckCircleIcon
                                style={{
                                    display: (emailVerified && !emailVerificationPending) ? 'block' : 'none',
                                    color: '#25d366'
                            }}
                            />

                            <button type="submit" className={"btn-verification"}
                                    onClick={e => verifyEmailVerificationCode(e)}>
                                인증번호 확인
                            </button>
                        </div>

                        {/*TODO: 휴대폰 인증 기능은, 나중에 SMS 발신자 인증 작업이 완료되는대로 활성화 시키기.*/}
                        {/*<div className={"horizontal-container space-between signup-input-row"}>*/}
                        {/*    <input className={"text-field padding-input signup-email"}*/}
                        {/*           ref={refPhoneNumber}*/}
                        {/*           placeholder={"휴대폰 번호 ('-' 제외하고 입력)"}*/}
                        {/*           autoComplete={"off"}/>*/}

                        {/*    <button type="submit" className={"btn-verification"}*/}
                        {/*            onClick={e => getPhoneNumberVerificationCode(e)}>*/}
                        {/*        인증번호 받기*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        {/*<div className={"horizontal-container space-between signup-input-row"}>*/}
                        {/*    <input*/}
                        {/*        className={"text-field2 padding-input signup-email-verification-code"}*/}
                        {/*        placeholder={"휴대폰 인증번호"}*/}
                        {/*        ref={refPhoneVerificationCode}*/}
                        {/*        autoComplete={"off"}/>*/}

                        {/*    <div style={{*/}
                        {/*        visibility: phoneVerificationPending ? 'visible' : 'hidden',*/}
                        {/*        fontSize: '10px'*/}
                        {/*    }}>*/}
                        {/*        {displayRemainingTime(phoneVerificationSeconds)}*/}
                        {/*    </div>*/}

                        {/*    <button type="submit" className={"btn-verification"}*/}
                        {/*            onClick={e => verifyPhoneNumberVerificationCode(e)}>*/}
                        {/*        인증번호 확인*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        <input className={"text-field padding-input signup"} placeholder={"(필수) 비밀번호"}
                               autoComplete={"off"}
                               onChange={e => setPassword(e.target.value)} type="password"/>

                        <PasswordStrengthBar className={"password-strength"} password={password}/>

                        <div className={"vertical-gap-sm"}/>

                        <input className={"text-field padding-input signup"} placeholder={"(필수) 비밀번호 확인"}
                               autoComplete={"off"}
                               onChange={e => setRePassword(e.target.value)} type="password"/>

                        <div className={"vertical-gap-sm"}/>

                        <div style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size={"normal"}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <div className="checkbox-wrapper">
                            <FormControlLabel
                                className={"control-label"}
                                control={
                                    <Checkbox
                                        className={"checkbox"}
                                        value={agreedWithTerms}
                                        onChange={onClickAgreedWithTerms}
                                    />
                                }
                                label={<Typography component={"div"} color={'#777777'} fontSize={13}>
                                    [필수] 이용약관에 동의 합니다.
                                </Typography>}
                            />

                            <div style={{minWidth: '24px'}}/>

                            <div className={"terms"} onClick={() => openTermsModal()}>보기</div>
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <div className="checkbox-wrapper">
                            <FormControlLabel
                                style={{
                                    marginRight: 0
                                }}
                                control={
                                    <Checkbox
                                        className={"checkbox"}
                                        value={agreedWithPrivateInfoPolicy}
                                        onChange={onClickAgreedWithPrivateInfoPolicy}
                                    />
                                }
                                label={
                                    <Typography
                                        fontSize={13}
                                        color={'#777777'}
                                        paddingRight={0}
                                        component={"div"}
                                    >
                                        [필수] 개인정보처리방침에 동의 합니다. 14세 이상 본인입니다. 만 14세 미만은 가입하실 수 없습니다.
                                    </Typography>
                                }
                            />

                            <div style={{minWidth: '24px'}}/>

                            <div className={"terms"} onClick={() => openPrivacyPolicyModal()}>보기</div>
                        </div>

                        <div className={"vertical-gap-mid"}/>

                        <div className={"font-xl"}>
                            (옵션) 회원님은 어디 팬이신가요?
                        </div>

                        <div className={"vertical-gap-sm"}/>

                        <TeamSelector leagues={leagues} favouriteTeamIds={favouriteTeamIds}
                                      setFavouriteTeamIds={setFavouriteTeamIds} isAtSignupPage={true}/>

                        <div className={"vertical-gap-sm"}/>

                        <a href={"/login"} className="link">
                            이미 회원이신가요? 여기서 <u>로그인</u> 하세요
                        </a>

                        <button type="submit" className={"button font-mid"}
                                onClick={e => processSignup(e)}>
                            회원가입
                        </button>
                    </form>

                    <div className={"vertical-gap-sm"}/>

                    <div className={"form"}>
                        <button type={"submit"} className={"button3 kakao"}
                                onClick={e => toSignupWithOAuth2Provider(e, KAKAO_OAUTH2_URL)}>
                            카카오로 시작하기
                        </button>
                        <div className={"vertical-gap-sm"}/>
                        <button type={"submit"} className={"button3 google"}
                                onClick={e => toSignupWithOAuth2Provider(e, GOOGLE_OAUTH2_URL)}>
                            구글로 시작하기
                        </button>
                        <div className={"vertical-gap-sm"}/>
                        <button type={"submit"} className={"button3 naver"}
                                onClick={e => toSignupWithOAuth2Provider(e, NAVER_OAUTH2_URL)}>
                            네이버로 시작하기
                        </button>
                    </div>

                    <div className={"vertical-gap-mid"}/>
                </div>
            )
        }
    }

    const render = () => {
        if(loaded) {
            return (
                <MainLayoutWithoutSecondaryHeader className={"horizontal-container"} children={signupForm()}/>
            );
        }
    }

    return (
        render()
    )
}

export default SignupPage;