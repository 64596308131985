import React, { useEffect, useState } from "react";
import PrimaryHeader from "../component/PrimaryHeader";
import SecondaryHeader from "../component/SecondaryHeader";
import Footer from "../component/Footer";
import { isMobile } from "../../util/device";
import VisitorAction from "../../api/action/VisitorAction";

import RESP_CODE from "../../const/response-code";

const MainLayout = ({currentBoardName, children}) => {
    const [todayVisitCount, setTodayVisitCount] = useState(0);
    const [totalVisitCount, setTotalVisitCount] = useState(0);

    const loadVisitorCount = () => {
        return VisitorAction.get();
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        loadVisitorCount().then(response => {
            const responseCode = response.data.responseCode;

            if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                const responseBody = response.data.responseBody;
                setTodayVisitCount(responseBody.visit_count_by_date.visit_count);
                setTotalVisitCount(responseBody.visit_count_by_date.total_count);
            }
        });
    }, []);

    const insertMargin = () => {
        if(isMobile()) {
            return (
                <div className={"horizontal-gap-mid"}/>
            );
        }

        return (
            <div className={"vertical-gap-sm"}/>
        );
    }

    const getVisitorsInfoSummary = () => {
        let fontSize;
        let styleSubitem = "visitor-subitem "
        if(isMobile()) {
            fontSize = "font-sm";
            styleSubitem += "horizontal-center"
        } else {
            fontSize = "font-mid";
        }
        return (
            <div className={"visitor"}>
                {insertMargin()}
                
                <div className={styleSubitem}>
                    <div className={fontSize}>방문한 회원 수</div>
                </div>

                {insertMargin()}

                <div className={styleSubitem}>
                    <div className={`${fontSize} firebrick`}>
                        {todayVisitCount} / {totalVisitCount}
                    </div>
                </div>

            </div>
        );
    }

    if(isMobile()) {
        return (
            <div>
                <PrimaryHeader/>
                <SecondaryHeader currentBoardName={currentBoardName}/>

                {children}

                {getVisitorsInfoSummary()}

                <div className={"vertical-gap-sm"}/>

                <Footer/>

                <div className={"vertical-gap-sm"}/>
            </div>
        )
    }

    return (
        <div>
            <PrimaryHeader/>
            <SecondaryHeader currentBoardName={currentBoardName}/>

            <div className={"horizontal-container align-center"}>
                <div className={"side-ad align-left"}>
                    <div className={"ad1-box"}>

                    </div>
                </div>

                {children}

                <div className={"side-ad align-center"}>
                    {getVisitorsInfoSummary()}

                    <div className={"ad1-box"}>

                    </div>
                </div>
            </div>
            
            <Footer/>
        </div>
    )};

export default MainLayout;