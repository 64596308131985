import {Button, Dialog, DialogActions, DialogTitle, Typography} from "@mui/material";
import useModal from "../../../hooks/useModal";
import RESP_CODE from "../../../const/response-code";
import {useNavigate} from "react-router-dom";
import CommentAction from "../../../api/action/CommentAction";
import {useDispatch} from "react-redux";
import {useEffect} from "react";


const DeleteCommentModal = (props) => {
    const {closeModal} = useModal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.onpopstate = (event) => {
            closeModal();
        }
    }, []);

    const deleteComment = (event) => {
        event.preventDefault();
        CommentAction.delete({
            comment_id: props.item.comment_id,
        }, dispatch).then(response => {
            if(response !== null) {
                const responseCode = response.data.responseCode;
                if(responseCode === RESP_CODE.REQUEST_SUCCESS) {
                    const commentToDelete = response.data.responseBody;
                }
                closeModal();
            }

            window.location.reload();
        }).catch(error => {
            console.log(`댓글 삭제 중 에러 발생: ${error}`);
        });
    }

    const handleClose = (e) => {
        e.preventDefault();
        closeModal();
    }

    return (
        <Dialog
            open={props.open}
            className={"dialog"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{
                maxWidth: 'xl'
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>

            <DialogActions>
                <Button onClick={e => handleClose(e)}>아니오</Button>
                <Button onClick={e => deleteComment(e)}>네</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCommentModal;