export function formatTimestamp(timestamp) {
    const timezoneOffset = new Date().getTimezoneOffset();

    let timestampWithTimezoneOffset = new Date(timestamp);
    timestampWithTimezoneOffset.setMinutes(timestampWithTimezoneOffset.getMinutes() - timezoneOffset);

    if (isToday(timestampWithTimezoneOffset)) {
        return toHhMmSs(timestampWithTimezoneOffset);
    }
    return toYyyyMmDd(timestampWithTimezoneOffset);
}

function isToday(timestamp) {
    const today = new Date();
    return (today.toDateString() === timestamp.toDateString());
}

function toYyyyMmDd(timestamp) {
    const year = timestamp.getFullYear();
    const month = timestamp.getMonth() + 1;
    const date = timestamp.getDate();

    return `${year}년 ${month}월 ${date}일`;
}

function toHhMmSs(timestamp) {
    timestamp.toLocaleTimeString();
    const hour = timestamp.getHours();
    const minutes = timestamp.getMinutes();

    if(minutes < 10) {
        return `${hour}:0${minutes}`;
    }
    return `${hour}:${minutes}`;
}

export function getTimeDifference(t1) {
    const tzOffset = new Date().getTimezoneOffset();

    let postCreatedTimestampWTzOffset = new Date(t1);
    postCreatedTimestampWTzOffset.setMinutes(postCreatedTimestampWTzOffset.getMinutes() - tzOffset);

    let now = new Date();

    let diff = Math.abs(postCreatedTimestampWTzOffset.getTime() - now.getTime());
    const dateDiff = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    // 작성일자가 오늘이 아닌 경우, '0일 전'과 같은 형식으로 리턴한다.
    if(dateDiff !== 0) {
        const yearDiff = Math.floor(dateDiff / 365);
        if(yearDiff > 0) {
            return `${yearDiff}년 전`;
        }

        const monDiff = Math.floor(dateDiff / 30);

        if(monDiff > 0) {
            return `${monDiff}개월 전`;
        }

        return `${dateDiff}일 전`;
    }

    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN
    const hrs = Math.floor(diff / HRS);
    const min = Math.floor((diff % HRS) / MIN);

    if(hrs < 1) {
        return (min === 0) ? `방금 전` : `${min}분 전`;
    }

    return `${hrs}시간 전`;
}